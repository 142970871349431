@tailwind base;
@tailwind components;

@tailwind utilities;
@import "~react-image-gallery/styles/css/image-gallery.css";

:root {
  scroll-behavior: initial;
  font-family: "Nunito", sans-serif;
}

body,
html {
  font-family: "Nunito", sans-serif !important;
}

* {
  font-family: "Nunito", sans-serif;
}

.new-line {
  white-space: pre-line;
}

.timeline {
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

input:checked~.radio {
  color: white;
  background-color: rgb(105, 78, 237);
}

.li {
  transition: all 200ms ease-in;
}

.review-verified {
  color: green;
}

.timestamp {
  margin-bottom: 20px;
  padding: 0px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 100;
}

.status {
  padding: 10px 40px;
  display: flex;
  justify-content: center;
  border-top: 2px solid #d6dce0;
  position: relative;
  transition: all 200ms ease-in;
}

.status h4 {
  font-weight: 600;
}

.status:before {
  content: "";
  width: 25px;
  height: 25px;
  background-color: white;
  border-radius: 25px;
  border: 1px solid #ddd;
  position: absolute;
  top: -15px;
  left: 42%;
  transition: all 200ms ease-in;
}

.li.complete .status {
  border-top: 2px solid #66dc71;
}

.li.complete .status:before {
  background-color: #66dc71;
  border: none;
  transition: all 200ms ease-in;
}

.li.complete .status h4 {
  color: #66dc71;
}

.cta-btn {
  margin-top: 22px;
  border: none;
  display: block;
  text-align: center;
  overflow: hidden;
  color: #ffffff;
  background-color: #5172ff;
  transition: 0.5s;
  width: 100%;
  border-radius: 5px;
  padding: 14.5px 30px;
  font-weight: 700;
  font-size: 16px;
}

.tab-listing {
  color: #222;
  min-width: 126px;
  border: 1px solid #000;
  border-radius: 2px;
  position: relative;
  z-index: 2;
}

.tab-listing>a {
  font-size: 14px;
  color: #222;
  display: inline-block;
  padding: 8px 10px;
  min-width: 80px;
  border-right: 1px solid #000;
  font-weight: 600;
}

.tab-listing>a:hover,
.tab-listing>a:active,
.tab-listing>a:focus {
  color: #fff;
  background-color: rgba(81, 114, 255, 0.6);
}

.tab-listing>i {
  cursor: pointer;
  padding: 2px;
  height: 37px;
  display: inline-flex;
  align-items: center;
  font-size: 22px;
}

.tab-listing>i:hover {
  background-color: #5172ff;
  color: #fff;
}

.tab-listing>ul {
  position: absolute;
  top: 45px;
  border: 1px solid #e2e7ec;
  width: 100%;
  max-height: 108px;
  overflow-y: auto;
  border-radius: 2px;
  background-color: #fff;
}

.tab-listing>ul>li:hover {
  background-color: #e2e7ec;
}

.tab-listing>ul>li>a {
  width: 100%;
  padding: 10px;
  display: block;
  color: #222;
  font-size: 14px;
  font-weight: 600;
}

.tab-listing>ul>li {
  border-bottom: 1px solid #e2e7ec;
}

.tab-listing>ul>li:last-child {
  border-bottom: 0;
}

.column-gap-3 {
  column-gap: 1rem;
}

.row-gap-3 {
  row-gap: 1rem;
}

.product-slider .prev-btn,
.product-slider .next-btn {
  border-radius: 4px;
  box-shadow: -5px 0 10px 2px hsl(0deg 0% 48% / 18%);
  background-color: #fff;
  z-index: 99;
  text-align: center;
  cursor: pointer;
  width: 35px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 66px;
}

.product-slider .prev-btn {
  position: absolute;
  left: 0;
  top: 0;
}

.product-slider .next-btn {
  position: absolute;
  right: 0;
  top: 0;
}

.product-slider .prev-btn i,
.product-slider .next-btn i {
  font-size: 30px;
}

.dropdown-backdDrop {
  position: fixed;
  inset: 0;
  z-index: 1;
  background-color: transparent;
}

.slider-card-wrapper {
  position: relative;
  height: 200px;
  width: 100%;
  overflow: hidden;
}

.max-h-340 {
  max-height: 340px;
}
.samples-listing {
  flex: 0 0 calc(50% - 96px);
  max-width: calc(50% - 96px);
  width: 100%;
}

@media (min-device-width: 320px) and (max-device-width: 700px) {
  .samples-listing {
    flex: 0 0 calc(100% - 0px);
    max-width: calc(100% - 0px);
    width: 100%;
  }
  .timeline {
    list-style-type: none;
    display: block;
  }

  .li {
    transition: all 200ms ease-in;
    display: flex;
    width: inherit;
  }

  .timestamp {
    width: 100px;
  }

  .status:before {
    left: -8%;
    top: 30%;
    transition: all 200ms ease-in;
  }
}

center {
  display: block;
  text-align: -webkit-center;
}

/* iframe,
object,
embed {
  max-width: 100%;
  border-radius: 12px;
  max-height: 250px;
} */

.fixed-header {
  transform: translate(-50%, 0);
}

.swiper {
  width: 100%;
  height: 100%;
}

.top-shadow {
  box-shadow: 0px -4px 5px 0px rgba(194, 194, 194, 0.75);
  -webkit-box-shadow: 0px -4px 5px 0px rgba(194, 194, 194, 0.75);
  -moz-box-shadow: 0px -4px 5px 0px rgba(194, 194, 194, 0.75);
}

.header-shadow {
  box-shadow: 2px 12px 14px 0px rgba(222, 222, 222, 0.75);
  -webkit-box-shadow: 2px 12px 14px 0px rgba(222, 222, 222, 0.75);
  -moz-box-shadow: 2px 12px 14px 0px rgba(222, 222, 222, 0.75);
}

body {
  padding: 0;
  margin: 0;
  font-size: 16px;
  /* font-family: "Inter", sans-serif; */
  font-family: "Nunito", sans-serif;
}

img {
  max-width: 100%;
  height: auto;
}

p {
  color: #606060;
  margin-bottom: 15px;
  line-height: 1.6;
  font-size: 16px;
}

p:last-child {
  margin-bottom: 0;
}

:focus {
  outline: 0 !important;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #221638;
  /* font-family: "Inter", sans-serif; */
  font-family: "Nunito", sans-serif;
}

.container {
  max-width: 1230px;
}

.bg-fef8ef {
  background-color: #fef8ef;
}

.bg-fe4a55 {
  background-color: #5172ff;
}

.bg-f5f1ed {
  background-color: #f5f1ed;
}

.bg-f0f2f5 {
  background-color: #f0f2f5;
}

.bg-f8f9f8 {
  background-color: #f8f9f8;
}

.d-table {
  width: 100%;
  height: 100%;
}

.d-table-cell {
  vertical-align: middle;
}

.bg-f7ebeb {
  background-color: #f7ebeb;
}

.bg-6dbbbd {
  background-color: #6dbbbd;
}

.font-weight-black {
  font-weight: 900 !important;
}

.bg-f9f9f9 {
  background-color: #f9f9f9;
}

.bg-6ba292 {
  background-color: #6ba292;
}

.bg-f5f7fa {
  background-color: #f5f7fa;
}

.bg-fffaf3 {
  background-color: #fffaf3;
}

.bg-fff7f4 {
  background-color: #fff7f4;
}

.bg-f6f7fb {
  background-color: #f6f7fb;
}

.bg-fcf7f3 {
  background-color: #fcf7f3;
}

.bg-eee8df {
  background-color: #eee8df;
}

.ptb-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}

.pt-100 {
  padding-top: 100px;
}

.pb-100 {
  padding-bottom: 100px;
}

.ptb-70 {
  padding-top: 70px;
  padding-bottom: 70px;
}

.pt-70 {
  padding-top: 70px;
}

.pb-70 {
  padding-bottom: 70px;
}

.mtb-100 {
  margin-top: 100px;
  margin-bottom: 100px;
}

a {
  color: #221638;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  text-decoration: none;
}

a:hover {
  color: #5172ff;
  text-decoration: none;
}

.playfair-display-font {
  /* font-family: "Playfair Display", serif; */
  font-family: "Nunito", sans-serif !important;
}

.form-group {
  margin-bottom: 20px;
}

/* section-title */
.section-title {
  text-align: center;
  max-width: 720px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 55px;
}

.section-title .sub-title {
  display: block;
  text-transform: uppercase;
  color: #5172ff;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 600;
}

.section-title h2 {
  max-width: 615px;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
  font-size: 36px;
  font-weight: 800;
}

.section-title p {
  max-width: 615px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 12px;
}

/* default-btn */
.default-btn {
  border: none;
  position: relative;
  display: inline-block;
  text-align: center;
  overflow: hidden;
  z-index: 1;
  color: #ffffff;
  background-color: #5172ff;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  border-radius: 5px;
  font-weight: 700;
  font-size: 16px;
  padding-left: 55px;
  padding-right: 30px;
  padding-top: 11px;
  padding-bottom: 11px;
}

.default-btn span {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-radius: 50%;
  background-color: #221638;
  -webkit-transition: width 0.5s ease-in-out, height 0.5s ease-in-out;
  transition: width 0.5s ease-in-out, height 0.5s ease-in-out;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: -1;
  border-radius: 5px;
}

.default-btn i {
  position: absolute;
  left: 30px;
  top: 10px;
}

.default-btn:hover {
  color: #ffffff;
}

.default-btn:hover span {
  width: 225%;
  height: 562.5px;
}

.default-btn-style-two {
  border: none;
  position: relative;
  display: inline-block;
  text-align: center;
  overflow: hidden;
  z-index: 1;
  color: #ffffff;
  background-color: transparent;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  border-radius: 30px;
  border: 1px dashed #5172ff;
  font-weight: 700;
  font-size: 16px;
  padding-left: 60px;
  padding-right: 35px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.default-btn-style-two i {
  position: absolute;
  left: 35px;
  top: 14px;
}

.default-btn-style-two::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #5172ff;
  z-index: -1;
  border-radius: 30px;
  margin: 4px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.default-btn-style-two:hover {
  color: #ffffff;
}

.default-btn-style-two:hover::before {
  margin: 0;
}

/* form-control */
.form-control {
  height: 50px;
  color: #221638;
  -webkit-box-shadow: unset !important;
  box-shadow: unset !important;
  border: 1px solid #f5f5f5;
  background-color: #f5f5f5;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  border-radius: 3px;
  padding: 1px 0 0 15px;
  font-size: 16px;
  font-weight: 400;
}

.form-control::-webkit-input-placeholder {
  color: #7e7e7e;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.form-control:-ms-input-placeholder {
  color: #7e7e7e;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.form-control::-ms-input-placeholder {
  color: #7e7e7e;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.form-control::placeholder {
  color: #7e7e7e;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.form-control:focus {
  border-color: #5172ff;
  background-color: transparent;
}

.form-control:focus::-webkit-input-placeholder {
  color: transparent;
}

.form-control:focus:-ms-input-placeholder {
  color: transparent;
}

.form-control:focus::-ms-input-placeholder {
  color: transparent;
}

.form-control:focus::placeholder {
  color: transparent;
}

textarea.form-control {
  height: auto;
  padding-top: 15px;
}

/*================================================
Navbar Area CSS
=================================================*/
.edemy-nav .container-fluid {
  padding-left: 40px;
  padding-right: 40px;
}

.edemy-nav .navbar .search-box {
  margin-left: auto;
  position: relative;
  flex-basis: 270px;
  border-radius: 5px;
}

.edemy-nav .navbar .search-box .input-search {
  display: block;
  width: 100%;
  height: 48px;
  border: none;
  border-radius: 5px;
  background-color: #f5f5f5;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  color: #221638;
  font-size: 16px;
  font-weight: 400;
  padding-left: 15px;
  padding-top: 1px;
  padding-bottom: 0;
  padding-right: 0;
}

.edemy-nav .navbar .search-box .input-search::-webkit-input-placeholder {
  color: #848484;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.edemy-nav .navbar .search-box .input-search:-ms-input-placeholder {
  color: #848484;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.edemy-nav .navbar .search-box .input-search::-ms-input-placeholder {
  color: #848484;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.edemy-nav .navbar .search-box .input-search::placeholder {
  color: #848484;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.edemy-nav .navbar .search-box .input-search:focus {
  border-color: #5172ff;
}

.edemy-nav .navbar .search-box .input-search:focus::-webkit-input-placeholder {
  color: transparent;
}

.edemy-nav .navbar .search-box .input-search:focus:-ms-input-placeholder {
  color: transparent;
}

.edemy-nav .navbar .search-box .input-search:focus::-ms-input-placeholder {
  color: transparent;
}

.edemy-nav .navbar .search-box .input-search:focus::placeholder {
  color: transparent;
}

.edemy-nav .navbar .search-box button {
  position: absolute;
  right: 0;
  top: 0;
  height: 48px;
  background-color: transparent;
  border: none;
  color: #5172ff;
  border-radius: 5px;
  padding: 0 15px;
}

.edemy-nav .navbar .navbar-nav {
  margin-left: auto;
}

.edemy-nav .navbar .navbar-nav .nav-item {
  position: relative;
}

.edemy-nav .navbar .navbar-nav .nav-item a {
  color: #221638;
  font-size: 16.5px;
  font-weight: 700;
  padding-left: 0;
  padding-right: 0;
  padding-top: 25px;
  padding-bottom: 25px;
  margin-left: 13px;
  margin-right: 13px;
  /* right: 150px; */
}

.edemy-nav .navbar .navbar-nav .nav-item a i {
  font-size: 18px;
  /* position: relative; */
  top: 2px;
  /* display: inline-block; */
  margin-left: -4px;
  margin-right: -3px;
}

.edemy-nav .navbar .navbar-nav .nav-item a:hover,
.edemy-nav .navbar .navbar-nav .nav-item a:focus,
.edemy-nav .navbar .navbar-nav .nav-item a.active {
  color: #5172ff;
}

.edemy-nav .navbar .navbar-nav .nav-item:last-child a {
  margin-right: 0;
}

.edemy-nav .navbar .navbar-nav .nav-item:first-child a {
  margin-left: 0;
}

.edemy-nav .navbar .navbar-nav .nav-item:hover a,
.edemy-nav .navbar .navbar-nav .nav-item.active a {
  color: #5172ff;
}

/* .edemy-nav .navbar .navbar-nav li:hover {
  background-color: #f4ebff;
  border-radius: 12px;
  padding: 12px 12px;
} */

.edemy-nav .navbar .navbar-nav .nav-item .dropdown-menu {
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
  border: none;
  top: 60px;
  left: 0;
  z-index: 99;
  opacity: 0;
  width: 270px;
  display: block;
  border-radius: 0;
  padding: 10px 0;
  margin-top: 15px;
  position: absolute;
  visibility: hidden;
  background: #ffffff;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  border-top: 3px solid #5172ff;
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  padding: 0px 0px;
  /* float: right; */
}

.parent-nav {
  border: none;
  padding: 13px 0px 0px 0px;
}

.parent-nav li {
  padding: 0px 10px;
  border-top: none !important;
}

/* 
.edemy-nav .navbar .navbar-nav .nav-item .dropdown-menu ul li:hover {
  background-color: #f4ebff;
  border-radius: 12px;
  padding: 4px 12px;
}
 */

.edemy-nav .navbar .navbar-nav .nav-item .dropdown-menu li a {
  padding: 8px 20px;
  margin: 0;
  position: relative;
  display: block;
  color: #221638;
  font-size: 16px;
  font-weight: 700;
}

.edemy-nav .navbar .navbar-nav .nav-item .dropdown-menu li a i {
  margin: 0;
  position: absolute;
  top: 50%;
  font-size: 20px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 15px;
}

.edemy-nav .navbar .navbar-nav .nav-item .dropdown-menu li a:hover,
.edemy-nav .navbar .navbar-nav .nav-item .dropdown-menu li a:focus,
.edemy-nav .navbar .navbar-nav .nav-item .dropdown-menu li a.active {
  color: #5172ff;
}

.edemy-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu {
  top: -3px;
  opacity: 0;
  left: 100%;
  /* right: 100px !important; */
  /* margin-top: 15px; */
  visibility: hidden;
  /* float: right !important; */
}

.edemy-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a {
  color: #221638;
}

.edemy-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a:hover,
.edemy-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a:focus,
.edemy-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a.active {
  color: #5172ff;
}

.edemy-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu {
  top: -3px;
  opacity: 0;
  left: 100%;
  visibility: hidden;
}

.edemy-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #221638;
}

.edemy-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover,
.edemy-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus,
.edemy-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #5172ff;
}

.edemy-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
  top: 0;
  opacity: 0;
  left: -100%;
  visibility: hidden;
}

.edemy-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #221638;
}

.edemy-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover,
.edemy-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus,
.edemy-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #5172ff;
}

.edemy-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
  top: 0;
  opacity: 0;
  left: 100%;
  visibility: hidden;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
}

.edemy-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #221638;
}

.edemy-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover,
.edemy-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus,
.edemy-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #5172ff;
}

.edemy-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
  top: 0;
  opacity: 0;
  left: -100%;
  visibility: hidden;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
}

.edemy-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #221638;
}

.edemy-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover,
.edemy-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus,
.edemy-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #5172ff;
}

.edemy-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
  top: 0;
  opacity: 0;
  left: 100%;
  visibility: hidden;
}

.edemy-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #221638;
}

.edemy-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover,
.edemy-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus,
.edemy-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #5172ff;
}

.edemy-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: #5172ff;
}

.edemy-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
}

.edemy-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: #5172ff;
}

.edemy-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
}

.edemy-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: #5172ff;
}

.edemy-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
}

.edemy-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: #5172ff;
}

.edemy-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
}

.edemy-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li.active a {
  color: #5172ff;
}

.edemy-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
}

.edemy-nav .navbar .navbar-nav .nav-item .dropdown-menu li.active a {
  color: #5172ff;
}

.edemy-nav .navbar .navbar-nav .nav-item .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  margin-top: 0;
}

.edemy-nav .navbar .navbar-nav .nav-item:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  /* margin-top: 9px; */
}

.edemy-nav .navbar .navbar-nav .nav-item.megamenu {
  position: inherit;
}

.edemy-nav .navbar .navbar-nav .nav-item.megamenu .container {
  display: block;
  -ms-flex-wrap: unset;
  flex-wrap: unset;
  -webkit-box-align: unset;
  -ms-flex-align: unset;
  align-items: unset;
  -webkit-box-pack: unset;
  -ms-flex-pack: unset;
  justify-content: unset;
}

.edemy-nav .navbar .navbar-nav .nav-item.megamenu .dropdown-menu {
  width: 100%;
  top: auto;
  left: 0;
  margin-top: 0;
  padding: 30px 0;
  position: absolute;
  -webkit-transform: unset !important;
  transform: unset !important;
}

.edemy-nav .navbar .navbar-nav .nav-item.megamenu .dropdown-menu .megamenu-submenu li a {
  margin-top: 14px;
  color: #221638;
  padding: 0;
  display: block;
  font-size: 16px;
  font-weight: 700;
}

.edemy-nav .navbar .navbar-nav .nav-item.megamenu .dropdown-menu .megamenu-submenu li a:hover,
.edemy-nav .navbar .navbar-nav .nav-item.megamenu .dropdown-menu .megamenu-submenu li a.active {
  color: #5172ff;
}

.edemy-nav .navbar .navbar-nav .nav-item.megamenu .dropdown-menu .megamenu-submenu li:first-child a {
  margin-top: 0;
}

.edemy-nav .navbar .navbar-nav .nav-item.megamenu .dropdown-menu .single-category-widget {
  position: relative;
  border-top: 1px solid #eeeeee;
  padding-top: 30px;
  margin-top: 30px;
}

.edemy-nav .navbar .navbar-nav .nav-item.megamenu .dropdown-menu .single-category-widget .icon {
  width: 70px;
  height: 70px;
  position: relative;
  background: #f4f4f4;
  text-align: center;
  font-size: 40px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  color: #221638;
  margin-bottom: 15px;
  border-width: 1.5px;
  border-style: dashed;
  border-color: #eeeeee;
  border-radius: 50%;
}

.edemy-nav .navbar .navbar-nav .nav-item.megamenu .dropdown-menu .single-category-widget .icon i {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  right: 0;
}

.edemy-nav .navbar .navbar-nav .nav-item.megamenu .dropdown-menu .single-category-widget h3 {
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 800;
}

.edemy-nav .navbar .navbar-nav .nav-item.megamenu .dropdown-menu .single-category-widget .sub-title {
  display: block;
  margin-top: 5px;
  color: #5172ff;
  font-weight: 600;
  font-size: 15px;
}

.edemy-nav .navbar .navbar-nav .nav-item.megamenu .dropdown-menu .single-category-widget .link-btn {
  position: absolute;
  display: block;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
}

.edemy-nav .navbar .navbar-nav .nav-item.megamenu .dropdown-menu .single-category-widget:hover .icon {
  background-color: #5172ff;
  border-color: #ffffff;
  color: #ffffff;
}

.edemy-nav .navbar .others-option {
  margin-left: 25px;
}

.edemy-nav .navbar .others-option .option-item {
  margin-left: 30px;
}

.edemy-nav .navbar .others-option .option-item:first-child {
  margin-left: 0;
}

.edemy-nav .navbar .others-option .cart-btn {
  font-size: 22px;
  position: relative;
  line-height: 1;
}

.edemy-nav .navbar .others-option .cart-btn a {
  display: inline-block;
  position: relative;
  color: #221638;
}

.edemy-nav .navbar .others-option .cart-btn a span {
  position: absolute;
  right: -4px;
  top: -4px;
  width: 14px;
  height: 14px;
  text-align: center;
  line-height: 14px;
  border-radius: 50%;
  color: #ffffff;
  background-color: #5172ff;
  font-size: 10px;
  font-weight: 600;
}

.edemy-nav .navbar .others-option .cart-btn a:hover {
  color: #5172ff;
}

.navbar-area {
  position: relative;
  background-color: #ffffff;
  padding-top: 15px;
  padding-bottom: 15px;
}

.textElips {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.navbar-area.is-sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  -webkit-box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
  box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
  background-color: #ffffff !important;
  -webkit-animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
}

.navbar-area.is-sticky.pb-0 {
  padding-bottom: 5px !important;
}

/* Navbar toggler */
.navbar-toggler {
  border: none;
  border-radius: 0;
  padding: 0;
}

.navbar-toggler .icon-bar {
  width: 35px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  background: #221638;
  height: 4px;
  display: block;
  border-radius: 3px;
}

.navbar-toggler .top-bar {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transform-origin: 10% 10%;
  transform-origin: 10% 10%;
  left: 4px;
  position: relative;
}

.navbar-toggler .middle-bar {
  opacity: 0;
  margin: 5px 0;
}

.navbar-toggler .bottom-bar {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: 10% 90%;
  transform-origin: 10% 90%;
  left: 4px;
  position: relative;
}

/* Free study material nested level Cross bar */
.navbar-toggler-nested .icon-bar {
  width: 25px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  background: #221638;
  height: 2px;
  display: block;
  border-radius: 3px;
}

.navbar-toggler-nested .top-bar {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transform-origin: 10% 10%;
  transform-origin: 10% 10%;
  left: 4px;
  position: relative;
}

.navbar-toggler-nested .middle-bar {
  opacity: 0;
  margin: 5px 0;
}

.navbar-toggler-nested .bottom-bar {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: 10% 90%;
  transform-origin: 10% 90%;
  left: 4px;
  position: relative;
}

.navbar-toggler.collapsed .top-bar {
  -webkit-transform: rotate(0);
  transform: rotate(0);
  left: 0;
}

.navbar-toggler.collapsed .middle-bar {
  opacity: 1;
}

.navbar-toggler.collapsed .bottom-bar {
  -webkit-transform: rotate(0);
  transform: rotate(0);
  left: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1366px) {
  .navbar-expand-lg .navbar-collapse {
    flex-basis: 100% !important;
    justify-content: space-between;
  }

  .edemy-nav .navbar .search-box {
    flex-basis: 160px !important;
    width: 100%;
    margin-left: auto !important;
  }

  .navbar .navbar-brand img {
    max-width: 120px;
    height: auto !important;
  }
}

/*================================================
Main Banner Area CSS
=================================================*/
.main-banner {
  position: relative;
  z-index: 1;
  background-image: url(/images/gray-bg.jpg);
  background-position: bottom right;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 100px;
}

.main-banner .container-fluid {
  max-width: 1395px;
}

.main-banner .container-fluid .row {
  margin-left: 0;
  margin-right: 0;
}

.main-banner .container-fluid .row .col-lg-6 {
  padding-left: 0;
  padding-right: 0;
}

.main-banner-content {
  max-width: 595px;
  position: relative;
  top: -60px;
  padding-right: 30px;
}

.main-banner-content h1 {
  margin-bottom: 25px;
  font-size: 48px;
  font-weight: 800;
}

.main-banner-content p {
  max-width: 450px;
  line-height: 1.8;
  font-weight: 600;
  font-size: 17px;
}

.main-banner-content .default-btn {
  margin-top: 10px;
}

.main-banner-courses-list {
  position: relative;
  z-index: 1;
}

.main-banner-courses-list .row {
  margin-left: -15px !important;
  margin-right: -15px !important;
}

.main-banner-courses-list .row .col-lg-6 {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.main-banner-courses-list .row .col-lg-6:nth-child(1) .single-courses-box {
  margin-top: 90px;
}

.main-banner-courses-list .single-courses-box {
  margin-bottom: 0;
}

.main-banner-courses-list .single-courses-box .courses-content {
  padding: 20px;
}

.banner-shape1 {
  position: absolute;
  left: 0;
  bottom: 30px;
  z-index: -1;
  text-align: center;
  right: 0;
}

.banner-shape1 img {
  -webkit-animation: moveleftbounce 4s linear infinite;
  animation: moveleftbounce 4s linear infinite;
}

.banner-shape2 {
  position: absolute;
  left: -85px;
  top: 17px;
  z-index: -1;
  right: 0;
  text-align: center;
}

.banner-shape2 img {
  -webkit-animation: movebounce 5s linear infinite;
  animation: movebounce 5s linear infinite;
}

.banner-shape3 {
  position: absolute;
  left: -40px;
  top: -35px;
  z-index: -2;
  right: 0;
  text-align: center;
}

.banner-shape3 img {
  -webkit-animation-name: rotateme;
  animation-name: rotateme;
  -webkit-animation-duration: 50s;
  animation-duration: 50s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

.banner-section {
  position: relative;
  z-index: 1;
  background-image: url(/images/banner-bg.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 60px;
  padding-bottom: 60px;
}

.banner-section .container-fluid {
  max-width: 1395px;
}

.banner-content h1 {
  color: #ffffff;
  margin-bottom: 20px;
  font-size: 48px;
  font-weight: 800;
}

.banner-content p {
  max-width: 450px;
  color: #d2d2d2;
  font-weight: 600;
  font-size: 17px;
}

.banner-content .default-btn {
  margin-top: 15px;
}

.banner-content .default-btn span {
  background-color: #ffffff;
}

.banner-content .default-btn:hover {
  color: #221638;
}

.banner-image {
  position: relative;
  z-index: 1;
}

.banner-shape4 {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 40px;
  right: 0;
  text-align: center;
}

.banner-shape4 img {
  -webkit-animation: moveleftbounce 4s linear infinite;
  animation: moveleftbounce 4s linear infinite;
}

.banner-shape5 {
  position: absolute;
  z-index: -1;
  right: 24.5%;
  bottom: 10.5%;
  text-align: center;
}

.banner-shape5 img {
  -webkit-animation: movescale 4s linear infinite;
  animation: movescale 4s linear infinite;
}

.banner-shape6 {
  position: absolute;
  z-index: -1;
  left: 12%;
  bottom: 8%;
}

.banner-shape6 img {
  -webkit-animation: movebounce 4s linear infinite;
  animation: movebounce 4s linear infinite;
}

.banner-shape7 {
  position: absolute;
  z-index: -1;
  top: 30%;
  left: -12%;
}

.banner-shape7 img {
  -webkit-animation: moveleftbounce 8s linear infinite;
  animation: moveleftbounce 8s linear infinite;
}

.banner-wrapper {
  height: 1000px;
  position: relative;
  z-index: 1;
  background-color: #221638;
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(/images/main-banner1.jpg);
}

.banner-wrapper::before {
  height: 512px;
  width: 100%;
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  z-index: 1;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(/images/banner-shape11.png);
}

.banner-wrapper-text {
  position: relative;
  z-index: 2;
  max-width: 590px;
  margin-left: auto;
  margin-top: -200px;
}

.banner-wrapper-text h1 {
  margin-bottom: 22px;
  color: #ffffff;
  font-size: 48px;
  font-weight: 800;
}

.banner-wrapper-text p {
  color: #ffffff;
  max-width: 500px;
  font-size: 16.5px;
}

.banner-wrapper-text .default-btn {
  margin-top: 10px;
}

.home-banner-area {
  background-color: #5172ff;
  background-position: center center;
  background-size: cover;
  background-image: url(/images/main-banner2.jpg);
  background-repeat: no-repeat;
  margin-bottom: 50px;
  margin-top: 25px;
  padding-top: 70px;
  padding-bottom: 70px;
  padding-left: 40px;
  padding-right: 40px;
}

.home-banner-area h1 {
  margin-bottom: 12px;
  max-width: 600px;
  color: #ffffff;
  font-size: 48px;
  font-weight: 800;
}

.home-banner-area p {
  max-width: 500px;
  color: #ffffff;
  font-weight: 600;
  font-size: 18px;
}

.banner-shape11 {
  position: absolute;
  z-index: -1;
  right: 21%;
  bottom: 24%;
}

.banner-shape11 img {
  -webkit-animation: movebounce 5s linear infinite;
  animation: movebounce 5s linear infinite;
}

.banner-shape12 {
  position: absolute;
  z-index: -1;
  right: 19%;
  top: 14%;
}

.banner-shape12 img {
  -webkit-animation: movescale 4s linear infinite;
  animation: movescale 4s linear infinite;
}

.banner-shape13 {
  position: absolute;
  z-index: -1;
  bottom: 5%;
  left: 4%;
}

.banner-shape13 img {
  -webkit-animation-name: rotateme;
  animation-name: rotateme;
  -webkit-animation-duration: 100s;
  animation-duration: 100s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

.main-banner-wrapper {
  position: relative;
  z-index: 2;
  background-color: #eae1d6;
  padding-bottom: 120px;
}

.main-banner-wrapper .container-fluid {
  max-width: 1395px;
}

.main-banner-wrapper-content {
  position: relative;
  top: 80px;
}

.main-banner-wrapper-content h1 {
  margin-bottom: 18px;
  font-size: 52px;
  font-weight: 800;
}

.main-banner-wrapper-content p {
  font-weight: 600;
  font-size: 17px;
}

.main-banner-wrapper-content .default-btn {
  margin-top: 10px;
}

.banner-shape14 {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}

.banner-shape14 img {
  -webkit-animation: movebounce 4s linear infinite;
  animation: movebounce 4s linear infinite;
}

.banner-shape15 {
  position: absolute;
  left: 0;
  bottom: -10px;
  z-index: -1;
}

.banner-shape15 img {
  -webkit-animation: movebounce 4s linear infinite;
  animation: movebounce 4s linear infinite;
}

.banner-shape16 {
  position: absolute;
  left: 130px;
  bottom: -25px;
  z-index: -1;
}

.banner-shape16 img {
  -webkit-animation-name: rotateme;
  animation-name: rotateme;
  -webkit-animation-duration: 50s;
  animation-duration: 50s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

.banner-shape17 {
  position: absolute;
  right: 0;
  bottom: -10px;
  z-index: -1;
}

.banner-shape17 img {
  -webkit-animation: movebounce 4s linear infinite;
  animation: movebounce 4s linear infinite;
}

.banner-shape18 {
  position: absolute;
  right: 400px;
  bottom: 0;
  z-index: -1;
}

.banner-shape18 img {
  -webkit-animation: moveleftbounce 4s linear infinite;
  animation: moveleftbounce 4s linear infinite;
}

.hero-banner-area {
  position: relative;
  z-index: 1;
  background-color: #fffaf3;
  padding-bottom: 200px;
}

.hero-banner-area .container-fluid {
  max-width: 1395px;
}

.hero-banner-area .divider {
  top: auto;
  bottom: 0;
  background: #f5f7fa;
}

.hero-banner-content {
  position: relative;
}

.hero-banner-content h1 {
  margin-bottom: 25px;
  font-size: 45px;
  font-weight: 800;
}

.hero-banner-content p {
  padding-right: 50px;
  line-height: 1.8;
  font-weight: 600;
  font-size: 17px;
}

.hero-banner-content .default-btn {
  margin-top: 10px;
}

.hero-banner-image {
  text-align: center;
}

.banner-shape19 {
  position: absolute;
  z-index: -1;
  left: 40%;
  bottom: 30%;
}

.banner-shape19 img {
  -webkit-animation-name: rotateme;
  animation-name: rotateme;
  -webkit-animation-duration: 30s;
  animation-duration: 30s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

/*================================================
Boxes Area CSS
=================================================*/
.boxes-area {
  position: relative;
  z-index: 2;
}

.single-box-item {
  text-align: center;
  border-radius: 8px;
  background-color: #ffffff;
  padding: 70px 30px 40px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  top: -250px;
  position: relative;
  margin-bottom: -250px;
}

.single-box-item .icon {
  font-size: 65px;
  line-height: 1;
  position: relative;
  margin-bottom: 40px;
  z-index: 1;
}

.single-box-item .icon img {
  position: absolute;
  left: 0;
  right: 0;
  top: -22px;
  z-index: -1;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.single-box-item h3 {
  margin-bottom: 12px;
  font-size: 23px;
  font-weight: 800;
}

.single-box-item p {
  margin-bottom: 12px;
}

.single-box-item .link-btn {
  display: inline-block;
  color: #5172ff;
  font-size: 15px;
  font-weight: 700;
}

.single-box-item .link-btn:hover {
  color: #221638;
}

.single-box-item:hover {
  -webkit-box-shadow: 0px 8px 16px 0px rgba(146, 184, 255, 0.2);
  box-shadow: 0px 8px 16px 0px rgba(146, 184, 255, 0.2);
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
}

.boxes-area.boxes-style-two .single-box-item {
  top: -130px;
  margin-bottom: -130px;
}

.single-box-item .image {
  margin-bottom: 30px;
}

.boxes-info {
  text-align: center;
  margin-top: 30px;
}

.boxes-info p {
  line-height: initial;
  font-weight: 600;
}

.boxes-info p a {
  font-weight: 700;
  color: #5172ff;
}

.boxes-info p a:hover {
  color: #221638;
}

/*================================================
About Area CSS
=================================================*/
.about-area {
  position: relative;
  z-index: 1;
}

.about-image {
  overflow: hidden;
  text-align: center;
}

.about-image .row {
  margin-left: -5px;
  margin-right: -5px;
}

.about-image .row .col-lg-6 {
  padding-left: 5px;
  padding-right: 5px;
}

.about-image .row .col-lg-6:nth-child(3) .image {
  margin-top: -15px;
}

.about-image .row .col-lg-6:nth-child(2) .image {
  margin-bottom: 20px;
}

.about-content {
  padding-left: 15px;
}

.about-content .sub-title {
  display: block;
  text-transform: uppercase;
  color: #5172ff;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 600;
}

.about-content h2 {
  margin-bottom: 15px;
  font-size: 36px;
  font-weight: 800;
}

.about-content .features-list {
  padding-left: 0;
  list-style-type: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 0;
  margin-top: -5px;
  margin-left: -8px;
  margin-right: -8px;
}

.about-content .features-list li {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 16px;
}

.about-content .features-list li span {
  display: block;
  position: relative;
  color: #221638;
  font-size: 18px;
  font-weight: 700;
  padding-left: 50px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.about-content .features-list li span i {
  position: absolute;
  left: 0;
  top: -2px;
  width: 40px;
  height: 40px;
  overflow: hidden;
  text-align: center;
  font-weight: 500;
  line-height: 40px;
  color: #5172ff;
  font-size: 25px;
  background-image: url(/images/circle-border.png);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.about-content .default-btn {
  margin-top: 30px;
}

.about-area-three {
  position: relative;
  z-index: 1;
}

.about-area-three .shape3 {
  right: 12%;
  bottom: auto;
  top: 38%;
}

.about-area-three .shape4 {
  right: 8%;
  bottom: auto;
  top: 20%;
}

.about-img {
  position: relative;
  z-index: 1;
}

.about-img .image {
  text-align: right;
  position: relative;
  padding-bottom: 45px;
}

.about-img .image img:nth-child(2) {
  position: absolute;
  left: 80px;
  bottom: 0;
}

.divider {
  width: 100%;
  position: absolute;
  height: 100px;
  pointer-events: none;
  -webkit-mask-image: url("data:image/svg+xml;utf8,%3csvg viewBox='0 0 100 100' width='100%' height='100%' xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none'%3e%3cpath d='M0,0 C16.6666667,66 33.3333333,98 50,98 C66.6666667,98 83.3333333,66 100,0 L100,100 L0,100 L0,0 Z' fill='%23fff'/%3e%3c/svg%3e");
  mask-image: url("data:image/svg+xml;utf8,%3csvg viewBox='0 0 100 100' width='100%' height='100%' xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none'%3e%3cpath d='M0,0 C16.6666667,66 33.3333333,98 50,98 C66.6666667,98 83.3333333,66 100,0 L100,100 L0,100 L0,0 Z' fill='%23fff'/%3e%3c/svg%3e");
  -webkit-mask-size: 100% 101%;
  mask-size: 100% 101%;
  background: #fffaf3;
  top: -100px;
  left: 0;
  z-index: -1;
}

.shape1 {
  position: absolute;
  left: 12%;
  top: 0;
  z-index: -1;
}

.shape1 img {
  -webkit-animation: moveleftbounce 4s linear infinite;
  animation: moveleftbounce 4s linear infinite;
}

.shape2 {
  position: absolute;
  left: 5%;
  bottom: 8%;
  z-index: -1;
}

.shape2 img {
  -webkit-animation: movebounce 5s linear infinite;
  animation: movebounce 5s linear infinite;
}

.shape3 {
  position: absolute;
  right: 9%;
  bottom: 12%;
  z-index: -1;
}

.shape3 img {
  -webkit-animation-name: rotateme;
  animation-name: rotateme;
  -webkit-animation-duration: 100s;
  animation-duration: 100s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

.shape4 {
  position: absolute;
  right: 5%;
  bottom: 40%;
  z-index: -1;
}

.shape4 img {
  -webkit-animation: movescale 4s linear infinite;
  animation: movescale 4s linear infinite;
}

.shape17 {
  position: absolute;
  z-index: -1;
  top: -45px;
  left: 160px;
  right: 0;
  text-align: center;
}

.shape17 img {
  -webkit-animation: moveleftbounce 4s linear infinite;
  animation: moveleftbounce 4s linear infinite;
}

.shape18 {
  position: absolute;
  z-index: -1;
  top: 0;
  left: -150px;
  right: 0;
  text-align: center;
}

.shape18 img {
  -webkit-animation-name: rotateme;
  animation-name: rotateme;
  -webkit-animation-duration: 100s;
  animation-duration: 100s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

.shape19 {
  position: absolute;
  right: -15px;
  top: 40%;
}

.shape19 img {
  -webkit-animation: movescale 4s linear infinite;
  animation: movescale 4s linear infinite;
}

.shape20 {
  position: absolute;
  z-index: -1;
  left: 7%;
  top: 57%;
}

.shape20 img {
  -webkit-animation: movescale 4s linear infinite;
  animation: movescale 4s linear infinite;
}

.shape21 {
  position: absolute;
  z-index: -1;
  left: 11%;
  bottom: 15%;
}

.shape21 img {
  -webkit-animation: movebounce 5s linear infinite;
  animation: movebounce 5s linear infinite;
}

.shape22 {
  position: absolute;
  z-index: -1;
  left: 55%;
  bottom: 10px;
}

.shape22 img {
  -webkit-animation-name: rotateme;
  animation-name: rotateme;
  -webkit-animation-duration: 50s;
  animation-duration: 50s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

.shape23 {
  position: absolute;
  z-index: -1;
  right: 5%;
  bottom: 5%;
}

.shape23 img {
  -webkit-animation-name: rotateme;
  animation-name: rotateme;
  -webkit-animation-duration: 100s;
  animation-duration: 100s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

@-webkit-keyframes movebounce {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }

  50% {
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }

  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

@keyframes movebounce {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }

  50% {
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }

  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

@-webkit-keyframes moveleftbounce {
  0% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }

  50% {
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
  }

  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}

@keyframes moveleftbounce {
  0% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }

  50% {
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
  }

  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}

@-webkit-keyframes rotateme {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotateme {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes movescale {
  0% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }

  50% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }

  100% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}

@keyframes movescale {
  0% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }

  50% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }

  100% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}

.about-content .about-list {
  padding-left: 0;
  list-style-type: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 0;
  margin-top: -5px;
  margin-left: -8px;
  margin-right: -8px;
}

.about-content .about-list li {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 16px;
}

.about-content .about-list li span {
  display: block;
  position: relative;
  font-weight: 700;
  padding-left: 40px;
  padding-top: 3px;
}

.about-content .about-list li span i {
  position: absolute;
  left: 0;
  top: 0;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  color: #ffffff;
  background-color: #a3c5bb;
  z-index: 1;
  border-radius: 50%;
  font-size: 20px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.about-content .about-list li span i::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #6ba292;
  border-radius: 50%;
  z-index: -1;
  margin: 2px 1px 1px 2px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.about-content .about-list li span:hover i {
  background-color: #5172ff;
  color: #5172ff;
}

.about-content .about-list li span:hover i::after {
  background-color: #ffffff;
}

.about-content .default-btn-style-two {
  margin-top: 35px;
}

/*================================================
Courses Area CSS
=================================================*/
.courses-area {
  position: relative;
  z-index: 1;
}

.single-courses-box {
  margin-bottom: 30px;
  position: relative;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0px 3px 6px #00000029;
}

.single-courses-box .courses-image {
  border-radius: 5px 5px 0 0;
  position: relative;
  z-index: 1;
}

.single-courses-box .courses-image .image {
  border-radius: 5px 5px 0 0;
}

.single-courses-box .courses-image .image img {
  border-radius: 5px 5px 0 0;
}

.single-courses-box .courses-image .fav {
  display: inline-block;
  position: absolute;
  right: 20px;
  top: 20px;
  color: #ffffff;
  font-size: 30px;
  line-height: 1;
}

.single-courses-box .courses-image .fav:hover {
  color: #5172ff;
}

.single-courses-box .courses-image .price {
  display: inline-block;
  background-color: #5172ff;
  color: #ffffff;
  width: 135px;
  height: 48px;
  border-radius: 6px;
  position: absolute;
  right: 0px;
  bottom: 5px;
  text-align: center;
  line-height: 48px;
  font-size: 24px;
  font-weight: 600;
}

.single-courses-box .courses-content {
  border-radius: 0 0 5px 5px;
  padding: 20px 30px;
}

.single-courses-box .courses-content .course-author {
  margin-bottom: 15px;
}

.single-courses-box .courses-content .course-author img {
  width: 35px;
  height: 35px;
  margin-right: 10px;
}

.single-courses-box .courses-content .course-author span {
  color: #5172ff;
  font-weight: 600;
  font-size: 15px;
}

.single-courses-box .courses-content h3 {
  margin-bottom: 12px;
  line-height: 1.3;
  font-size: 16px;
  font-weight: 600;
}

.single-courses-box .courses-content h3 a {
  display: inline-block;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  /* overflow: hidden; */
  margin-bottom: 40%;
}

@media (max-width: 480px) {
  .single-courses-box .courses-content h3 a {
    display: inline-block;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    /* overflow: hidden; */
    margin-bottom: 90%;
  }
}

.single-courses-box .courses-content .courses-box-footer {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
  margin-left: -7px;
  margin-right: -7px;
  margin-top: 20px;
}

.single-courses-box .courses-content .courses-box-footer li {
  color: #606060;
  font-size: 15px;
  position: relative;
  padding-left: 25px;
  padding-right: 7px;
}

.single-courses-box .courses-content .courses-box-footer li i {
  color: #5172ff;
  position: absolute;
  left: 7px;
  top: 0;
}

.single-courses-box .courses-content .courses-box-footer li i.flaticon-people {
  font-size: 18px;
  left: 3px;
}

.single-courses-box.bg-color {
  background-color: #fffaf3;
  -webkit-box-shadow: unset;
  box-shadow: unset;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.single-courses-box.bg-color .courses-content h3 {
  margin-bottom: 10px;
}

.single-courses-box.bg-color:hover {
  background-color: #ffffff;
  -webkit-box-shadow: 0px 8px 16px 0px rgba(146, 184, 255, 0.2);
  box-shadow: 0px 8px 16px 0px rgba(146, 184, 255, 0.2);
}

.single-courses-box.without-boxshadow {
  background-color: #ffffff;
  -webkit-box-shadow: unset;
  box-shadow: unset;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.single-courses-box.without-boxshadow .courses-content h3 {
  margin-bottom: 10px;
}

.single-courses-box.without-boxshadow:hover,
.single-courses-box.without-boxshadow.active {
  -webkit-box-shadow: 0px 8px 16px 0px rgba(146, 184, 255, 0.2);
  box-shadow: 0px 8px 16px 0px rgba(146, 184, 255, 0.2);
}

.courses-info {
  text-align: center;
  margin-top: 20px;
}

.courses-info .default-btn {
  margin-bottom: 15px;
}

.courses-info p {
  max-width: 630px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
}

.courses-info p a {
  color: #5172ff;
}

.courses-info p a:hover {
  text-decoration: underline;
  color: #221638;
}

.courses-slides.owl-theme .owl-dots {
  margin-top: 20px !important;
  margin-bottom: 35px;
}

.courses-slides.owl-theme .owl-dots .owl-dot span {
  margin: 0 3px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: transparent !important;
  border: 1px solid #a8a8a8;
  position: relative;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.courses-slides.owl-theme .owl-dots .owl-dot span::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: transparent;
  border-radius: 50%;
  margin: 2px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.courses-slides.owl-theme .owl-dots .owl-dot:hover span,
.courses-slides.owl-theme .owl-dots .owl-dot.active span {
  border-color: #5172ff;
}

.courses-slides.owl-theme .owl-dots .owl-dot:hover span::before,
.courses-slides.owl-theme .owl-dots .owl-dot.active span::before {
  background-color: #5172ff;
}

.courses-slides.owl-theme .owl-nav {
  margin-top: 0;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -3px;
}

.courses-slides.owl-theme .owl-nav [class*="owl-"] {
  margin: 0 40px;
  width: 35px;
  height: 35px;
  border-radius: 3px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  background-color: #ffffff;
  -webkit-box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
  box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
  line-height: 42px;
  padding: 0;
  font-size: 25px;
  color: #5172ff;
}

.courses-slides.owl-theme .owl-nav [class*="owl-"]:hover {
  background-color: #5172ff;
  color: #ffffff;
}

.shorting-menu {
  text-align: center;
  margin-bottom: 30px;
}

.shorting-menu .filter {
  display: inline-block;
  padding: 0;
  background-color: transparent;
  color: #221638;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  border: none;
  font-size: 16.5px;
  font-weight: 700;
  margin-left: 10px;
  margin-right: 10px;
}

.shorting-menu .filter.active,
.shorting-menu .filter:hover {
  color: #5172ff;
}

.shorting-menu.shorting-menu-style-two {
  background-color: #f8f9f8;
  border-radius: 5px;
  margin-bottom: 40px;
  padding-left: 60px;
  padding-right: 60px;
}

.shorting-menu.shorting-menu-style-two .filter {
  position: relative;
  color: #606060;
  padding-top: 16px;
  padding-bottom: 14px;
  padding-left: 7px;
  padding-right: 7px;
}

.shorting-menu.shorting-menu-style-two .filter::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background-color: #5172ff;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
}

.shorting-menu.shorting-menu-style-two .filter:hover,
.shorting-menu.shorting-menu-style-two .filter.active {
  color: #221638;
}

.shorting-menu.shorting-menu-style-two .filter:hover::before,
.shorting-menu.shorting-menu-style-two .filter.active::before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

.shorting .mix {
  display: none;
}

.edemy-grid-sorting {
  margin-bottom: 40px;
}

.edemy-grid-sorting .result-count p .count {
  font-weight: 700;
  color: #221638;
}

.edemy-grid-sorting .ordering {
  text-align: right;
}

.edemy-grid-sorting .ordering label {
  display: inline-block;
  margin-bottom: 0;
  color: #ababab;
  margin-right: 5px;
  font-size: 16px;
  font-weight: 700;
}

.edemy-grid-sorting .ordering .nice-select {
  display: inline-block;
  width: 215px;
  background: #f8f8f8;
  border-color: #eeeeee;
  color: #221638;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  padding: 0 0 0 12px;
  height: 45px;
  line-height: 45px;
  font-size: 16px;
  font-weight: 700;
}

.edemy-grid-sorting .ordering .nice-select .list {
  background-color: #ffffff;
  border-radius: 5px;
  -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  list-style-type: none;
  border: none;
  width: 100%;
  margin-bottom: 0;
  margin-top: 0;
  padding-left: 0;
  padding-top: 10px;
  padding-bottom: 10px;
}

.edemy-grid-sorting .ordering .nice-select .list .option {
  line-height: 38px;
  min-height: 38px;
  color: #221638;
  position: relative;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  padding-left: 32px;
  padding-right: 25px;
  font-size: 16px;
  font-weight: 700;
}

.edemy-grid-sorting .ordering .nice-select .list .option:hover {
  background-color: #5172ff !important;
  color: #ffffff;
}

.edemy-grid-sorting .ordering .nice-select .list .option:hover::before {
  color: #ffffff;
}

.edemy-grid-sorting .ordering .nice-select .list .option.focus,
.edemy-grid-sorting .ordering .nice-select .list .option.selected.focus {
  background-color: transparent !important;
  color: #221638;
}

.edemy-grid-sorting .ordering .nice-select .list .option::before {
  content: "\f11f";
  position: absolute;
  left: 10px;
  top: 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  color: #5172ff;
  font-family: Flaticon;
  font-size: 11px;
}

.edemy-grid-sorting .ordering .nice-select .list .option.selected::before {
  opacity: 1;
  visibility: visible;
}

.edemy-grid-sorting .ordering .nice-select .list .option.selected:hover {
  background-color: #5172ff !important;
  color: #ffffff;
}

.edemy-grid-sorting .ordering .nice-select .list .option.selected:hover::before {
  color: #ffffff;
}

.edemy-grid-sorting .ordering .nice-select:after {
  border-color: #221638;
  height: 8px;
  width: 8px;
  margin-top: -5px;
}

.edemy-grid-sorting .ordering .nice-select:hover {
  border-color: #5172ff;
  background-color: transparent;
}

.load-more-btn {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
}

.load-more-btn .load-more {
  display: inline-block;
  background-color: transparent;
  border: 1px solid #eeeeee;
  padding: 13px 65px 13px 40px;
  border-radius: 5px;
  color: #5172ff;
  position: relative;
  font-size: 16px;
  font-weight: 700;
}

.load-more-btn .load-more i {
  position: absolute;
  right: 40px;
  top: 50%;
  font-size: 18px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.load-more-btn .load-more:hover {
  color: #ffffff;
  border-color: #ffffff;
  background-color: #5172ff;
}

.courses-items {
  margin-left: -15px;
  margin-right: -15px;
}

.single-courses-box .courses-image .price.bg-45a393 {
  background-color: #6ba292;
}

.courses-area.bg-image {
  background-color: #fff7f4;
  background-image: url(/images/courses-bg.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

/*================================================
Courses Details Area CSS
=================================================*/
.courses-details-desc {
  margin-top: 60px;
  padding-right: 15px;
}

.courses-details-desc .react-tabs__tab-list {
  margin-bottom: 0;
  list-style-type: none;
  background-color: #f8f9f8;
  border: none;
  border-radius: 5px;
  padding-left: 30px;
  padding-right: 30px;
}

.courses-details-desc .react-tabs__tab-list .react-tabs__tab {
  margin-bottom: 0;
  margin-right: 40px;
  border-radius: 0;
  border: none;
  padding: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  padding: 14px 0;
  background-color: transparent;
  position: relative;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 800;
}

.courses-details-desc .react-tabs__tab-list .react-tabs__tab::before {
  content: "";
  bottom: 1px;
  height: 2px;
  background-color: #5172ff;
  position: absolute;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  left: 0;
  width: 100%;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
}

.courses-details-desc .react-tabs__tab-list .react-tabs__tab:hover,
.courses-details-desc .react-tabs__tab-list .react-tabs__tab.react-tabs__tab--selected {
  color: #221638;
}

.courses-details-desc .react-tabs__tab-list .react-tabs__tab:hover::before,
.courses-details-desc .react-tabs__tab-list .react-tabs__tab.react-tabs__tab--selected::before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

.courses-details-desc .react-tabs__tab-list .react-tabs__tab:last-child {
  margin-right: 0;
}

.courses-details-desc .react-tabs__tab-panel {
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 40px;
}

.courses-details-desc .react-tabs__tab-panel .courses-overview h3 {
  margin-bottom: 12px;
  font-size: 24px;
  font-weight: 800;
}

.courses-details-desc .react-tabs__tab-panel .courses-overview h3:not(:first-child) {
  margin-top: 30px;
}

.courses-details-desc .react-tabs__tab-panel .courses-curriculum {
  border: 1px solid #eeeeee;
  border-radius: 5px;
  padding: 30px;
  margin-left: -30px;
  margin-right: -30px;
}

.courses-details-desc .react-tabs__tab-panel .courses-curriculum h3 {
  margin-bottom: 18px;
  font-size: 20px;
  font-weight: 800;
}

.courses-details-desc .react-tabs__tab-panel .courses-curriculum h3:not(:first-child) {
  margin-top: 30px;
}

.courses-details-desc .react-tabs__tab-panel .courses-curriculum ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}

.courses-details-desc .react-tabs__tab-panel .courses-curriculum ul li {
  display: block;
}

.courses-details-desc .react-tabs__tab-panel .courses-curriculum ul li a {
  background-color: #f8f9f8;
  color: #221638;
  position: relative;
  padding-left: 55px;
  padding-right: 30px;
  padding-top: 14.5px;
  padding-bottom: 14.5px;
  margin-left: -30px;
  margin-right: -30px;
}

.courses-details-desc .react-tabs__tab-panel .courses-curriculum ul li a::before {
  content: "\f104";
  position: absolute;
  left: 30px;
  color: #5172ff;
  top: 14px;
  font-size: 18px;
  font-family: Flaticon;
}

.courses-details-desc .react-tabs__tab-panel .courses-curriculum ul li a .courses-name {
  font-size: 14.5px;
  font-weight: 700;
}

.courses-details-desc .react-tabs__tab-panel .courses-curriculum ul li a .courses-meta {
  text-align: right;
}

.courses-details-desc .react-tabs__tab-panel .courses-curriculum ul li a .courses-meta .questions {
  display: inline-block;
  background: #e3f1f2;
  color: #2dbbc4;
  text-transform: lowercase;
  border-radius: 3px;
  margin-right: 6px;
  padding: 2px 10px 1.5px;
  font-size: 14px;
  font-weight: 700;
}

.courses-details-desc .react-tabs__tab-panel .courses-curriculum ul li a .courses-meta .duration {
  display: inline-block;
  background: #f7e7e8;
  color: #5172ff;
  text-transform: lowercase;
  border-radius: 3px;
  padding: 2px 10px 1.5px;
  font-size: 14px;
  font-weight: 700;
}

.courses-details-desc .react-tabs__tab-panel .courses-curriculum ul li a .courses-meta .status {
  display: inline-block;
  background: #221638;
  color: #ffffff;
  border-radius: 3px;
  margin-left: 6px;
  padding: 2px 10px 1.5px;
  font-size: 14px;
  font-weight: 700;
}

.courses-details-desc .react-tabs__tab-panel .courses-curriculum ul li a .courses-meta .status.locked {
  color: #221638;
  background-color: transparent;
  padding: 0;
  margin-left: 8px;
  border-radius: 0;
  position: relative;
  top: 1px;
  font-size: 18px;
  font-weight: normal;
}

.courses-details-desc .react-tabs__tab-panel .courses-curriculum ul li a:hover {
  color: #5172ff;
}

.courses-details-desc .react-tabs__tab-panel .courses-curriculum ul li:nth-child(2) a,
.courses-details-desc .react-tabs__tab-panel .courses-curriculum ul li:nth-child(4) a,
.courses-details-desc .react-tabs__tab-panel .courses-curriculum ul li:nth-child(6) a,
.courses-details-desc .react-tabs__tab-panel .courses-curriculum ul li:nth-child(8) a,
.courses-details-desc .react-tabs__tab-panel .courses-curriculum ul li:nth-child(10) a,
.courses-details-desc .react-tabs__tab-panel .courses-curriculum ul li:nth-child(12) a,
.courses-details-desc .react-tabs__tab-panel .courses-curriculum ul li:nth-child(14) a,
.courses-details-desc .react-tabs__tab-panel .courses-curriculum ul li:nth-child(16) a,
.courses-details-desc .react-tabs__tab-panel .courses-curriculum ul li:nth-child(18) a,
.courses-details-desc .react-tabs__tab-panel .courses-curriculum ul li:nth-child(20) a,
.courses-details-desc .react-tabs__tab-panel .courses-curriculum ul li:nth-child(22) a,
.courses-details-desc .react-tabs__tab-panel .courses-curriculum ul li:nth-child(24) a,
.courses-details-desc .react-tabs__tab-panel .courses-curriculum ul li:nth-child(26) a,
.courses-details-desc .react-tabs__tab-panel .courses-curriculum ul li:nth-child(28) a,
.courses-details-desc .react-tabs__tab-panel .courses-curriculum ul li:nth-child(30) a,
.courses-details-desc .react-tabs__tab-panel .courses-curriculum ul li:nth-child(32) a,
.courses-details-desc .react-tabs__tab-panel .courses-curriculum ul li:nth-child(34) a,
.courses-details-desc .react-tabs__tab-panel .courses-curriculum ul li:nth-child(36) a,
.courses-details-desc .react-tabs__tab-panel .courses-curriculum ul li:nth-child(38) a,
.courses-details-desc .react-tabs__tab-panel .courses-curriculum ul li:nth-child(40) a,
.courses-details-desc .react-tabs__tab-panel .courses-curriculum ul li:nth-child(42) a,
.courses-details-desc .react-tabs__tab-panel .courses-curriculum ul li:nth-child(44) a,
.courses-details-desc .react-tabs__tab-panel .courses-curriculum ul li:nth-child(46) a,
.courses-details-desc .react-tabs__tab-panel .courses-curriculum ul li:nth-child(48) a,
.courses-details-desc .react-tabs__tab-panel .courses-curriculum ul li:nth-child(50) a,
.courses-details-desc .react-tabs__tab-panel .courses-curriculum ul li:nth-child(52) a,
.courses-details-desc .react-tabs__tab-panel .courses-curriculum ul li:nth-child(54) a,
.courses-details-desc .react-tabs__tab-panel .courses-curriculum ul li:nth-child(56) a,
.courses-details-desc .react-tabs__tab-panel .courses-curriculum ul li:nth-child(58) a,
.courses-details-desc .react-tabs__tab-panel .courses-curriculum ul li:nth-child(60) a,
.courses-details-desc .react-tabs__tab-panel .courses-curriculum ul li:nth-child(62) a,
.courses-details-desc .react-tabs__tab-panel .courses-curriculum ul li:nth-child(64) a,
.courses-details-desc .react-tabs__tab-panel .courses-curriculum ul li:nth-child(66) a,
.courses-details-desc .react-tabs__tab-panel .courses-curriculum ul li:nth-child(68) a,
.courses-details-desc .react-tabs__tab-panel .courses-curriculum ul li:nth-child(70) a,
.courses-details-desc .react-tabs__tab-panel .courses-curriculum ul li:nth-child(72) a,
.courses-details-desc .react-tabs__tab-panel .courses-curriculum ul li:nth-child(74) a,
.courses-details-desc .react-tabs__tab-panel .courses-curriculum ul li:nth-child(76) a,
.courses-details-desc .react-tabs__tab-panel .courses-curriculum ul li:nth-child(78) a,
.courses-details-desc .react-tabs__tab-panel .courses-curriculum ul li:nth-child(80) a,
.courses-details-desc .react-tabs__tab-panel .courses-curriculum ul li:nth-child(82) a,
.courses-details-desc .react-tabs__tab-panel .courses-curriculum ul li:nth-child(84) a,
.courses-details-desc .react-tabs__tab-panel .courses-curriculum ul li:nth-child(86) a,
.courses-details-desc .react-tabs__tab-panel .courses-curriculum ul li:nth-child(88) a,
.courses-details-desc .react-tabs__tab-panel .courses-curriculum ul li:nth-child(90) a,
.courses-details-desc .react-tabs__tab-panel .courses-curriculum ul li:nth-child(92) a,
.courses-details-desc .react-tabs__tab-panel .courses-curriculum ul li:nth-child(94) a,
.courses-details-desc .react-tabs__tab-panel .courses-curriculum ul li:nth-child(96) a,
.courses-details-desc .react-tabs__tab-panel .courses-curriculum ul li:nth-child(98) a,
.courses-details-desc .react-tabs__tab-panel .courses-curriculum ul li:nth-child(100) a {
  background-color: #ffffff;
}

.courses-details-desc .react-tabs__tab-panel .single-advisor-box {
  margin-bottom: 0;
}

.courses-details-desc .react-tabs__tab-panel .single-advisor-box .advisor-image {
  top: 0;
  margin-bottom: 0;
}

.courses-details-desc .react-tabs__tab-panel .single-advisor-box .advisor-content {
  padding: 0 0 0 20px;
}

.courses-details-desc .react-tabs__tab-panel .courses-reviews h3 {
  margin-bottom: 0;
  display: inline-block;
  margin-right: 15px;
  font-size: 21px;
  font-weight: 800;
}

.courses-details-desc .react-tabs__tab-panel .courses-reviews .rating {
  display: inline-block;
  position: relative;
  top: 1px;
}

.courses-details-desc .react-tabs__tab-panel .courses-reviews .rating span {
  font-size: 19px;
  color: #cecfd2;
  margin-right: 2px;
}

.courses-details-desc .react-tabs__tab-panel .courses-reviews .rating span.checked {
  color: orange;
}

.courses-details-desc .react-tabs__tab-panel .courses-reviews .rating-count {
  margin-top: 10px;
  margin-bottom: 20px;
}

.courses-details-desc .react-tabs__tab-panel .courses-reviews .rating-count span {
  display: block;
  font-size: 16px;
  color: #606060;
}

.courses-details-desc .react-tabs__tab-panel .courses-reviews .row {
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}

.courses-details-desc .react-tabs__tab-panel .courses-reviews .side {
  float: left;
  width: 9%;
  margin-top: 10px;
}

.courses-details-desc .react-tabs__tab-panel .courses-reviews .side div {
  font-size: 16px;
  font-weight: 700;
}

.courses-details-desc .react-tabs__tab-panel .courses-reviews .middle {
  margin-top: 14px;
  float: left;
  width: 82%;
}

.courses-details-desc .react-tabs__tab-panel .courses-reviews .right {
  text-align: right;
}

.courses-details-desc .react-tabs__tab-panel .courses-reviews .bar-container {
  width: 100%;
  background-color: #f1f1f1;
  text-align: center;
  color: #ffffff;
  border-radius: 5px;
}

.courses-details-desc .react-tabs__tab-panel .courses-reviews .bar-5 {
  width: 100%;
  height: 18px;
  background-color: #4caf50;
  border-radius: 5px;
}

.courses-details-desc .react-tabs__tab-panel .courses-reviews .bar-4 {
  width: 75%;
  height: 18px;
  background-color: #2196f3;
  border-radius: 5px;
  border-radius: 5px;
}

.courses-details-desc .react-tabs__tab-panel .courses-reviews .bar-3 {
  width: 50%;
  height: 18px;
  background-color: #00bcd4;
  border-radius: 5px;
}

.courses-details-desc .react-tabs__tab-panel .courses-reviews .bar-2 {
  width: 25%;
  height: 18px;
  background-color: #ff9800;
  border-radius: 5px;
}

.courses-details-desc .react-tabs__tab-panel .courses-reviews .bar-1 {
  width: 0;
  height: 18px;
  background-color: #f44336;
  border-radius: 5px;
}

.courses-details-desc .react-tabs__tab-panel .courses-review-comments {
  margin-top: 40px;
}

.courses-details-desc .react-tabs__tab-panel .courses-review-comments h3 {
  border-bottom: 1px solid #f3f3f3;
  padding-bottom: 12px;
  margin-bottom: 8px;
  font-size: 21px;
  font-weight: 800;
}

.courses-details-desc .react-tabs__tab-panel .courses-review-comments .user-review {
  border-bottom: 1px solid #f3f3f3;
  padding: 20px 0 20px 110px;
  position: relative;
}

.courses-details-desc .react-tabs__tab-panel .courses-review-comments .user-review img {
  position: absolute;
  left: 0;
  top: 20px;
  width: 90px;
  height: 90px;
  border-radius: 5px;
}

.courses-details-desc .react-tabs__tab-panel .courses-review-comments .user-review .sub-comment {
  margin-bottom: 8px;
  font-weight: 700;
}

.courses-details-desc .react-tabs__tab-panel .courses-review-comments .user-review .review-rating {
  display: block;
  margin-bottom: 8px;
}

.courses-details-desc .react-tabs__tab-panel .courses-review-comments .user-review .review-rating .review-stars {
  display: inline-block;
}

.courses-details-desc .react-tabs__tab-panel .courses-review-comments .user-review .review-rating .review-stars i {
  color: #cecfd2;
  font-size: 18px;
  display: inline-block;
  margin-right: 2px;
}

.courses-details-desc .react-tabs__tab-panel .courses-review-comments .user-review .review-rating .review-stars i.checked {
  color: orange;
}

.courses-details-desc .react-tabs__tab-panel .courses-review-comments .user-review .review-rating span {
  color: #221638;
  position: relative;
  top: -2px;
  font-weight: 700;
  margin-left: 5px;
}

.courses-details-info {
  background-color: #f8f9f8;
  border-radius: 5px;
  padding: 12px 12px 50px 12px;
  margin-left: 15px;
  margin-top: -248px;
}

.courses-details-info .image {
  text-align: center;
  position: relative;
}

.courses-details-info .image .link-btn {
  display: block;
  width: 100%;
  height: 100%;
  z-index: 3;
  position: absolute;
  left: 0;
  top: 0;
}

.courses-details-info .image .content {
  position: absolute;
  left: 0;
  z-index: 2;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.courses-details-info .image .content i {
  display: block;
  width: 60px;
  height: 60px;
  background-color: #5172ff;
  color: #ffffff;
  border-radius: 50%;
  line-height: 60px;
  font-size: 25px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;
}

.courses-details-info .image .content span {
  display: inline-block;
  color: #ffffff;
  border-radius: 5px;
  padding: 4px 20px;
  background-color: #221638;
  font-size: 17px;
  font-weight: 700;
}

.courses-details-info .image::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background-color: #221638;
  opacity: 0.25;
}

.courses-details-info .info {
  margin-bottom: 0;
  list-style-type: none;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 35px;
}

.courses-details-info .info li {
  border-bottom: 1px solid #e5e5e7;
  color: #606060;
  font-size: 17px;
  font-weight: 600;
  padding-top: 15px;
  padding-bottom: 14px;
}

.courses-details-info .info li span {
  font-weight: 800;
  color: #221638;
  position: relative;
  padding-left: 28px;
}

.courses-details-info .info li span i {
  color: #5172ff;
  position: absolute;
  left: 0;
  font-weight: normal;
  top: -2px;
  font-size: 20px;
}

.courses-details-info .info li.price {
  padding-bottom: 10px;
  color: #5172ff;
  font-size: 28px;
  font-weight: 800;
}

.courses-details-info .info li.price span {
  color: #221638;
  font-size: 17px;
  font-weight: 800;
}

.courses-details-info .info li:first-child {
  padding-top: 0;
}

.courses-details-info .btn-box {
  margin-top: 35px;
  padding-left: 25px;
  padding-right: 25px;
}

.courses-details-info .btn-box .default-btn {
  display: block;
  padding-right: 30px;
  padding-left: 30px;
  padding-top: 12px;
  padding-bottom: 12px;
}

.courses-details-info .btn-box .default-btn i {
  position: relative;
  left: 0;
  top: 2px;
  margin-right: 8px;
  line-height: 1;
  font-weight: normal;
  font-size: 20px;
}

.courses-details-info .btn-box .default-btn:not(:first-child) {
  margin-top: 15px;
  background-color: transparent;
  color: #221638;
  border: 1px solid #221638;
}

.courses-details-info .btn-box .default-btn:not(:first-child):hover {
  color: #ffffff;
  border-color: #221638;
}

.courses-details-info .courses-share {
  text-align: center;
  margin-top: 20px;
  padding-left: 25px;
  padding-right: 25px;
}

.courses-details-info .courses-share .share-info {
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.courses-details-info .courses-share .share-info span {
  display: inline-block;
  color: #5172ff;
  line-height: 1;
  font-size: 16px;
  font-weight: 700;
}

.courses-details-info .courses-share .share-info span i {
  color: #221638;
}

.courses-details-info .courses-share .share-info .social-link {
  padding-left: 0;
  list-style-type: none;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -35px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  margin-bottom: 0;
}

.courses-details-info .courses-share .share-info .social-link li {
  display: inline-block;
  margin-right: 3px;
}

.courses-details-info .courses-share .share-info .social-link li:last-child {
  margin-right: 0;
}

.courses-details-info .courses-share .share-info .social-link li a {
  width: 30px;
  height: 30px;
  text-align: center;
  background-color: #e1e1e1;
  font-size: 18px;
  color: #221638;
  position: relative;
  border-radius: 2px;
}

.courses-details-info .courses-share .share-info .social-link li a i {
  position: absolute;
  left: 0;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-48%);
  transform: translateY(-48%);
}

.courses-details-info .courses-share .share-info .social-link li a:hover {
  background-color: #5172ff;
  color: #ffffff;
}

.courses-details-info .courses-share .share-info:hover .social-link {
  opacity: 1;
  visibility: visible;
  bottom: -42px;
}

.courses-details-header {
  margin-bottom: 20px;
}

.courses-details-header .courses-title p {
  font-size: 17px;
}

.courses-details-header .courses-meta {
  margin-top: 20px;
}

.courses-details-header .courses-meta ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}

.courses-details-header .courses-meta ul li {
  margin-right: 20px;
  position: relative;
  display: inline-block;
  border-right: 1px solid #eeeeee;
  font-weight: 800;
  font-size: 17px;
  padding-right: 20px;
  padding-left: 40px;
}

.courses-details-header .courses-meta ul li i {
  font-size: 28px;
  color: #cfcfcf;
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.courses-details-header .courses-meta ul li span {
  display: block;
  color: #5172ff;
  text-transform: uppercase;
  margin-bottom: 1px;
  font-size: 13.5px;
  font-weight: 700;
}

.courses-details-header .courses-meta ul li a {
  display: inline-block;
  color: #221638;
}

.courses-details-header .courses-meta ul li a:hover,
.courses-details-header .courses-meta ul li a:focus {
  color: #5172ff;
}

.courses-details-header .courses-meta ul li:last-child {
  padding-right: 0;
  margin-right: 0;
  border-right: none;
}

.courses-details-header .courses-price {
  text-align: right;
}

.courses-details-header .courses-price .price {
  display: inline-block;
  margin-right: 15px;
  line-height: 1;
  position: relative;
  top: -9px;
  font-size: 36px;
  font-weight: 800;
}

.courses-details-header .courses-price .courses-review {
  display: block;
  margin-bottom: 10px;
}

.courses-details-header .courses-price .courses-review .review-stars {
  display: inline-block;
}

.courses-details-header .courses-price .courses-review .review-stars i {
  color: #f2b827;
  font-size: 18px;
  display: inline-block;
  margin-right: 2px;
}

.courses-details-header .courses-price .courses-review .reviews-total {
  position: relative;
  color: #606060;
  position: relative;
  top: -1px;
  margin-left: 2px;
  font-weight: 700;
}

.courses-details-image-style-two {
  border-radius: 5px;
}

.courses-details-image-style-two img {
  width: 100%;
  border-radius: 5px;
}

.courses-details-desc-style-two {
  margin-top: 35px;
}

.courses-details-desc-style-two h3 {
  margin-bottom: 15px;
  font-size: 24px;
  font-weight: 800;
}

.courses-details-desc-style-two h3:not(first-child) {
  margin-top: 30px;
}

.courses-details-desc-style-two .why-you-learn {
  margin-top: 20px;
}

.courses-details-desc-style-two .why-you-learn ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  list-style-type: none;
  padding-left: 15px;
  margin-left: -10px;
  margin-right: -10px;
  margin-bottom: -12px;
}

.courses-details-desc-style-two .why-you-learn ul li {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 12px;
}

.courses-details-desc-style-two .why-you-learn ul li span {
  display: block;
  color: #221638;
  position: relative;
  padding-left: 22px;
  line-height: 1.7;
  font-weight: 500;
}

.courses-details-desc-style-two .why-you-learn ul li span i {
  position: absolute;
  left: 0;
  top: 3px;
  color: #5172ff;
  font-size: 13px;
}

.courses-details-desc-style-two .courses-curriculum {
  border: 1px solid #eeeeee;
  border-radius: 5px;
  padding: 30px;
  margin-top: 20px;
}

.courses-details-desc-style-two .courses-curriculum h3 {
  margin-top: 0;
  margin-bottom: 18px;
  font-size: 18px;
  font-weight: 800;
}

.courses-details-desc-style-two .courses-curriculum h3:not(:first-child) {
  margin-top: 30px;
}

.courses-details-desc-style-two .courses-curriculum ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}

.courses-details-desc-style-two .courses-curriculum ul li {
  display: block;
}

.courses-details-desc-style-two .courses-curriculum ul li a {
  background-color: #f8f9f8;
  color: #221638;
  position: relative;
  padding-left: 55px;
  padding-right: 30px;
  padding-top: 14.5px;
  padding-bottom: 14.5px;
  margin-left: -30px;
  margin-right: -30px;
}

.courses-details-desc-style-two .courses-curriculum ul li a::before {
  content: "\f104";
  position: absolute;
  left: 30px;
  color: #5172ff;
  top: 14px;
  font-size: 18px;
  font-family: Flaticon;
}

.courses-details-desc-style-two .courses-curriculum ul li a .courses-name {
  font-size: 14.5px;
  font-weight: 700;
}

.courses-details-desc-style-two .courses-curriculum ul li a .courses-meta {
  text-align: right;
}

.courses-details-desc-style-two .courses-curriculum ul li a .courses-meta .questions {
  display: inline-block;
  background: #e3f1f2;
  color: #2dbbc4;
  text-transform: lowercase;
  border-radius: 3px;
  margin-right: 6px;
  padding: 2px 10px 1.5px;
  font-size: 14px;
  font-weight: 700;
}

.courses-details-desc-style-two .courses-curriculum ul li a .courses-meta .duration {
  display: inline-block;
  background: #f7e7e8;
  color: #5172ff;
  text-transform: lowercase;
  border-radius: 3px;
  padding: 2px 10px 1.5px;
  font-size: 14px;
  font-weight: 700;
}

.courses-details-desc-style-two .courses-curriculum ul li a .courses-meta .status {
  display: inline-block;
  background: #221638;
  color: #ffffff;
  border-radius: 3px;
  margin-left: 6px;
  padding: 2px 10px 1.5px;
  font-size: 14px;
  font-weight: 700;
}

.courses-details-desc-style-two .courses-curriculum ul li a .courses-meta .status.locked {
  color: #221638;
  background-color: transparent;
  padding: 0;
  margin-left: 8px;
  border-radius: 0;
  position: relative;
  top: 1px;
  font-size: 18px;
  font-weight: normal;
}

.courses-details-desc-style-two .courses-curriculum ul li a:hover {
  color: #5172ff;
}

.courses-details-desc-style-two .courses-curriculum ul li:nth-child(2) a,
.courses-details-desc-style-two .courses-curriculum ul li:nth-child(4) a,
.courses-details-desc-style-two .courses-curriculum ul li:nth-child(6) a,
.courses-details-desc-style-two .courses-curriculum ul li:nth-child(8) a,
.courses-details-desc-style-two .courses-curriculum ul li:nth-child(10) a,
.courses-details-desc-style-two .courses-curriculum ul li:nth-child(12) a,
.courses-details-desc-style-two .courses-curriculum ul li:nth-child(14) a,
.courses-details-desc-style-two .courses-curriculum ul li:nth-child(16) a,
.courses-details-desc-style-two .courses-curriculum ul li:nth-child(18) a,
.courses-details-desc-style-two .courses-curriculum ul li:nth-child(20) a,
.courses-details-desc-style-two .courses-curriculum ul li:nth-child(22) a,
.courses-details-desc-style-two .courses-curriculum ul li:nth-child(24) a,
.courses-details-desc-style-two .courses-curriculum ul li:nth-child(26) a,
.courses-details-desc-style-two .courses-curriculum ul li:nth-child(28) a,
.courses-details-desc-style-two .courses-curriculum ul li:nth-child(30) a,
.courses-details-desc-style-two .courses-curriculum ul li:nth-child(32) a,
.courses-details-desc-style-two .courses-curriculum ul li:nth-child(34) a,
.courses-details-desc-style-two .courses-curriculum ul li:nth-child(36) a,
.courses-details-desc-style-two .courses-curriculum ul li:nth-child(38) a,
.courses-details-desc-style-two .courses-curriculum ul li:nth-child(40) a,
.courses-details-desc-style-two .courses-curriculum ul li:nth-child(42) a,
.courses-details-desc-style-two .courses-curriculum ul li:nth-child(44) a,
.courses-details-desc-style-two .courses-curriculum ul li:nth-child(46) a,
.courses-details-desc-style-two .courses-curriculum ul li:nth-child(48) a,
.courses-details-desc-style-two .courses-curriculum ul li:nth-child(50) a,
.courses-details-desc-style-two .courses-curriculum ul li:nth-child(52) a,
.courses-details-desc-style-two .courses-curriculum ul li:nth-child(54) a,
.courses-details-desc-style-two .courses-curriculum ul li:nth-child(56) a,
.courses-details-desc-style-two .courses-curriculum ul li:nth-child(58) a,
.courses-details-desc-style-two .courses-curriculum ul li:nth-child(60) a,
.courses-details-desc-style-two .courses-curriculum ul li:nth-child(62) a,
.courses-details-desc-style-two .courses-curriculum ul li:nth-child(64) a,
.courses-details-desc-style-two .courses-curriculum ul li:nth-child(66) a,
.courses-details-desc-style-two .courses-curriculum ul li:nth-child(68) a,
.courses-details-desc-style-two .courses-curriculum ul li:nth-child(70) a,
.courses-details-desc-style-two .courses-curriculum ul li:nth-child(72) a,
.courses-details-desc-style-two .courses-curriculum ul li:nth-child(74) a,
.courses-details-desc-style-two .courses-curriculum ul li:nth-child(76) a,
.courses-details-desc-style-two .courses-curriculum ul li:nth-child(78) a,
.courses-details-desc-style-two .courses-curriculum ul li:nth-child(80) a,
.courses-details-desc-style-two .courses-curriculum ul li:nth-child(82) a,
.courses-details-desc-style-two .courses-curriculum ul li:nth-child(84) a,
.courses-details-desc-style-two .courses-curriculum ul li:nth-child(86) a,
.courses-details-desc-style-two .courses-curriculum ul li:nth-child(88) a,
.courses-details-desc-style-two .courses-curriculum ul li:nth-child(90) a,
.courses-details-desc-style-two .courses-curriculum ul li:nth-child(92) a,
.courses-details-desc-style-two .courses-curriculum ul li:nth-child(94) a,
.courses-details-desc-style-two .courses-curriculum ul li:nth-child(96) a,
.courses-details-desc-style-two .courses-curriculum ul li:nth-child(98) a,
.courses-details-desc-style-two .courses-curriculum ul li:nth-child(100) a {
  background-color: #ffffff;
}

.courses-details-desc-style-two .requirements-list {
  padding-left: 15px;
  margin-bottom: 0;
  list-style-type: none;
}

.courses-details-desc-style-two .requirements-list li {
  color: #606060;
  margin-bottom: 10px;
  position: relative;
  padding-left: 17px;
}

.courses-details-desc-style-two .requirements-list li::before {
  position: absolute;
  left: 0;
  content: "";
  top: 7px;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: #221638;
}

.courses-details-desc-style-two .requirements-list li:last-child {
  margin-bottom: 0;
}

.courses-details-desc-style-two p strong {
  color: #221638;
}

.courses-details-desc-style-two .description-features-list {
  padding-left: 15px;
  margin-bottom: 15px;
  list-style-type: none;
}

.courses-details-desc-style-two .description-features-list li {
  color: #606060;
  margin-bottom: 10px;
  position: relative;
  padding-left: 17px;
  line-height: 1.8;
}

.courses-details-desc-style-two .description-features-list li::before {
  position: absolute;
  left: 0;
  content: "";
  top: 10px;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: #221638;
}

.courses-details-desc-style-two .description-features-list li strong {
  color: #221638;
}

.courses-details-desc-style-two .description-features-list li:last-child {
  margin-bottom: 0;
}

.courses-details-desc-style-two .audience-list {
  padding-left: 15px;
  margin-bottom: 0;
  list-style-type: none;
}

.courses-details-desc-style-two .audience-list li {
  color: #606060;
  margin-bottom: 10px;
  position: relative;
  padding-left: 17px;
  line-height: 1.8;
}

.courses-details-desc-style-two .audience-list li::before {
  position: absolute;
  left: 0;
  content: "";
  top: 10px;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: #221638;
}

.courses-details-desc-style-two .audience-list li strong {
  color: #221638;
}

.courses-details-desc-style-two .audience-list li:last-child {
  margin-bottom: 0;
}

.courses-details-desc-style-two .courses-author {
  margin-top: 20px;
  border-radius: 5px;
  background: #f8f8f8;
}

.courses-details-desc-style-two .courses-author .author-profile-header {
  height: 115px;
  border-radius: 5px 5px 0 0;
  background-color: #5172ff;
  background-image: url(/images/bg.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.courses-details-desc-style-two .courses-author .author-profile {
  padding: 0 25px 25px;
}

.courses-details-desc-style-two .courses-author .author-profile .author-profile-title {
  position: relative;
  z-index: 1;
  margin-bottom: 15px;
  margin-top: -45px;
}

.courses-details-desc-style-two .courses-author .author-profile .author-profile-title img {
  display: inline-block;
  border: 3px solid #ffffff;
  width: 100px;
  height: 100px;
}

.courses-details-desc-style-two .courses-author .author-profile .author-profile-title .author-profile-title-details {
  margin-top: 15px;
}

.courses-details-desc-style-two .courses-author .author-profile .author-profile-title .author-profile-title-details .author-profile-details h4 {
  margin-bottom: 5px;
  font-size: 22px;
  font-weight: 800;
}

.courses-details-desc-style-two .courses-author .author-profile .author-profile-title .author-profile-title-details .author-profile-details span {
  color: #606060;
}

.courses-details-desc-style-two .courses-reviews h3 {
  margin-bottom: 0;
  display: inline-block;
  margin-right: 15px;
  font-size: 24px;
  font-weight: 800;
}

.courses-details-desc-style-two .courses-reviews .rating {
  display: inline-block;
  position: relative;
  top: 2px;
}

.courses-details-desc-style-two .courses-reviews .rating span {
  font-size: 17px;
  color: #cecfd2;
  margin-right: 2px;
}

.courses-details-desc-style-two .courses-reviews .rating span.checked {
  color: orange;
}

.courses-details-desc-style-two .courses-reviews .rating-count {
  margin-top: 10px;
  margin-bottom: 20px;
}

.courses-details-desc-style-two .courses-reviews .rating-count span {
  display: block;
  font-size: 16px;
  color: #606060;
}

.courses-details-desc-style-two .courses-reviews .row {
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}

.courses-details-desc-style-two .courses-reviews .side {
  float: left;
  width: 9%;
  margin-top: 10px;
}

.courses-details-desc-style-two .courses-reviews .side div {
  font-size: 16px;
  font-weight: 700;
}

.courses-details-desc-style-two .courses-reviews .middle {
  margin-top: 14px;
  float: left;
  width: 82%;
}

.courses-details-desc-style-two .courses-reviews .right {
  text-align: right;
}

.courses-details-desc-style-two .courses-reviews .bar-container {
  width: 100%;
  background-color: #f1f1f1;
  text-align: center;
  color: #ffffff;
  border-radius: 5px;
}

.courses-details-desc-style-two .courses-reviews .bar-5 {
  width: 100%;
  height: 18px;
  background-color: #4caf50;
  border-radius: 5px;
}

.courses-details-desc-style-two .courses-reviews .bar-4 {
  width: 75%;
  height: 18px;
  background-color: #2196f3;
  border-radius: 5px;
  border-radius: 5px;
}

.courses-details-desc-style-two .courses-reviews .bar-3 {
  width: 50%;
  height: 18px;
  background-color: #00bcd4;
  border-radius: 5px;
}

.courses-details-desc-style-two .courses-reviews .bar-2 {
  width: 25%;
  height: 18px;
  background-color: #ff9800;
  border-radius: 5px;
}

.courses-details-desc-style-two .courses-reviews .bar-1 {
  width: 0;
  height: 18px;
  background-color: #f44336;
  border-radius: 5px;
}

.courses-review-comments {
  margin-top: 40px;
}

.courses-review-comments h3 {
  border-bottom: 1px solid #f3f3f3;
  padding-bottom: 10px;
  margin-top: 0;
  margin-bottom: 10px;
}

.courses-review-comments .user-review {
  border-bottom: 1px solid #f3f3f3;
  padding: 20px 0 20px 110px;
  position: relative;
}

.courses-review-comments .user-review img {
  position: absolute;
  left: 0;
  top: 20px;
  width: 90px;
  height: 90px;
  border-radius: 5px;
}

.courses-review-comments .user-review .sub-comment {
  margin-bottom: 8px;
  font-weight: 700;
}

.courses-review-comments .user-review .review-rating {
  display: block;
  margin-bottom: 8px;
}

.courses-review-comments .user-review .review-rating .review-stars {
  display: inline-block;
}

.courses-review-comments .user-review .review-rating .review-stars i {
  color: #cecfd2;
  font-size: 18px;
  display: inline-block;
  margin-right: 2px;
}

.courses-review-comments .user-review .review-rating .review-stars i.checked {
  color: orange;
}

.courses-review-comments .user-review .review-rating span {
  color: #221638;
  position: relative;
  top: -2px;
  font-weight: 700;
  margin-left: 5px;
}

.related-courses {
  margin-top: 40px;
}

.related-courses h3 {
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: 800;
}

.courses-sidebar-information {
  background-color: #ffffff;
  -webkit-box-shadow: 0px 8px 16px 0px rgba(146, 184, 255, 0.2);
  box-shadow: 0px 8px 16px 0px rgba(146, 184, 255, 0.2);
  padding: 30px;
}

.courses-sidebar-information .info {
  margin-bottom: 0;
  list-style-type: none;
  padding-left: 0;
}

.courses-sidebar-information .info li {
  border-bottom: 1px dashed #eeeeee;
  color: #606060;
  font-size: 17px;
  font-weight: 600;
  padding-top: 15px;
  padding-bottom: 13px;
}

.courses-sidebar-information .info li span {
  font-weight: 800;
  color: #221638;
  position: relative;
  padding-left: 28px;
}

.courses-sidebar-information .info li span i {
  color: #5172ff;
  position: absolute;
  left: 0;
  font-weight: normal;
  top: -2px;
  font-size: 20px;
}

.courses-sidebar-information .info li.price {
  padding-bottom: 10px;
  color: #5172ff;
  font-size: 28px;
  font-weight: 800;
}

.courses-sidebar-information .info li.price span {
  color: #221638;
  font-size: 17px;
  font-weight: 800;
}

.courses-sidebar-information .info li:first-child {
  padding-top: 0;
}

.courses-sidebar-information .btn-box {
  margin-top: 35px;
}

.courses-sidebar-information .btn-box .default-btn {
  display: block;
  padding-right: 30px;
  padding-left: 30px;
  padding-top: 12px;
  padding-bottom: 12px;
}

.courses-sidebar-information .btn-box .default-btn i {
  position: relative;
  left: 0;
  top: 2px;
  margin-right: 8px;
  line-height: 1;
  font-weight: normal;
  font-size: 20px;
}

.courses-sidebar-information .btn-box .default-btn:not(:first-child) {
  margin-top: 15px;
  background-color: transparent;
  color: #221638;
  border: 1px solid #221638;
}

.courses-sidebar-information .btn-box .default-btn:not(:first-child):hover {
  color: #ffffff;
  border-color: #221638;
}

.courses-sidebar-information .courses-share {
  text-align: center;
  margin-top: 25px;
}

.courses-sidebar-information .courses-share .share-info {
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.courses-sidebar-information .courses-share .share-info span {
  display: inline-block;
  color: #5172ff;
  line-height: 1;
  font-size: 16px;
  font-weight: 700;
}

.courses-sidebar-information .courses-share .share-info span i {
  color: #221638;
}

.courses-sidebar-information .courses-share .share-info .social-link {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
  margin-top: 12px;
}

.courses-sidebar-information .courses-share .share-info .social-link li {
  display: inline-block;
  margin-right: 3px;
}

.courses-sidebar-information .courses-share .share-info .social-link li:last-child {
  margin-right: 0;
}

.courses-sidebar-information .courses-share .share-info .social-link li a {
  width: 30px;
  height: 30px;
  text-align: center;
  background-color: #e1e1e1;
  font-size: 18px;
  color: #221638;
  position: relative;
  border-radius: 2px;
}

.courses-sidebar-information .courses-share .share-info .social-link li a i {
  position: absolute;
  left: 0;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-48%);
  transform: translateY(-48%);
}

.courses-sidebar-information .courses-share .share-info .social-link li a:hover {
  background-color: #5172ff;
  color: #ffffff;
}

/*================================================
Feedback Area CSS
=================================================*/
.feedback-content .sub-title {
  display: block;
  text-transform: uppercase;
  color: #5172ff;
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 600;
}

.feedback-content h2 {
  margin-bottom: 0;
  font-size: 36px;
  font-weight: 800;
}

.feedback-content p {
  margin-top: 12px;
}

.feedback-content .feedback-info p {
  font-weight: 600;
  margin-bottom: 0;
}

.feedback-content .feedback-info p a {
  color: #5172ff;
  font-weight: 700;
}

.feedback-content .feedback-info p a:hover {
  text-decoration: underline;
  color: #221638;
}

.feedback-content .feedback-slides {
  margin-top: 30px;
  margin-bottom: 30px;
}

.single-feedback-item {
  background-color: #ffffff;
  position: relative;
  z-index: 1;
  padding: 50px 105px 50px 50px;
  border-radius: 5px;
  overflow: hidden;
}

.single-feedback-item p {
  color: #606060;
  line-height: 1.8;
  font-size: 16px;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 0;
}

.single-feedback-item .client-info {
  margin-top: 25px;
}

.single-feedback-item .client-info img {
  width: 55px !important;
  height: 55px;
}

.single-feedback-item .client-info .title {
  margin-left: 15px;
}

.single-feedback-item .client-info .title h3 {
  margin-bottom: 0;
  color: #5172ff;
  font-size: 17px;
  font-weight: 700;
}

.single-feedback-item .client-info .title span {
  display: block;
  color: #606060;
  font-size: 14px;
  margin-top: 4px;
}

.single-feedback-item::after {
  width: 75px;
  height: 75px;
  content: "";
  border-radius: 50%;
  background-color: #5172ff;
  position: absolute;
  left: -25px;
  top: -25px;
  z-index: -1;
}

.single-feedback-item::before {
  content: "\f10a";
  font-family: Flaticon;
  font-size: 100px;
  position: absolute;
  right: 50px;
  color: #e8e8e8;
  z-index: -1;
  bottom: 25px;
  line-height: 1;
}

.feedback-slides.owl-theme .owl-nav.disabled+.owl-dots {
  margin-top: 0;
  position: absolute;
  right: 20px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.feedback-slides.owl-theme .owl-dots .owl-dot {
  display: block;
}

.feedback-slides.owl-theme .owl-dots .owl-dot span {
  margin: 3px 0;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: transparent !important;
  border: 1px solid #a8a8a8;
  position: relative;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.feedback-slides.owl-theme .owl-dots .owl-dot span::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: transparent;
  border-radius: 50%;
  margin: 2px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.feedback-slides.owl-theme .owl-dots .owl-dot:hover span,
.feedback-slides.owl-theme .owl-dots .owl-dot.active span {
  border-color: #5172ff;
}

.feedback-slides.owl-theme .owl-dots .owl-dot:hover span::before,
.feedback-slides.owl-theme .owl-dots .owl-dot.active span::before {
  background-color: #5172ff;
}

.testimonials-slides {
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
}

.testimonials-slides.owl-theme .owl-dots {
  margin-top: 30px !important;
}

.testimonials-slides.owl-theme .owl-dots .owl-dot span {
  margin: 0 3px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: transparent !important;
  border: 1px solid #a8a8a8;
  position: relative;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.testimonials-slides.owl-theme .owl-dots .owl-dot span::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: transparent;
  border-radius: 50%;
  margin: 2px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.testimonials-slides.owl-theme .owl-dots .owl-dot:hover span,
.testimonials-slides.owl-theme .owl-dots .owl-dot.active span {
  border-color: #5172ff;
}

.testimonials-slides.owl-theme .owl-dots .owl-dot:hover span::before,
.testimonials-slides.owl-theme .owl-dots .owl-dot.active span::before {
  background-color: #5172ff;
}

.single-testimonials-item {
  margin-bottom: 30px;
  position: relative;
  z-index: 1;
  padding-left: 280px;
}

.single-testimonials-item .client-img {
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 150px !important;
  height: 150px;
  border-radius: 50%;
  display: inline-block !important;
  left: 35px;
}

.single-testimonials-item p {
  color: #221638;
  font-size: 20px;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 0;
}

.single-testimonials-item h3 {
  color: #5172ff;
  margin-top: 18px;
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 700;
}

.single-testimonials-item span {
  display: block;
  color: #606060;
  font-size: 15px;
  margin-top: 4px;
}

.single-testimonials-item::before {
  content: "\f10a";
  position: absolute;
  right: 0;
  color: #e8e8e8;
  z-index: -1;
  bottom: 0;
  line-height: 1;
  font-family: Flaticon;
  font-size: 100px;
}

.single-testimonials-item .shape-img img {
  width: auto !important;
  display: inline-block !important;
  position: absolute;
}

.single-testimonials-item .shape-img img.shape-1 {
  position: absolute;
  left: 13%;
  top: 11%;
  z-index: -1;
  -webkit-animation: movescale 4s linear infinite;
  animation: movescale 4s linear infinite;
}

.single-testimonials-item .shape-img img.shape-2 {
  left: 0;
  top: 8px;
  z-index: -3;
  -webkit-animation-name: rotateme;
  animation-name: rotateme;
  -webkit-animation-duration: 100s;
  animation-duration: 100s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

.single-testimonials-item .shape-img img.shape-3 {
  left: 20px;
  -webkit-animation: moveleftbounce 4s linear infinite;
  animation: moveleftbounce 4s linear infinite;
  bottom: -30px;
  z-index: -2;
}

.feedback-area {
  position: relative;
  z-index: 1;
}

.feedback-area .shape2 {
  bottom: 2%;
}

.feedback-area .shape9 {
  left: 8%;
  bottom: auto;
  top: 15%;
}

.feedback-slides-two.owl-theme .owl-dots {
  margin-top: 20px !important;
}

.feedback-slides-two.owl-theme .owl-dots .owl-dot span {
  margin: 0 3px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: transparent !important;
  border: 1px solid #a8a8a8;
  position: relative;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.feedback-slides-two.owl-theme .owl-dots .owl-dot span::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: transparent;
  border-radius: 50%;
  margin: 2px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.feedback-slides-two.owl-theme .owl-dots .owl-dot:hover span,
.feedback-slides-two.owl-theme .owl-dots .owl-dot.active span {
  border-color: #5172ff;
}

.feedback-slides-two.owl-theme .owl-dots .owl-dot:hover span::before,
.feedback-slides-two.owl-theme .owl-dots .owl-dot.active span::before {
  background-color: #5172ff;
}

.single-testimonials-box {
  max-width: 1110px;
  text-align: center;
  padding-right: 90px;
  position: relative;
  z-index: 1;
  margin-left: auto;
  margin-right: auto;
}

.single-testimonials-box .client-img {
  width: 150px !important;
  height: 150px;
  border-radius: 50%;
  margin-bottom: 50px;
}

.single-testimonials-box p {
  margin-bottom: 0;
  color: #221638;
  font-size: 24px;
  font-weight: 600;
}

.single-testimonials-box h3 {
  color: #5172ff;
  margin-top: 20px;
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 700;
}

.single-testimonials-box span {
  display: block;
  color: #606060;
  font-size: 15px;
  margin-top: 4px;
}

.single-testimonials-box::before {
  content: "\f10a";
  position: absolute;
  right: 0;
  color: #e7e2d9;
  z-index: -1;
  bottom: 120px;
  line-height: 1;
  font-family: Flaticon;
  font-size: 195px;
}

.single-testimonials-box .shape-img img {
  width: auto !important;
  display: inline-block !important;
  position: absolute;
}

.single-testimonials-box .shape-img img.shape-1 {
  position: absolute;
  left: 165px;
  top: 0;
  z-index: -1;
  -webkit-animation: movescale 4s linear infinite;
  animation: movescale 4s linear infinite;
  right: 0;
  margin: 0 auto;
}

.single-testimonials-box .shape-img img.shape-2 {
  left: -115px;
  top: -80px;
  z-index: -3;
  right: 0;
  margin: 0 auto;
  -webkit-animation-name: rotateme;
  animation-name: rotateme;
  -webkit-animation-duration: 100s;
  animation-duration: 100s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

.single-testimonials-box .shape-img img.shape-3 {
  left: 0;
  -webkit-animation: moveleftbounce 4s linear infinite;
  animation: moveleftbounce 4s linear infinite;
  top: 50px;
  z-index: -2;
  right: 35px;
  margin: 0 auto;
}

.feedback-with-bg-image {
  position: relative;
  z-index: 1;
  background-image: url(/images/feedback-bg.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.feedback-with-bg-image::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #000000;
  opacity: 0.5;
  z-index: -1;
}

.single-feedback-item-box {
  position: relative;
  z-index: 1;
  border-radius: 5px;
  padding: 30px;
  background-color: #5172ff;
}

.single-feedback-item-box::after {
  content: "\f10a";
  position: absolute;
  right: 90px;
  color: #ffffff;
  z-index: -1;
  bottom: 125px;
  line-height: 1;
  opacity: 0.35;
  font-family: Flaticon;
  font-size: 100px;
}

.single-feedback-item-box p {
  color: #ffffff;
}

.single-feedback-item-box .client-info {
  margin-top: 25px;
}

.single-feedback-item-box .client-info img {
  width: 55px !important;
  height: 55px;
  border-radius: 50%;
  display: inline-block !important;
}

.single-feedback-item-box .client-info .title {
  margin-left: 15px;
}

.single-feedback-item-box .client-info .title h3 {
  color: #ffffff;
  margin-bottom: 5px;
  font-size: 20px;
  font-weight: 800;
}

.single-feedback-item-box .client-info .title span {
  color: #ffffff;
  display: block;
}

.feedback-slides-style-two.feedback-slides.owl-theme {
  position: relative;
  z-index: 1;
  max-width: 600px;
  border-radius: 5px;
  margin-left: 100px;
}

.feedback-slides-style-two.feedback-slides.owl-theme::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 5px;
  z-index: -1;
  background-color: #5172ff;
  opacity: 0.85;
}

.feedback-slides-style-two.feedback-slides.owl-theme .single-feedback-item-box {
  background-color: transparent;
  border-radius: 0;
  padding: 85px 85px 150px;
}

.feedback-slides-style-two.feedback-slides.owl-theme .owl-nav.disabled+.owl-dots {
  right: auto;
  top: auto;
  -webkit-transform: unset;
  transform: unset;
  left: 82px;
  bottom: 80px;
}

.feedback-slides-style-two.feedback-slides.owl-theme .owl-dots .owl-dot {
  display: inline-block;
}

.feedback-slides-style-two.feedback-slides.owl-theme .owl-dots .owl-dot span {
  border-color: #ffffff;
  margin: 0 3px;
}

.feedback-slides-style-two.feedback-slides.owl-theme .owl-dots .owl-dot.active span::before,
.feedback-slides-style-two.feedback-slides.owl-theme .owl-dots .owl-dot:hover span::before {
  background-color: #ffffff;
}

.tree-shape {
  position: absolute;
  right: 0;
  bottom: -100px;
  z-index: -1;
}

.feedback-quote {
  border-radius: 5px;
  -webkit-box-shadow: 0px 8px 16px 0px rgba(146, 184, 255, 0.2);
  box-shadow: 0px 8px 16px 0px rgba(146, 184, 255, 0.2);
  background-color: #f9f9f2;
  position: relative;
  padding: 55px 45px;
  z-index: 1;
}

.feedback-quote p {
  font-size: 19px;
  font-weight: 600;
}

.feedback-quote .client-info {
  margin-top: 25px;
  text-align: left;
}

.feedback-quote .client-info img {
  width: 60px !important;
  height: 60px;
  border-radius: 50%;
}

.feedback-quote .client-info .title {
  margin-left: 15px;
  text-align: left;
}

.feedback-quote .client-info .title h3 {
  margin-bottom: 0;
  color: #5172ff;
  font-size: 17px;
  font-weight: 700;
}

.feedback-quote .client-info .title span {
  display: block;
  color: #606060;
  font-size: 14px;
  margin-top: 4px;
}

.object1 {
  position: absolute;
  left: -35px;
  top: -30px;
  z-index: -1;
}

.object1 img {
  -webkit-animation-name: rotateme;
  animation-name: rotateme;
  -webkit-animation-duration: 50s;
  animation-duration: 50s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

.object2 {
  position: absolute;
  right: 0;
  top: -20px;
  z-index: -1;
}

.object3 {
  position: absolute;
  left: -15px;
  bottom: -15px;
  z-index: -1;
}

.object4 {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.feedback-area.bg-6dbbbd .section-title .sub-title {
  color: #ffffff;
}

.feedback-area.bg-6dbbbd .section-title h2 {
  color: #ffffff;
}

.feedback-area.bg-6dbbbd .section-title p {
  color: #ffffff;
}

.feedback-slides-three.owl-theme .owl-nav {
  margin-top: 0;
}

.feedback-slides-three.owl-theme .owl-nav [class*="owl-"] {
  margin: -60px 0 0;
  position: absolute;
  left: 23%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 1;
  width: 55px;
  height: 55px;
  line-height: 54px;
  border: 1px dashed #5172ff;
  border-radius: 50%;
  background-color: transparent !important;
  color: #d1a1a1;
  font-size: 20px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  padding-left: 2px !important;
}

.feedback-slides-three.owl-theme .owl-nav [class*="owl-"]::before {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  background-color: #ffffff;
  content: "";
  border-radius: 50%;
  margin: 4px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.feedback-slides-three.owl-theme .owl-nav [class*="owl-"].owl-next {
  left: auto;
  right: 23%;
}

.feedback-slides-three.owl-theme .owl-nav [class*="owl-"]:hover {
  color: #ffffff;
}

.feedback-slides-three.owl-theme .owl-nav [class*="owl-"]:hover::before {
  background-color: #5172ff;
}

/*================================================
Profile Area CSS
=================================================*/
.profile-box {
  max-width: 1110px;
}

.profile-box .row {
  margin-left: 0;
  margin-right: 0;
}

.profile-box .row .col-lg-4,
.profile-box .row .col-lg-8 {
  padding-left: 0;
  padding-right: 0;
}

.profile-box .image {
  border-radius: 5px;
  padding-right: 15px;
}

.profile-box .image img {
  border-radius: 5px;
}

.profile-box .content {
  padding: 20px 0 20px 50px;
}

.profile-box .content h3 {
  margin-bottom: 7px;
  font-size: 23px;
  font-weight: 800;
}

.profile-box .content .sub-title {
  display: block;
  color: #5172ff;
  margin-bottom: 15px;
  font-size: 15px;
  font-weight: 600;
}

.profile-box .content .info {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}

.profile-box .content .info li {
  margin-bottom: 8px;
  font-weight: 600;
}

.profile-box .content .info li span {
  color: #606060;
  font-weight: 700;
}

.profile-box .content .info li:last-child {
  margin-bottom: 0;
}

.profile-box .content .social-link {
  padding-left: 0;
  list-style-type: none;
  margin-top: 20px;
  margin-bottom: 0;
}

.profile-box .content .social-link li {
  display: inline-block;
  margin-right: 8px;
}

.profile-box .content .social-link li:last-child {
  margin-right: 0;
}

.profile-box .content .social-link li a {
  width: 30px;
  height: 30px;
  text-align: center;
  background-color: #e1e1e1;
  font-size: 18px;
  color: #221638;
  position: relative;
  border-radius: 2px;
}

.profile-box .content .social-link li a i {
  position: absolute;
  left: 0;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-48%);
  transform: translateY(-48%);
}

.profile-box .content .social-link li a:hover {
  background-color: #5172ff;
  color: #ffffff;
}

.profile-courses-quizzes {
  margin-top: 80px;
}

.profile-courses-quizzes .nav {
  margin-bottom: 0;
  list-style-type: none;
  background-color: #f8f9f8;
  border-radius: 5px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: none;
  text-align: center;
  padding-left: 30px;
  padding-right: 30px;
}

.profile-courses-quizzes .nav .nav-item {
  margin-bottom: 0;
}

.profile-courses-quizzes .nav .nav-item .nav-link {
  position: relative;
  color: #606060;
  border: none;
  background-color: transparent;
  padding-top: 16px;
  padding-bottom: 14px;
  padding-left: 7px;
  padding-right: 7px;
  font-size: 17px;
  font-weight: 800;
  margin-left: 10px;
  margin-right: 10px;
}

.profile-courses-quizzes .nav .nav-item .nav-link::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background-color: #5172ff;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
}

.profile-courses-quizzes .nav .nav-item .nav-link:hover,
.profile-courses-quizzes .nav .nav-item .nav-link.active {
  color: #221638;
}

.profile-courses-quizzes .nav .nav-item .nav-link:hover::before,
.profile-courses-quizzes .nav .nav-item .nav-link.active::before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

.profile-courses-quizzes .tab-content {
  margin-top: 30px;
}

.profile-courses-quizzes .tab-content .nav {
  background-color: transparent;
  border-radius: 0;
  padding-left: 0;
  padding-right: 0;
}

.profile-courses-quizzes .tab-content .nav .nav-item .nav-link {
  color: #221638;
  background-color: #f8f9f8;
  border-radius: 30px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 35px;
  padding-right: 35px;
  font-size: 16px;
  font-weight: 600;
  margin-left: 5px;
  margin-right: 5px;
}

.profile-courses-quizzes .tab-content .nav .nav-item .nav-link::before {
  display: none;
}

.profile-courses-quizzes .tab-content .nav .nav-item .nav-link:hover,
.profile-courses-quizzes .tab-content .nav .nav-item .nav-link.active {
  color: #ffffff;
  background-color: #5172ff;
}

.profile-courses-quizzes .tab-content .tab-content .nav .nav-item .nav-link {
  color: #606060;
  background-color: transparent;
  border-radius: 0;
  padding-top: 0;
  padding-bottom: 3px;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 17px;
  font-weight: 600;
  margin-left: 10px;
  margin-right: 10px;
}

.profile-courses-quizzes .tab-content .tab-content .nav .nav-item .nav-link::before {
  display: block;
}

.profile-courses-quizzes .tab-content .tab-content .nav .nav-item .nav-link:hover,
.profile-courses-quizzes .tab-content .tab-content .nav .nav-item .nav-link.active {
  color: #221638;
}

.profile-courses-quizzes .tab-content .tab-content .nav .nav-item .nav-link:hover::before,
.profile-courses-quizzes .tab-content .tab-content .nav .nav-item .nav-link.active::before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

.profile-courses-quizzes .tab-content .table {
  margin-bottom: 30px;
}

.profile-courses-quizzes .tab-content .table thead {
  background-color: #221638;
  color: #ffffff;
}

.profile-courses-quizzes .tab-content .table thead tr th {
  white-space: nowrap;
  vertical-align: middle;
  border: none;
  font-size: 18px;
  font-weight: 800;
  padding-top: 14px;
  padding-bottom: 14px;
  padding-left: 25px;
  padding-right: 25px;
}

.profile-courses-quizzes .tab-content .table tbody tr td {
  vertical-align: middle;
  white-space: nowrap;
  color: #606060;
  border: none;
  font-size: 16px;
  font-weight: 600;
  padding-top: 15px;
  padding-bottom: 14px;
  padding-left: 25px;
  padding-right: 25px;
}

.profile-courses-quizzes .tab-content .table tbody tr td a {
  display: inline-block;
  color: #606060;
}

.profile-courses-quizzes .tab-content .table tbody tr td a:hover {
  color: #5172ff;
}

.profile-courses-quizzes .tab-content .table tbody tr td .progress {
  display: inline-block;
  border-radius: 30px;
  height: auto;
  padding: 4px 15px 3.5px;
  background-color: #221638;
  overflow: unset;
  margin-left: 5px;
  position: relative;
  top: -1px;
  color: #ffffff;
  font-size: 13px;
  font-weight: 700;
}

.profile-courses-quizzes .tab-content .table tbody tr td .progress.completed {
  background-color: #539a00;
}

.profile-courses-quizzes .tab-content .table tbody tr td .progress.pending {
  background-color: #ffc107;
}

.profile-courses-quizzes .tab-content .table tbody tr td .progress.passed {
  background-color: #007bff;
}

.profile-courses-quizzes .tab-content .table tbody tr td .progress.failed {
  background-color: #dc3545;
}

.profile-courses-quizzes .tab-content .table tbody tr:nth-of-type(odd) {
  background-color: #f8f9f8;
}

.profile-courses-quizzes .pagination-area {
  margin-bottom: 30px;
}

.profile-courses .title {
  background-color: #f8f9f8;
  color: #221638;
  text-align: center;
  margin-bottom: 30px;
  font-weight: 800;
  padding: 15px 0;
  font-size: 22px;
}

.profile-quizzes .title {
  background-color: #f8f9f8;
  color: #221638;
  text-align: center;
  margin-bottom: 30px;
  font-weight: 800;
  padding: 15px 0;
  font-size: 22px;
}

.profile-quizzes .table {
  margin-bottom: 30px;
}

.profile-quizzes .table thead {
  background-color: #221638;
  color: #ffffff;
}

.profile-quizzes .table thead tr th {
  white-space: nowrap;
  vertical-align: middle;
  border: none;
  font-size: 18px;
  font-weight: 800;
  padding-top: 14px;
  padding-bottom: 14px;
  padding-left: 25px;
  padding-right: 25px;
}

.profile-quizzes .table tbody tr td {
  vertical-align: middle;
  white-space: nowrap;
  color: #606060;
  border: none;
  font-size: 16px;
  font-weight: 600;
  padding-top: 15px;
  padding-bottom: 14px;
  padding-left: 25px;
  padding-right: 25px;
}

.profile-quizzes .table tbody tr td a {
  display: inline-block;
  color: #606060;
}

.profile-quizzes .table tbody tr td a:hover {
  color: #5172ff;
}

.profile-quizzes .table tbody tr td .progress {
  display: inline-block;
  border-radius: 30px;
  height: auto;
  padding: 4px 12px 3.5px;
  background-color: #221638;
  overflow: unset;
  margin-left: 5px;
  position: relative;
  top: -2px;
  color: #ffffff;
  font-size: 11px;
  font-weight: 700;
}

.profile-quizzes .table tbody tr td .progress.completed {
  background-color: #539a00;
}

.profile-quizzes .table tbody tr td .progress.pending {
  background-color: #ffc107;
}

.profile-quizzes .table tbody tr td .progress.passed {
  background-color: #007bff;
}

.profile-quizzes .table tbody tr td .progress.failed {
  background-color: #dc3545;
}

.profile-quizzes .table tbody tr:nth-of-type(odd) {
  background-color: #f8f9f8;
}

/*================================================
Events Area CSS
=================================================*/

/*================================================
Blog Area CSS
=================================================*/
.blog-area .row .col-lg-8 .row .col-lg-12 .pagination-area {
  margin-top: 25px;
}

.single-blog-post {
  margin-bottom: 30px;
  position: relative;
}

.single-blog-post .post-image {
  border-radius: 5px 5px 0 0;
}

.single-blog-post .post-image a {
  border-radius: 5px 5px 0 0;
  overflow: hidden;
}

.single-blog-post .post-image a img {
  border-radius: 5px 5px 0 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  width: 100%;
}

.single-blog-post .post-content {
  margin-top: 25px;
}

.single-blog-post .post-content .category {
  display: inline-block;
  margin-bottom: 10px;
  color: #606060;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
}

.single-blog-post .post-content .category:hover {
  color: #5172ff;
}

.single-blog-post .post-content h3 {
  margin-bottom: 12px;
  line-height: 1.3;
  font-size: 24px;
  font-weight: 800;
}

.single-blog-post .post-content h3 a {
  display: inline-block;
}

.single-blog-post .post-content .post-content-footer {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
  margin-left: -7px;
  margin-right: -7px;
  margin-top: 18px;
}

.single-blog-post .post-content .post-content-footer li {
  color: #606060;
  font-size: 15px;
  position: relative;
  padding-left: 29px;
  padding-right: 7px;
}

.single-blog-post .post-content .post-content-footer li .post-author img {
  width: 35px;
  height: 35px;
  margin-right: 10px;
}

.single-blog-post .post-content .post-content-footer li .post-author span {
  color: #5172ff;
  font-weight: 600;
  font-size: 15px;
}

.single-blog-post .post-content .post-content-footer li i {
  color: #5172ff;
  position: absolute;
  left: 7px;
  top: 0;
}

.single-blog-post .post-content .post-content-footer li:first-child {
  padding-left: 7px;
}

.single-blog-post:hover .post-image a img {
  -webkit-transform: rotate(5deg) scale(1.3);
  transform: rotate(5deg) scale(1.3);
}

.single-blog-post-item {
  margin-bottom: 30px;
  position: relative;
}

.single-blog-post-item .post-image {
  border-radius: 5px 5px 0 0;
}

.single-blog-post-item .post-image a {
  border-radius: 5px 5px 0 0;
  overflow: hidden;
}

.single-blog-post-item .post-image a img {
  border-radius: 5px 5px 0 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.single-blog-post-item .post-content {
  margin-top: 25px;
}

.single-blog-post-item .post-content .category {
  display: inline-block;
  margin-bottom: 7px;
  color: #606060;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
}

.single-blog-post-item .post-content .category:hover {
  color: #5172ff;
}

.single-blog-post-item .post-content h3 {
  margin-bottom: 0;
  line-height: 1.3;
  font-size: 24px;
  font-weight: 800;
}

.single-blog-post-item .post-content h3 a {
  display: inline-block;
}

.single-blog-post-item .post-content .post-content-footer {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
  margin-left: -7px;
  margin-right: -7px;
  margin-top: 20px;
}

.single-blog-post-item .post-content .post-content-footer li {
  color: #606060;
  font-size: 15px;
  position: relative;
  margin-left: 15px;
  padding-left: 29px;
  padding-right: 7px;
}

.single-blog-post-item .post-content .post-content-footer li .post-author img {
  width: 35px;
  height: 35px;
  margin-right: 10px;
}

.single-blog-post-item .post-content .post-content-footer li .post-author span {
  color: #5172ff;
  font-weight: 600;
  font-size: 15px;
}

.single-blog-post-item .post-content .post-content-footer li i {
  color: #5172ff;
  position: absolute;
  left: 7px;
  top: 0;
}

.single-blog-post-item .post-content .post-content-footer li:first-child {
  padding-left: 7px;
  margin-left: 0;
}

.single-blog-post-item:hover .post-image a img {
  -webkit-transform: rotate(5deg) scale(1.3);
  transform: rotate(5deg) scale(1.3);
}

.blog-post-info {
  border-top: 1px solid #f2f1f3;
  text-align: center;
  padding-top: 30px;
}

.blog-post-info p {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
}

.blog-post-info p a {
  color: #5172ff;
}

.blog-post-info p a:hover {
  text-decoration: underline;
  color: #221638;
}

.blog-post-list .single-blog-post-item .post-content {
  margin-top: 20px;
}

.single-blog-post-box {
  margin-bottom: 30px;
  position: relative;
}

.single-blog-post-box .post-image {
  border-radius: 5px 5px 0 0;

  /* padding-top:100%; */
}

.single-blog-post-box .post-image a {
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  background-color: #fffaf3;
  padding-top: -20px;
}

.single-blog-post-box .post-image a img {
  border-radius: 5px 5px 0 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.single-blog-post-box .post-content {
  background-color: #fffaf3;
  padding-bottom: 25px;
  padding-right: 25px;
  padding-left: 25px;
}

.single-blog-post-box .post-content .category {
  display: inline-block;
  margin-bottom: 7px;
  color: #606060;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
}

.single-blog-post-box .post-content .category:hover {
  color: #5172ff;
}

.single-blog-post-box .post-content h3 {
  margin-bottom: 12px;
  line-height: 1.3;
  font-size: 24px;
  font-weight: 800;
}

.single-blog-post-box .post-content h3 a {
  display: inline-block;
}

.single-blog-post-box .post-content .post-content-footer {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
  margin-left: -7px;
  margin-right: -7px;
  margin-top: 20px;
}

.single-blog-post-box .post-content .post-content-footer li {
  color: #606060;
  font-size: 15px;
  position: relative;
  padding-left: 29px;
  padding-right: 7px;
}

.single-blog-post-box .post-content .post-content-footer li .post-author img {
  width: 35px;
  height: 35px;
  margin-right: 10px;
}

.single-blog-post-box .post-content .post-content-footer li .post-author span {
  color: #5172ff;
  font-weight: 600;
  font-size: 15px;
}

.single-blog-post-box .post-content .post-content-footer li i {
  color: #5172ff;
  position: absolute;
  left: 7px;
  top: 0;
}

.single-blog-post-box .post-content .post-content-footer li:first-child {
  padding-left: 7px;
}

.single-blog-post-box:hover .post-image a img {
  -webkit-transform: rotate(5deg) scale(1.3);
  transform: rotate(5deg) scale(1.3);
}

.blog-slides.owl-theme .owl-dots {
  margin-top: 20px !important;
  margin-bottom: 35px;
}

.blog-slides.owl-theme .owl-dots .owl-dot span {
  margin: 0 3px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: transparent !important;
  border: 1px solid #a8a8a8;
  position: relative;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.blog-slides.owl-theme .owl-dots .owl-dot span::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: transparent;
  border-radius: 50%;
  margin: 2px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.blog-slides.owl-theme .owl-dots .owl-dot:hover span,
.blog-slides.owl-theme .owl-dots .owl-dot.active span {
  border-color: #5172ff;
}

.blog-slides.owl-theme .owl-dots .owl-dot:hover span::before,
.blog-slides.owl-theme .owl-dots .owl-dot.active span::before {
  background-color: #5172ff;
}

.blog-slides.owl-theme .owl-nav {
  margin-top: 0;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -3px;
}

.blog-slides.owl-theme .owl-nav [class*="owl-"] {
  margin: 0 40px;
  width: 35px;
  height: 35px;
  border-radius: 3px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  background-color: #ffffff;
  -webkit-box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
  box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
  line-height: 42px;
  padding: 0;
  font-size: 25px;
  color: #5172ff;
}

.blog-slides.owl-theme .owl-nav [class*="owl-"]:hover {
  background-color: #5172ff;
  color: #ffffff;
}

.blog-items {
  margin-left: -15px;
  margin-right: -15px;
}

.blog-area {
  position: relative;
  z-index: 1;
}

.blog-shape1 {
  position: absolute;
  left: 0;
  bottom: 100px;
  z-index: -1;
}

.blog-shape1 img {
  -webkit-animation: movebounce 5s linear infinite;
  animation: movebounce 5s linear infinite;
}

.blog-shape2 {
  position: absolute;
  right: 0;
  top: 35%;
  -webkit-transform: translateY(-35%);
  transform: translateY(-35%);
  z-index: -1;
}

.blog-shape2 img {
  -webkit-animation: movebounce 5s linear infinite;
  animation: movebounce 5s linear infinite;
}

.single-blog-item {
  margin-bottom: 30px;
  position: relative;
}

.single-blog-item .post-image {
  border-radius: 5px 5px 0 0;
  position: relative;
  z-index: 1;
}

.single-blog-item .post-image a {
  border-radius: 5px 5px 0 0;
  overflow: hidden;
}

.single-blog-item .post-image a img {
  border-radius: 5px 5px 0 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  width: 100%;
}

.single-blog-item .post-image::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-image: url(/images/shape-style1.png);
  background-position: bottom;
  background-repeat: no-repeat;
}

.single-blog-item .post-image .link-btn {
  border-radius: 0;
  overflow: unset;
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
}

.single-blog-item .post-content .category {
  display: inline-block;
  margin-bottom: 10px;
  color: #606060;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
}

.single-blog-item .post-content .category:hover {
  color: #5172ff;
}

.single-blog-item .post-content h3 {
  margin-bottom: 0;
  line-height: 1.3;
  font-size: 24px;
  font-weight: 800;
}

.single-blog-item .post-content h3 a {
  display: inline-block;
}

/*================================================
Blog Details Area CSS
=================================================*/


blockquote,
.blockquote {
  overflow: hidden;
  background-color: #fafafa;
  padding: 10px !important;
  position: relative;
  text-align: center;
  z-index: 1;
  margin-bottom: 5px;
  margin-top: 5px;
}

blockquote p,
.blockquote p {
  color: #221638;
  line-height: 1.6;
  margin-bottom: 0;
  font-style: italic;
  font-weight: 600;
  font-size: 17px !important;
}

blockquote cite,
.blockquote cite {
  display: none;
}

blockquote::before,
.blockquote::before {
  color: #efefef;
  position: absolute;
  -webkit-animation: fade-up 1.5s infinite linear;
  animation: fade-up 1.5s infinite linear;
  left: 50px;
  top: -50px;
  z-index: -1;
  content: "\f10a";
  font-family: Flaticon;
  font-size: 135px;
}

blockquote::after,
.blockquote::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 3px;
  background-color: #5172ff;
  margin-top: 20px;
  margin-bottom: 20px;
}

.edemy-post-navigation {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 30px;
  padding-top: 30px;
  padding-bottom: 30px;
  border-top: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
}

.prev-link-wrapper {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
  padding-right: 15px;
}

.prev-link-wrapper a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.prev-link-wrapper a:hover .image-prev::after {
  opacity: 1;
  visibility: visible;
}

.prev-link-wrapper a:hover .image-prev .post-nav-title {
  opacity: 1;
  visibility: visible;
}

.prev-link-wrapper a:hover .prev-link-info-wrapper {
  color: #5172ff;
}

.prev-link-wrapper .image-prev {
  display: inline-block;
  min-width: 100px;
  width: 100px;
  border-radius: 5px;
  overflow: hidden;
  vertical-align: top;
  margin-right: 20px;
  position: relative;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.prev-link-wrapper .image-prev img {
  border-radius: 5px;
}

.prev-link-wrapper .image-prev::after {
  display: block;
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 5px;
  opacity: 0;
  background-color: #5172ff;
  visibility: hidden;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.prev-link-wrapper .image-prev .post-nav-title {
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 0;
  margin: 0 auto;
  text-align: center;
  text-transform: uppercase;
  z-index: 2;
  color: #ffffff;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  font-size: 16px;
  font-weight: 700;
}

.prev-link-wrapper .prev-link-info-wrapper {
  color: #221638;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.prev-link-wrapper .prev-title {
  display: inline-block;
  font-weight: 800;
  font-size: 16.5px;
}

.prev-link-wrapper .meta-wrapper {
  display: block;
  color: #606060;
  text-transform: capitalize;
  margin-top: 5px;
  font-weight: 500;
  font-size: 14px;
}

.next-link-wrapper {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
  text-align: right;
  padding-left: 15px;
}

.next-link-wrapper a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.next-link-wrapper a:hover .image-next::after {
  opacity: 1;
  visibility: visible;
}

.next-link-wrapper a:hover .image-next .post-nav-title {
  opacity: 1;
  visibility: visible;
}

.next-link-wrapper a:hover .next-link-info-wrapper {
  color: #5172ff;
}

.next-link-wrapper .image-next {
  display: inline-block;
  min-width: 100px;
  width: 100px;
  border-radius: 5px;
  overflow: hidden;
  vertical-align: top;
  margin-left: 20px;
  position: relative;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.next-link-wrapper .image-next img {
  border-radius: 5px;
}

.next-link-wrapper .image-next::after {
  display: block;
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 5px;
  opacity: 0;
  background-color: #5172ff;
  visibility: hidden;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.next-link-wrapper .image-next .post-nav-title {
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 0;
  margin: 0 auto;
  text-align: center;
  text-transform: uppercase;
  z-index: 2;
  color: #ffffff;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  font-size: 16px;
  font-weight: 500;
}

.next-link-wrapper .next-link-info-wrapper {
  -webkit-transition: 0.5s;
  transition: 0.5s;
  color: #221638;
}

.next-link-wrapper .next-title {
  display: inline-block;
  font-weight: 800;
  font-size: 16.5px;
}

.next-link-wrapper .meta-wrapper {
  display: block;
  color: #606060;
  text-transform: capitalize;
  margin-top: 5px;
  font-weight: 500;
  font-size: 14px;
}

.comments-area {
  margin-top: 30px;
}

.comments-area .comments-title {
  line-height: initial;
  margin-bottom: 30px;
  font-size: 24px;
  font-weight: 800;
}

.comments-area ol,
.comments-area ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.comments-area .comment-list {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.comments-area .children {
  margin-left: 20px;
}

.comments-area .comment-body {
  border-bottom: 1px dashed #eeeeee;
  padding-left: 65px;
  color: #221638;
  font-size: 14px;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.comments-area .comment-body .reply {
  margin-top: 15px;
}

.comments-area .comment-body .reply a {
  border: 1px dashed #ded9d9;
  color: #221638;
  display: inline-block;
  padding: 6px 20px 3px;
  border-radius: 30px;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 700;
}

.comments-area .comment-body .reply a:hover {
  color: #ffffff;
  background-color: #5172ff;
  border-color: #5172ff;
}

.comments-area .comment-author {
  font-size: 16.5px;
  margin-bottom: 0.3em;
  position: relative;
  z-index: 2;
}

.comments-area .comment-author .avatar {
  height: 50px;
  left: -65px;
  position: absolute;
  width: 50px;
}

.comments-area .comment-author .fn {
  font-weight: 800;
}

.comments-area .comment-author .says {
  display: none;
}

.comments-area .comment-metadata {
  margin-bottom: 0.8em;
  color: #606060;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 400;
}

.comments-area .comment-metadata a {
  display: inline-block;
  color: #606060;
}

.comments-area .comment-metadata a:hover {
  color: #5172ff;
}

.comments-area .comment-respond {
  margin-top: 30px;
}

.comments-area .comment-respond .comment-reply-title {
  margin-bottom: 0;
  font-size: 24px;
  font-weight: 800;
}

.comments-area .comment-respond .comment-reply-title #cancel-comment-reply-link {
  display: inline-block;
}

.comments-area .comment-respond .comment-form {
  overflow: hidden;
}

.comments-area .comment-respond .comment-notes {
  margin-bottom: 20px;
  margin-top: 10px;
}

.comments-area .comment-respond .comment-notes .required {
  color: red;
}

.comments-area .comment-respond .comment-form-comment {
  float: left;
  width: 100%;
}

.comments-area .comment-respond label {
  display: none;
}

.comments-area .comment-respond input[type="date"],
.comments-area .comment-respond input[type="time"],
.comments-area .comment-respond input[type="datetime-local"],
.comments-area .comment-respond input[type="week"],
.comments-area .comment-respond input[type="month"],
.comments-area .comment-respond input[type="text"],
.comments-area .comment-respond input[type="email"],
.comments-area .comment-respond input[type="url"],
.comments-area .comment-respond input[type="password"],
.comments-area .comment-respond input[type="search"],
.comments-area .comment-respond input[type="tel"],
.comments-area .comment-respond input[type="number"],
.comments-area .comment-respond textarea {
  display: block;
  width: 100%;
  background-color: #f4f4f4;
  border: none;
  padding: 1px 0 0 15px;
  height: 50px;
  outline: 0;
  border-radius: 3px;
  color: #221638;
  font-weight: 600;
  font-size: 15px;
}

.comments-area .comment-respond input[type="date"]::-webkit-input-placeholder,
.comments-area .comment-respond input[type="time"]::-webkit-input-placeholder,
.comments-area .comment-respond input[type="datetime-local"]::-webkit-input-placeholder,
.comments-area .comment-respond input[type="week"]::-webkit-input-placeholder,
.comments-area .comment-respond input[type="month"]::-webkit-input-placeholder,
.comments-area .comment-respond input[type="text"]::-webkit-input-placeholder,
.comments-area .comment-respond input[type="email"]::-webkit-input-placeholder,
.comments-area .comment-respond input[type="url"]::-webkit-input-placeholder,
.comments-area .comment-respond input[type="password"]::-webkit-input-placeholder,
.comments-area .comment-respond input[type="search"]::-webkit-input-placeholder,
.comments-area .comment-respond input[type="tel"]::-webkit-input-placeholder,
.comments-area .comment-respond input[type="number"]::-webkit-input-placeholder,
.comments-area .comment-respond textarea::-webkit-input-placeholder {
  -webkit-transition: 0.5s;
  transition: 0.5s;
  color: #606060;
}

.comments-area .comment-respond input[type="date"]:-ms-input-placeholder,
.comments-area .comment-respond input[type="time"]:-ms-input-placeholder,
.comments-area .comment-respond input[type="datetime-local"]:-ms-input-placeholder,
.comments-area .comment-respond input[type="week"]:-ms-input-placeholder,
.comments-area .comment-respond input[type="month"]:-ms-input-placeholder,
.comments-area .comment-respond input[type="text"]:-ms-input-placeholder,
.comments-area .comment-respond input[type="email"]:-ms-input-placeholder,
.comments-area .comment-respond input[type="url"]:-ms-input-placeholder,
.comments-area .comment-respond input[type="password"]:-ms-input-placeholder,
.comments-area .comment-respond input[type="search"]:-ms-input-placeholder,
.comments-area .comment-respond input[type="tel"]:-ms-input-placeholder,
.comments-area .comment-respond input[type="number"]:-ms-input-placeholder,
.comments-area .comment-respond textarea:-ms-input-placeholder {
  -webkit-transition: 0.5s;
  transition: 0.5s;
  color: #606060;
}

.comments-area .comment-respond input[type="date"]::-ms-input-placeholder,
.comments-area .comment-respond input[type="time"]::-ms-input-placeholder,
.comments-area .comment-respond input[type="datetime-local"]::-ms-input-placeholder,
.comments-area .comment-respond input[type="week"]::-ms-input-placeholder,
.comments-area .comment-respond input[type="month"]::-ms-input-placeholder,
.comments-area .comment-respond input[type="text"]::-ms-input-placeholder,
.comments-area .comment-respond input[type="email"]::-ms-input-placeholder,
.comments-area .comment-respond input[type="url"]::-ms-input-placeholder,
.comments-area .comment-respond input[type="password"]::-ms-input-placeholder,
.comments-area .comment-respond input[type="search"]::-ms-input-placeholder,
.comments-area .comment-respond input[type="tel"]::-ms-input-placeholder,
.comments-area .comment-respond input[type="number"]::-ms-input-placeholder,
.comments-area .comment-respond textarea::-ms-input-placeholder {
  -webkit-transition: 0.5s;
  transition: 0.5s;
  color: #606060;
}

.comments-area .comment-respond input[type="date"]::placeholder,
.comments-area .comment-respond input[type="time"]::placeholder,
.comments-area .comment-respond input[type="datetime-local"]::placeholder,
.comments-area .comment-respond input[type="week"]::placeholder,
.comments-area .comment-respond input[type="month"]::placeholder,
.comments-area .comment-respond input[type="text"]::placeholder,
.comments-area .comment-respond input[type="email"]::placeholder,
.comments-area .comment-respond input[type="url"]::placeholder,
.comments-area .comment-respond input[type="password"]::placeholder,
.comments-area .comment-respond input[type="search"]::placeholder,
.comments-area .comment-respond input[type="tel"]::placeholder,
.comments-area .comment-respond input[type="number"]::placeholder,
.comments-area .comment-respond textarea::placeholder {
  -webkit-transition: 0.5s;
  transition: 0.5s;
  color: #606060;
}

.comments-area .comment-respond input[type="date"]:focus::-webkit-input-placeholder,
.comments-area .comment-respond input[type="time"]:focus::-webkit-input-placeholder,
.comments-area .comment-respond input[type="datetime-local"]:focus::-webkit-input-placeholder,
.comments-area .comment-respond input[type="week"]:focus::-webkit-input-placeholder,
.comments-area .comment-respond input[type="month"]:focus::-webkit-input-placeholder,
.comments-area .comment-respond input[type="text"]:focus::-webkit-input-placeholder,
.comments-area .comment-respond input[type="email"]:focus::-webkit-input-placeholder,
.comments-area .comment-respond input[type="url"]:focus::-webkit-input-placeholder,
.comments-area .comment-respond input[type="password"]:focus::-webkit-input-placeholder,
.comments-area .comment-respond input[type="search"]:focus::-webkit-input-placeholder,
.comments-area .comment-respond input[type="tel"]:focus::-webkit-input-placeholder,
.comments-area .comment-respond input[type="number"]:focus::-webkit-input-placeholder,
.comments-area .comment-respond textarea:focus::-webkit-input-placeholder {
  color: transparent;
}

.comments-area .comment-respond input[type="date"]:focus:-ms-input-placeholder,
.comments-area .comment-respond input[type="time"]:focus:-ms-input-placeholder,
.comments-area .comment-respond input[type="datetime-local"]:focus:-ms-input-placeholder,
.comments-area .comment-respond input[type="week"]:focus:-ms-input-placeholder,
.comments-area .comment-respond input[type="month"]:focus:-ms-input-placeholder,
.comments-area .comment-respond input[type="text"]:focus:-ms-input-placeholder,
.comments-area .comment-respond input[type="email"]:focus:-ms-input-placeholder,
.comments-area .comment-respond input[type="url"]:focus:-ms-input-placeholder,
.comments-area .comment-respond input[type="password"]:focus:-ms-input-placeholder,
.comments-area .comment-respond input[type="search"]:focus:-ms-input-placeholder,
.comments-area .comment-respond input[type="tel"]:focus:-ms-input-placeholder,
.comments-area .comment-respond input[type="number"]:focus:-ms-input-placeholder,
.comments-area .comment-respond textarea:focus:-ms-input-placeholder {
  color: transparent;
}

.comments-area .comment-respond input[type="date"]:focus::-ms-input-placeholder,
.comments-area .comment-respond input[type="time"]:focus::-ms-input-placeholder,
.comments-area .comment-respond input[type="datetime-local"]:focus::-ms-input-placeholder,
.comments-area .comment-respond input[type="week"]:focus::-ms-input-placeholder,
.comments-area .comment-respond input[type="month"]:focus::-ms-input-placeholder,
.comments-area .comment-respond input[type="text"]:focus::-ms-input-placeholder,
.comments-area .comment-respond input[type="email"]:focus::-ms-input-placeholder,
.comments-area .comment-respond input[type="url"]:focus::-ms-input-placeholder,
.comments-area .comment-respond input[type="password"]:focus::-ms-input-placeholder,
.comments-area .comment-respond input[type="search"]:focus::-ms-input-placeholder,
.comments-area .comment-respond input[type="tel"]:focus::-ms-input-placeholder,
.comments-area .comment-respond input[type="number"]:focus::-ms-input-placeholder,
.comments-area .comment-respond textarea:focus::-ms-input-placeholder {
  color: transparent;
}

.comments-area .comment-respond input[type="date"]:focus::placeholder,
.comments-area .comment-respond input[type="time"]:focus::placeholder,
.comments-area .comment-respond input[type="datetime-local"]:focus::placeholder,
.comments-area .comment-respond input[type="week"]:focus::placeholder,
.comments-area .comment-respond input[type="month"]:focus::placeholder,
.comments-area .comment-respond input[type="text"]:focus::placeholder,
.comments-area .comment-respond input[type="email"]:focus::placeholder,
.comments-area .comment-respond input[type="url"]:focus::placeholder,
.comments-area .comment-respond input[type="password"]:focus::placeholder,
.comments-area .comment-respond input[type="search"]:focus::placeholder,
.comments-area .comment-respond input[type="tel"]:focus::placeholder,
.comments-area .comment-respond input[type="number"]:focus::placeholder,
.comments-area .comment-respond textarea:focus::placeholder {
  color: transparent;
}

.comments-area .comment-respond textarea {
  height: auto !important;
  padding-top: 15px;
}

.comments-area .comment-respond .comment-form-author {
  float: left;
  width: 50%;
  padding-right: 10px;
  margin-bottom: 20px;
}

.comments-area .comment-respond .comment-form-email {
  float: left;
  width: 50%;
  padding-left: 12px;
  margin-bottom: 20px;
}

.comments-area .comment-respond .comment-form-url {
  float: left;
  width: 100%;
  margin-bottom: 20px;
}

.comments-area .comment-respond .comment-form-cookies-consent {
  width: 100%;
  float: left;
  position: relative;
  padding-left: 20px;
  margin-bottom: 20px;
  margin-top: 15px;
}

.comments-area .comment-respond .comment-form-cookies-consent input {
  position: absolute;
  left: 0;
  top: 4px;
}

.comments-area .comment-respond .comment-form-cookies-consent label {
  display: inline-block;
  margin: 0;
  color: #606060;
  position: relative;
  top: -1px;
  font-weight: normal;
  font-size: 16px;
}

.comments-area .comment-respond .form-submit {
  float: left;
  width: 100%;
}

.comments-area .comment-respond .form-submit input {
  background: #5172ff;
  border: none;
  color: #ffffff;
  padding: 12px 30px 10px;
  display: inline-block;
  cursor: pointer;
  text-transform: uppercase;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  border-radius: 0;
  font-weight: 800;
  font-size: 14.5px;
}

.comments-area .comment-respond .form-submit input:hover,
.comments-area .comment-respond .form-submit input:focus {
  color: #ffffff;
  background: #221638;
}

/*================================================
View All Courses Area CSS
=================================================*/
.view-all-courses-area {
  padding-top: 20px;
  position: relative;
  z-index: 1;
}

.view-all-courses-area .row {
  margin-left: 0;
  margin-right: 0;
}

.view-all-courses-area .row .col-lg-6 {
  padding-left: 0;
  padding-right: 0;
}

.view-all-courses-content {
  margin-left: auto;
  position: relative;
  z-index: 1;
  max-width: 600px;
}

.view-all-courses-content .sub-title {
  display: block;
  text-transform: uppercase;
  color: #5172ff;
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 600;
}

.view-all-courses-content h2 {
  margin-bottom: 0;
  font-size: 36px;
  font-weight: 800;
}

.view-all-courses-content p {
  margin-top: 12px;
}

.view-all-courses-content .default-btn {
  margin-top: 10px;
}

.view-all-courses-image {
  margin-left: -270px;
  text-align: center;
  position: relative;
}

.view-all-courses-image img {
  width: 100%;
}

.view-all-courses-area-two {
  position: relative;
  z-index: 1;
}

.view-all-courses-area-two .view-all-courses-content {
  margin-left: 0;
  max-width: 100%;
}

.view-all-courses-area-two .view-all-courses-image {
  margin-left: 0;
  position: relative;
  z-index: 1;
}

.view-all-courses-area-two .view-all-courses-image img {
  width: auto;
}

.shape9 {
  position: absolute;
  left: 10%;
  bottom: 20%;
  z-index: -1;
}

.shape9 img {
  -webkit-animation-name: rotateme;
  animation-name: rotateme;
  -webkit-animation-duration: 10s;
  animation-duration: 10s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

.shape11 {
  position: absolute;
  left: 15%;
  top: 18%;
  z-index: -1;
}

.shape11 img {
  -webkit-animation-name: rotateme;
  animation-name: rotateme;
  -webkit-animation-duration: 10s;
  animation-duration: 10s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

.shape12 {
  position: absolute;
  bottom: 18%;
  z-index: -1;
  right: 18%;
}

.shape12 img {
  -webkit-animation: movescale 4s linear infinite;
  animation: movescale 4s linear infinite;
}

/*================================================
Newsletter Modal CSS
=================================================*/
.newsletter-modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.9);
}

.newsletter-modal .newsletter-modal-content {
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 0;
  background-color: #fef8ef;
  max-width: 780px;
  border-radius: 5px;
  margin-left: auto;
  margin-right: auto;
}

.newsletter-modal .newsletter-modal-content .modal-image {
  border-radius: 5px 0 0 5px;
}

.newsletter-modal .newsletter-modal-content .modal-image img {
  border-radius: 5px 0 0 5px;
  width: 100%;
}

.newsletter-modal .newsletter-modal-content .modal-inner-content {
  padding: 38px 35px;
}

.newsletter-modal .newsletter-modal-content .modal-inner-content h2 {
  margin-bottom: 15px;
  font-size: 36px;
  font-weight: 800;
}

.newsletter-modal .newsletter-modal-content .modal-inner-content .sub-text {
  color: #221638;
  display: block;
  line-height: 1.5;
  font-weight: 600;
  font-size: 17px;
}

.newsletter-modal .newsletter-modal-content .modal-inner-content p {
  font-size: 14px;
}

.newsletter-modal .newsletter-modal-content .modal-inner-content form {
  position: relative;
  margin-top: 30px;
  margin-bottom: 22px;
}

.newsletter-modal .newsletter-modal-content .modal-inner-content form .input-newsletter {
  display: block;
  width: 100%;
  height: 50px;
  border: none;
  background-color: #e0d9d9;
  padding: 2px 0 0 15px;
  color: #221638;
  border-radius: 5px;
  font-size: 15px;
  font-weight: 600;
}

.newsletter-modal .newsletter-modal-content .modal-inner-content form .input-newsletter::-webkit-input-placeholder {
  color: #606060;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.newsletter-modal .newsletter-modal-content .modal-inner-content form .input-newsletter:-ms-input-placeholder {
  color: #606060;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.newsletter-modal .newsletter-modal-content .modal-inner-content form .input-newsletter::-ms-input-placeholder {
  color: #606060;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.newsletter-modal .newsletter-modal-content .modal-inner-content form .input-newsletter::placeholder {
  color: #606060;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.newsletter-modal .newsletter-modal-content .modal-inner-content form .input-newsletter:focus::-webkit-input-placeholder {
  color: transparent;
}

.newsletter-modal .newsletter-modal-content .modal-inner-content form .input-newsletter:focus:-ms-input-placeholder {
  color: transparent;
}

.newsletter-modal .newsletter-modal-content .modal-inner-content form .input-newsletter:focus::-ms-input-placeholder {
  color: transparent;
}

.newsletter-modal .newsletter-modal-content .modal-inner-content form .input-newsletter:focus::placeholder {
  color: transparent;
}

.newsletter-modal .newsletter-modal-content .modal-inner-content form button {
  border: none;
  text-align: center;
  color: #ffffff;
  background-color: #5172ff;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  border-radius: 5px;
  height: 50px;
  position: absolute;
  right: 0;
  top: 0;
  display: inline-block;
  font-weight: 700;
  font-size: 15px;
  padding-left: 22px;
  padding-right: 22px;
  padding-top: 12px;
  padding-bottom: 12px;
}

.newsletter-modal .newsletter-modal-content .modal-inner-content form button:hover {
  color: #ffffff;
  background-color: #221638;
}

.newsletter-modal .newsletter-modal-content .close-btn {
  position: absolute;
  right: 20px;
  cursor: pointer;
  color: #221638;
  top: 15px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.newsletter-modal .newsletter-modal-content .close-btn:hover {
  color: red;
}

/*================================================
Overview Area CSS
=================================================*/
.overview-area {
  position: relative;
  z-index: 1;
}

.overview-area .shape3 {
  right: auto;
  bottom: 35%;
  left: 3%;
}

.overview-area .shape4 {
  right: auto;
  bottom: 52%;
  left: 10%;
}

.overview-area .shape2 {
  left: auto;
  bottom: 39%;
  right: 5%;
}

.overview-area .shape9 {
  left: 40%;
  top: 60px;
}

.overview-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: -15px;
  margin-left: -15px;
  margin-top: 80px;
}

.overview-box .overview-content,
.overview-box .overview-image {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
  padding-left: 15px;
  padding-right: 15px;
}

.overview-box .overview-content .sub-title {
  display: block;
  text-transform: uppercase;
  color: #5172ff;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 600;
}

.overview-box .overview-content h2 {
  margin-bottom: 15px;
  font-size: 36px;
  font-weight: 800;
}

.overview-box .overview-content .default-btn {
  margin-top: 10px;
}

.overview-box .overview-content .btn-box {
  margin-top: 25px;
}

.overview-box .overview-content .btn-box .playstore-btn {
  display: inline-block;
  background-color: #221638;
  color: #ffffff;
  position: relative;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  padding-right: 20px;
  padding-top: 7px;
  padding-bottom: 5px;
  padding-left: 60px;
}

.overview-box .overview-content .btn-box .playstore-btn img {
  position: absolute;
  left: 20px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.overview-box .overview-content .btn-box .playstore-btn span {
  display: block;
  margin-top: -2px;
  font-size: 18px;
  font-weight: 700;
}

.overview-box .overview-content .btn-box .applestore-btn {
  display: inline-block;
  background-color: #221638;
  color: #ffffff;
  position: relative;
  margin-left: 15px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  padding-right: 20px;
  padding-top: 7px;
  padding-bottom: 5px;
  padding-left: 65px;
}

.overview-box .overview-content .btn-box .applestore-btn img {
  position: absolute;
  left: 20px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.overview-box .overview-content .btn-box .applestore-btn span {
  display: block;
  margin-top: -2px;
  font-size: 18px;
  font-weight: 700;
}

.overview-box .overview-image {
  text-align: center;
}

.overview-box:first-child {
  margin-top: 0;
}

/*================================================
Page Title Area CSS
=================================================*/

.page-title-area {
  background-color: #f8f9f8;
  position: relative;
  z-index: 1;
  padding-top: 20px;
  padding-bottom: 80px;
}

.page-title-content ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}

.page-title-content ul li {
  display: inline-block;
  margin-right: 20px;
  position: relative;
  color: #221638;
  font-size: 16px;
  font-weight: 600;
}

.page-title-content ul li::before {
  content: "";
  position: absolute;
  right: -10px;
  top: 5px;
  -webkit-transform: rotate(10deg);
  transform: rotate(10deg);
  background-color: #696969;
  height: 15px;
  width: 1px;
}

.page-title-content ul li a {
  color: #606060;
  /* display: block; */
  font-weight: 500;
}

.page-title-content ul li a:hover {
  color: #5172ff;
}

.page-title-content ul li:last-child {
  margin-right: 0;
}

.page-title-content ul li:last-child::before {
  display: none;
}

.page-title-content h1 {
  text-align: center;
  max-width: 800px;
  margin-top: 40px;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
  font-size: 36px;
  font-weight: 800;
}

.page-title-content .rating {
  text-align: center;
  font-size: 17px;
  margin-top: 20px;
}

.page-title-content .rating i {
  color: #dca416;
  display: inline-block;
  margin-right: 3px;
}

.page-title-content .rating .rating-count {
  display: inline-block;
  position: relative;
  top: -2px;
  font-size: 16px;
  font-weight: 600;
}

/*================================================
Subscribe Area CSS
=================================================*/
.subscribe-area {
  position: relative;
  z-index: 1;
}

.subscribe-area .shape4 {
  right: auto;
  bottom: 39%;
  left: 25%;
}

.subscribe-content {
  text-align: center;
  max-width: 720px;
  margin-left: auto;
  margin-right: auto;
}

.subscribe-content .sub-title {
  display: block;
  text-transform: uppercase;
  color: #5172ff;
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 600;
}

.subscribe-content h2 {
  max-width: 615px;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
  font-size: 36px;
  font-weight: 800;
}

.subscribe-content p {
  max-width: 615px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 12px;
}

.subscribe-content .newsletter-form {
  position: relative;
  max-width: 650px;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
}

.subscribe-content .newsletter-form .input-newsletter {
  display: block;
  width: 100%;
  border: none;
  background-color: #eaeaea;
  color: #221638;
  height: 50px;
  padding-left: 18px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 400;
}

.subscribe-content .newsletter-form .input-newsletter::-webkit-input-placeholder {
  color: #999999;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.subscribe-content .newsletter-form .input-newsletter:-ms-input-placeholder {
  color: #999999;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.subscribe-content .newsletter-form .input-newsletter::-ms-input-placeholder {
  color: #999999;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.subscribe-content .newsletter-form .input-newsletter::placeholder {
  color: #999999;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.subscribe-content .newsletter-form .input-newsletter:focus::-webkit-input-placeholder {
  color: transparent;
}

.subscribe-content .newsletter-form .input-newsletter:focus:-ms-input-placeholder {
  color: transparent;
}

.subscribe-content .newsletter-form .input-newsletter:focus::-ms-input-placeholder {
  color: transparent;
}

.subscribe-content .newsletter-form .input-newsletter:focus::placeholder {
  color: transparent;
}

.subscribe-content .newsletter-form .validation-danger {
  color: red;
  margin-top: 15px;
}

.subscribe-content .newsletter-form .validation-success {
  margin-top: 15px;
}

.subscribe-content .newsletter-form .default-btn {
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 0 5px 5px 0;
  height: 50px;
}

.subscribe-content .newsletter-form .default-btn i {
  top: 12px;
}

.subscribe-image {
  text-align: center;
}

.shape13 {
  position: absolute;
  left: 22%;
  bottom: 22%;
  z-index: -2;
}

.shape13 img {
  -webkit-animation-name: rotateme;
  animation-name: rotateme;
  -webkit-animation-duration: 50s;
  animation-duration: 50s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

.shape14 {
  position: absolute;
  right: 11%;
  bottom: 30%;
  z-index: -1;
}

.shape14 img {
  -webkit-animation: movebounce 5s linear infinite;
  animation: movebounce 5s linear infinite;
}

.shape15 {
  position: absolute;
  right: 15%;
  bottom: 15%;
  z-index: -2;
}

.shape15 img {
  -webkit-animation-name: rotateme;
  animation-name: rotateme;
  -webkit-animation-duration: 50s;
  animation-duration: 50s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

.subscribe-area-two {
  padding-top: 30px;
  background-color: #eee8df;
  background-image: url(/images/subscribe-bg.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.subscribe-area-two .subscribe-content {
  text-align: left;
  max-width: 490px;
  margin-left: 0;
  margin-right: 0;
}

.subscribe-area-two .subscribe-content .newsletter-form .input-newsletter {
  background-color: #ffffff;
}

.subscribe-area.bg-eee8df .subscribe-content .newsletter-form .input-newsletter {
  background-color: #ffffff;
}

.subscribe-content.text-left {
  max-width: 100%;
  margin-left: 0;
  margin-right: 0;
}

.subscribe-content.text-left h2 {
  max-width: 100%;
  margin-left: 0;
  margin-right: 0;
}

.subscribe-content.text-left p {
  max-width: 100%;
  margin-left: 0;
  margin-right: 0;
}

.subscribe-content.text-left .newsletter-form {
  max-width: 100%;
  margin-left: 0;
  margin-right: 0;
}

/*================================================
Gallery Area CSS
=================================================*/
.single-gallery-item {
  position: relative;
  margin-bottom: 30px;
  z-index: 1;
  border-radius: 5px;
  overflow: hidden;
  cursor: -webkit-zoom-in;
  cursor: zoom-in;
}

.single-gallery-item a {
  display: block;
  border-radius: 5px;
}

.single-gallery-item a img {
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.single-gallery-item:hover a img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-filter: blur(2px);
  filter: blur(2px);
}

/*================================================
Pricing Area CSS
=================================================*/
.single-pricing-box {
  margin-bottom: 30px;
  position: relative;
  z-index: 1;
  text-align: center;
  border-radius: 5px;
  background-color: #ffffff;
  padding-top: 30px;
  padding-bottom: 26px;
}

.single-pricing-box .pricing-header img {
  margin-bottom: 25px;
}

.single-pricing-box .pricing-header h3 {
  margin-bottom: 0;
  background-color: #f9f9f9;
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 800;
  padding-top: 14px;
  padding-bottom: 12px;
  padding-left: 10px;
  padding-right: 10px;
}

.single-pricing-box .pricing-features {
  margin-bottom: 20px;
  padding-top: 25px;
  padding-left: 10px;
  padding-right: 10px;
}

.single-pricing-box .pricing-features ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}

.single-pricing-box .pricing-features ul li {
  margin-bottom: 14px;
  color: #4b5280;
  font-size: 16.5px;
  font-weight: 600;
}

.single-pricing-box .pricing-features ul li:last-child {
  margin-bottom: 0;
}

.single-pricing-box .price {
  font-size: 42px;
  font-weight: 800;
}

.single-pricing-box .price span {
  display: block;
  color: #4b5280;
  margin-top: -5px;
  font-size: 18px;
  font-weight: 600;
}

.single-pricing-box .default-btn {
  margin-top: 25px;
  background-color: #6ba292;
  padding-left: 30px;
  padding-right: 30px;
}

.single-pricing-box .default-btn span {
  background-color: #5172ff;
}

.single-pricing-box .pricing-shape1 {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}

.single-pricing-box .pricing-shape2 {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -1;
}

/*================================================
Products Area CSS
=================================================*/
.single-products-box {
  position: relative;
  margin-bottom: 30px;
}

.single-products-box .products-image {
  text-align: center;
  position: relative;
  overflow: hidden;
}

.single-products-box .products-image a {
  position: relative;
  display: block;
}

.single-products-box .products-image a img {
  -webkit-transition: all 1.5s cubic-bezier(0, 0, 0.2, 1);
  transition: all 1.5s cubic-bezier(0, 0, 0.2, 1);
}

.single-products-box .products-image .products-button {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1;
}

.single-products-box .products-image .products-button ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}

.single-products-box .products-image .products-button ul li {
  margin-bottom: 10px;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateX(30px);
  transform: translateX(30px);
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.single-products-box .products-image .products-button ul li a {
  display: inline-block;
  font-size: 20px;
  line-height: 1;
  color: #221638;
  position: relative;
  -webkit-box-shadow: 0 0 4px rgba(0, 0, 0, 0.07);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.07);
  background-color: #ffffff;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 45px;
  border-radius: 50%;
}

.single-products-box .products-image .products-button ul li a .tooltip-label {
  right: 110%;
  top: 5px;
  -webkit-transform: translateX(0);
  transform: translateX(0);
  position: absolute;
  padding: 0 10px;
  visibility: hidden;
  white-space: nowrap;
  opacity: 0;
  color: transparent;
  background-color: transparent;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  background-color: #5172ff;
  color: #ffffff;
  line-height: 28px;
  font-size: 12px;
  font-weight: 800;
}

.single-products-box .products-image .products-button ul li a .tooltip-label::before {
  content: "";
  background-color: #5172ff;
  top: 50%;
  position: absolute;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  right: -1px;
  width: 10px;
  height: 10px;
  -webkit-transform: rotate(45deg) translateY(-50%);
  transform: rotate(45deg) translateY(-50%);
}

.single-products-box .products-image .products-button ul li a:hover {
  background-color: #5172ff;
  color: #ffffff;
}

.single-products-box .products-image .products-button ul li a:hover .tooltip-label {
  visibility: visible;
  opacity: 1;
  right: 135%;
}

.single-products-box .products-image .products-button ul li:last-child {
  margin-bottom: 0;
}

.single-products-box .products-image .products-button ul li:nth-child(1) {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

.single-products-box .products-image .products-button ul li:nth-child(2) {
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

.single-products-box .products-image .products-button ul li:nth-child(3) {
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
}

.single-products-box .products-image .sale-tag {
  position: absolute;
  left: 20px;
  top: 20px;
  background-color: red;
  color: #ffffff;
  width: 55px;
  height: 55px;
  line-height: 55px;
  text-align: center;
  border-radius: 50%;
  z-index: 2;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
}

.single-products-box .products-image .new-tag {
  position: absolute;
  left: 20px;
  top: 20px;
  background-color: green;
  color: #ffffff;
  text-align: center;
  z-index: 2;
  padding: 1px 7px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
}

.single-products-box .products-content {
  margin-top: 20px;
  position: relative;
}

.single-products-box .products-content h3 {
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 800;
}

.single-products-box .products-content h3 a {
  display: inline-block;
}

.single-products-box .products-content .price {
  margin-top: 13px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  font-size: 16px;
  font-weight: 800;
}

.single-products-box .products-content .price .old-price {
  text-decoration: line-through;
  color: #999999;
  font-weight: 700;
  margin-right: 10px;
}

.single-products-box .products-content .star-rating {
  position: absolute;
  right: 0;
  bottom: 0;
  color: #f49f0b;
}

.single-products-box .products-content .star-rating i {
  display: inline-block;
  margin-left: 2px;
}

.single-products-box .products-content .add-to-cart {
  position: absolute;
  left: 0;
  bottom: 0;
  text-transform: uppercase;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  display: inline-block;
  color: #606060;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateX(20px);
  transform: translateX(20px);
  font-size: 14px;
  font-weight: 800;
}

.single-products-box .products-content .add-to-cart:hover {
  color: #5172ff;
}

.single-products-box:hover .products-content .price {
  opacity: 0;
  visibility: hidden;
}

.single-products-box:hover .products-content .add-to-cart {
  -webkit-transform: translateX(0);
  transform: translateX(0);
  opacity: 1;
  visibility: visible;
}

.single-products-box:hover .products-image a img {
  -webkit-transform: scale3d(1.1, 1.1, 1.1) translateZ(0);
  transform: scale3d(1.1, 1.1, 1.1) translateZ(0);
}

.single-products-box:hover .products-image .products-button ul li {
  -webkit-transform: translateX(0);
  transform: translateX(0);
  opacity: 1;
  visibility: visible;
}

/*================================================
Products Details Area CSS
=================================================*/
.products-details-image {
  border-radius: 5px;
}

.products-details-image a {
  display: block;
  border-radius: 5px;
}

.products-details-image a img {
  border-radius: 5px;
}

.products-details-desc {
  padding-left: 30px;
}

.products-details-desc h3 {
  margin-bottom: 15px;
  font-size: 24px;
  font-weight: 800;
}

.products-details-desc .price {
  margin-bottom: 15px;
  color: #5172ff;
  font-size: 15px;
  font-weight: 600;
}

.products-details-desc .price .old-price {
  text-decoration: line-through;
  color: #828893;
  font-weight: normal;
  margin-left: 10px;
}

.products-details-desc .products-review {
  margin-bottom: 18px;
}

.products-details-desc .products-review .rating {
  display: inline-block;
  padding-right: 5px;
  font-size: 18px;
}

.products-details-desc .products-review .rating i {
  color: #ffba0a;
  display: inline-block;
  margin-right: 2px;
}

.products-details-desc .products-review .rating-count {
  display: inline-block;
  color: #221638;
  border-bottom: 1px solid #221638;
  line-height: initial;
  position: relative;
  top: -3px;
  font-weight: 600;
}

.products-details-desc .products-review .rating-count:hover {
  color: #5172ff;
  border-color: #5172ff;
}

.products-details-desc .products-meta {
  margin-top: 20px;
}

.products-details-desc .products-meta span {
  display: block;
  color: #221638;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 700;
}

.products-details-desc .products-meta span span {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 600;
}

.products-details-desc .products-meta span span.sku {
  color: #5172ff;
}

.products-details-desc .products-meta span span.in-stock {
  color: #606060;
}

.products-details-desc .products-meta span a {
  display: inline-block;
  color: #606060;
  font-weight: 600;
}

.products-details-desc .products-meta span a:hover,
.products-details-desc .products-meta span a:focus {
  color: #5172ff;
}

.products-details-desc .products-meta span:last-child {
  margin-bottom: 0;
}

.products-details-desc .products-add-to-cart {
  margin-top: 25px;
}

.products-details-desc .products-add-to-cart .input-counter {
  max-width: 130px;
  min-width: 130px;
  margin-right: 10px;
  text-align: center;
  display: inline-block;
  position: relative;
}

.products-details-desc .products-add-to-cart .input-counter span {
  position: absolute;
  top: 0;
  background-color: transparent;
  cursor: pointer;
  color: #d0d0d0;
  width: 50px;
  height: 100%;
  line-height: 55px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  font-size: 25px;
}

.products-details-desc .products-add-to-cart .input-counter span.minus-btn {
  left: 0;
}

.products-details-desc .products-add-to-cart .input-counter span.plus-btn {
  right: 0;
}

.products-details-desc .products-add-to-cart .input-counter span:hover {
  color: #5172ff;
}

.products-details-desc .products-add-to-cart .input-counter input {
  height: 50px;
  color: #221638;
  outline: 0;
  display: block;
  border: none;
  background-color: #f8f8f8;
  text-align: center;
  width: 100%;
  font-size: 17px;
  font-weight: 600;
}

.products-details-desc .products-add-to-cart .input-counter input::-webkit-input-placeholder {
  color: #221638;
}

.products-details-desc .products-add-to-cart .input-counter input:-ms-input-placeholder {
  color: #221638;
}

.products-details-desc .products-add-to-cart .input-counter input::-ms-input-placeholder {
  color: #221638;
}

.products-details-desc .products-add-to-cart .input-counter input::placeholder {
  color: #221638;
}

.products-details-desc .products-add-to-cart .default-btn {
  top: -2px;
  padding-top: 12.5px;
  padding-bottom: 12.5px;
}

.products-details-desc .products-share {
  margin-top: 30px;
}

.products-details-desc .products-share .social {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
}

.products-details-desc .products-share .social li {
  display: inline-block;
}

.products-details-desc .products-share .social li span {
  display: inline-block;
  margin-right: 3px;
  font-weight: 700;
  position: relative;
  top: -2px;
}

.products-details-desc .products-share .social li a {
  display: block;
  width: 32px;
  height: 32px;
  line-height: 34px;
  border-radius: 50%;
  background-color: #5172ff;
  color: #ffffff;
  border: 1px solid #5172ff;
  text-align: center;
  font-size: 17px;
  margin-left: 5px;
}

.products-details-desc .products-share .social li a:hover,
.products-details-desc .products-share .social li a:focus {
  color: #5172ff;
  background-color: transparent;
}

.products-details-desc .products-share .social li a.facebook {
  background-color: #3b5998;
  border-color: #3b5998;
  color: #ffffff;
}

.products-details-desc .products-share .social li a.facebook:hover,
.products-details-desc .products-share .social li a.facebook:focus {
  color: #3b5998;
  background-color: transparent;
}

.products-details-desc .products-share .social li a.twitter {
  background-color: #1da1f2;
  border-color: #1da1f2;
  color: #ffffff;
}

.products-details-desc .products-share .social li a.twitter:hover,
.products-details-desc .products-share .social li a.twitter:focus {
  color: #1da1f2;
  background-color: transparent;
}

.products-details-desc .products-share .social li a.linkedin {
  background-color: #007bb5;
  border-color: #007bb5;
  color: #ffffff;
}

.products-details-desc .products-share .social li a.linkedin:hover,
.products-details-desc .products-share .social li a.linkedin:focus {
  color: #007bb5;
  background-color: transparent;
}

.products-details-desc .products-share .social li a.instagram {
  background-color: #c13584;
  border-color: #c13584;
  color: #ffffff;
}

.products-details-desc .products-share .social li a.instagram:hover,
.products-details-desc .products-share .social li a.instagram:focus {
  color: #c13584;
  background-color: transparent;
}

.products-details-tabs {
  margin-top: 50px;
}

.products-details-tabs .react-tabs__tab-list {
  text-align: center;
  padding-left: 0;
  margin-bottom: 40px;
  list-style-type: none;
  display: block;
}

.products-details-tabs .react-tabs__tab-list .react-tabs__tab {
  display: inline-block;
  margin-left: 15px;
  margin-right: 15px;
  color: #737373;
  border: none;
  border-bottom: 1px solid #aaa;
  padding: 0;
  background-color: transparent;
  position: relative;
  padding-bottom: 8px;
  font-size: 20px;
  font-weight: 800;
}

.products-details-tabs .react-tabs__tab-list .react-tabs__tab::before {
  content: "";
  position: absolute;
  left: 0;
  width: 0;
  height: 3px;
  background-color: #5172ff;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  bottom: -2px;
}

.products-details-tabs .react-tabs__tab-list .react-tabs__tab:hover,
.products-details-tabs .react-tabs__tab-list .react-tabs__tab.react-tabs__tab--selected {
  color: #221638;
}

.products-details-tabs .react-tabs__tab-list .react-tabs__tab:hover::before,
.products-details-tabs .react-tabs__tab-list .react-tabs__tab.react-tabs__tab--selected::before {
  width: 100%;
}

.products-details-tabs .react-tabs__tab-panel {
  max-width: 720px;
  margin-left: auto;
  margin-right: auto;
}

.products-details-tabs .react-tabs__tab-panel p {
  line-height: 1.8;
}

.products-details-tabs .react-tabs__tab-panel ul {
  padding-left: 0;
  list-style-type: none;
  margin-top: 20px;
  margin-bottom: 0;
}

.products-details-tabs .react-tabs__tab-panel ul li {
  margin-bottom: 12px;
  position: relative;
  color: #606060;
  padding-left: 15px;
}

.products-details-tabs .react-tabs__tab-panel ul li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 8px;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: #221638;
}

.products-details-tabs .react-tabs__tab-panel ul li:last-child {
  margin-bottom: 0;
}

.products-details-tabs .react-tabs__tab-panel .products-reviews h3 {
  margin-bottom: 0;
  display: inline-block;
  margin-right: 15px;
  font-size: 24px;
  font-weight: 800;
}

.products-details-tabs .react-tabs__tab-panel .products-reviews .rating {
  display: inline-block;
}

.products-details-tabs .react-tabs__tab-panel .products-reviews .rating span {
  font-size: 17px;
  color: #cecfd2;
  margin-right: 2px;
}

.products-details-tabs .react-tabs__tab-panel .products-reviews .rating span.checked {
  color: orange;
}

.products-details-tabs .react-tabs__tab-panel .products-reviews .rating-count {
  margin-top: 10px;
  margin-bottom: 20px;
}

.products-details-tabs .react-tabs__tab-panel .products-reviews .rating-count span {
  display: block;
  font-size: 16px;
  color: #606060;
}

.products-details-tabs .react-tabs__tab-panel .products-reviews .row {
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}

.products-details-tabs .react-tabs__tab-panel .products-reviews .side {
  float: left;
  width: 9%;
  margin-top: 10px;
}

.products-details-tabs .react-tabs__tab-panel .products-reviews .side div {
  font-size: 16px;
  font-weight: 700;
}

.products-details-tabs .react-tabs__tab-panel .products-reviews .middle {
  margin-top: 14px;
  float: left;
  width: 82%;
}

.products-details-tabs .react-tabs__tab-panel .products-reviews .right {
  text-align: right;
}

.products-details-tabs .react-tabs__tab-panel .products-reviews .bar-container {
  width: 100%;
  background-color: #f1f1f1;
  text-align: center;
  color: #ffffff;
  border-radius: 5px;
}

.products-details-tabs .react-tabs__tab-panel .products-reviews .bar-5 {
  width: 100%;
  height: 18px;
  background-color: #4caf50;
  border-radius: 5px;
}

.products-details-tabs .react-tabs__tab-panel .products-reviews .bar-4 {
  width: 75%;
  height: 18px;
  background-color: #2196f3;
  border-radius: 5px;
  border-radius: 5px;
}

.products-details-tabs .react-tabs__tab-panel .products-reviews .bar-3 {
  width: 50%;
  height: 18px;
  background-color: #00bcd4;
  border-radius: 5px;
}

.products-details-tabs .react-tabs__tab-panel .products-reviews .bar-2 {
  width: 25%;
  height: 18px;
  background-color: #ff9800;
  border-radius: 5px;
}

.products-details-tabs .react-tabs__tab-panel .products-reviews .bar-1 {
  width: 0;
  height: 18px;
  background-color: #f44336;
  border-radius: 5px;
}

.products-details-tabs .react-tabs__tab-panel .products-review-comments {
  margin-top: 40px;
}

.products-details-tabs .react-tabs__tab-panel .products-review-comments h3 {
  border-bottom: 1px solid #f3f3f3;
  padding-bottom: 10px;
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: 800;
}

.products-details-tabs .react-tabs__tab-panel .products-review-comments .user-review {
  border-bottom: 1px solid #f3f3f3;
  padding: 20px 0 20px 110px;
  position: relative;
}

.products-details-tabs .react-tabs__tab-panel .products-review-comments .user-review img {
  position: absolute;
  left: 0;
  top: 20px;
  width: 90px;
  height: 90px;
  border-radius: 5px;
}

.products-details-tabs .react-tabs__tab-panel .products-review-comments .user-review .sub-comment {
  margin-bottom: 8px;
  font-weight: 700;
}

.products-details-tabs .react-tabs__tab-panel .products-review-comments .user-review .review-rating {
  display: block;
  margin-bottom: 8px;
}

.products-details-tabs .react-tabs__tab-panel .products-review-comments .user-review .review-rating .review-stars {
  display: inline-block;
}

.products-details-tabs .react-tabs__tab-panel .products-review-comments .user-review .review-rating .review-stars i {
  color: #cecfd2;
  font-size: 18px;
  display: inline-block;
  margin-right: 2px;
}

.products-details-tabs .react-tabs__tab-panel .products-review-comments .user-review .review-rating .review-stars i.checked {
  color: orange;
}

.products-details-tabs .react-tabs__tab-panel .products-review-comments .user-review .review-rating span {
  color: #221638;
  position: relative;
  top: -2px;
  font-weight: 700;
  margin-left: 5px;
}

.products-details-tabs .react-tabs__tab-panel .review-form-wrapper {
  margin-top: 30px;
}

.products-details-tabs .react-tabs__tab-panel .review-form-wrapper h3 {
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: 800;
}

.products-details-tabs .react-tabs__tab-panel .review-form-wrapper .comment-notes span {
  color: red;
}

.products-details-tabs .react-tabs__tab-panel .review-form-wrapper form {
  margin-top: 20px;
  text-align: center;
}

.products-details-tabs .react-tabs__tab-panel .review-form-wrapper form .form-group {
  margin-bottom: 25px;
  text-align: left;
}

.products-details-tabs .react-tabs__tab-panel .review-form-wrapper form .rating {
  text-align: left;
  overflow: hidden;
  max-width: 115px;
  margin-top: -5px;
  margin-bottom: 20px;
}

.products-details-tabs .react-tabs__tab-panel .review-form-wrapper form .rating label {
  float: right;
  position: relative;
  width: 23px;
  height: 23px;
  cursor: pointer;
}

.products-details-tabs .react-tabs__tab-panel .review-form-wrapper form .rating label:not(:first-of-type) {
  padding-right: 5px;
}

.products-details-tabs .react-tabs__tab-panel .review-form-wrapper form .rating label:before {
  content: "\2605";
  -webkit-transition: 0.5s;
  transition: 0.5s;
  font-size: 27px;
  color: #cccccc;
  line-height: 1;
}

.products-details-tabs .react-tabs__tab-panel .review-form-wrapper form .rating input {
  display: none;
}

.products-details-tabs .react-tabs__tab-panel .review-form-wrapper form .rating input:checked~label:before,
.products-details-tabs .react-tabs__tab-panel .review-form-wrapper form .rating:not(:checked)>label:hover:before,
.products-details-tabs .react-tabs__tab-panel .review-form-wrapper form .rating:not(:checked)>label:hover~label:before {
  color: #f6b500;
}

.products-details-tabs .react-tabs__tab-panel .review-form-wrapper form .comment-form-cookies-consent {
  text-align: left;
  margin-bottom: 0;
}

.products-details-tabs .react-tabs__tab-panel .review-form-wrapper form .comment-form-cookies-consent [type="checkbox"]:checked,
.products-details-tabs .react-tabs__tab-panel .review-form-wrapper form .comment-form-cookies-consent [type="checkbox"]:not(:checked) {
  display: none;
}

.products-details-tabs .react-tabs__tab-panel .review-form-wrapper form .comment-form-cookies-consent [type="checkbox"]:checked+label,
.products-details-tabs .react-tabs__tab-panel .review-form-wrapper form .comment-form-cookies-consent [type="checkbox"]:not(:checked)+label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  margin-bottom: 0;
  color: #606060;
  font-weight: 600;
}

.products-details-tabs .react-tabs__tab-panel .review-form-wrapper form .comment-form-cookies-consent [type="checkbox"]:checked+label:before,
.products-details-tabs .react-tabs__tab-panel .review-form-wrapper form .comment-form-cookies-consent [type="checkbox"]:not(:checked)+label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 19px;
  height: 19px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  border: 1px solid #f5f5f5;
  border-radius: 3px;
  background: #f5f5f5;
}

.products-details-tabs .react-tabs__tab-panel .review-form-wrapper form .comment-form-cookies-consent [type="checkbox"]:checked+label:after,
.products-details-tabs .react-tabs__tab-panel .review-form-wrapper form .comment-form-cookies-consent [type="checkbox"]:not(:checked)+label:after {
  content: "";
  width: 8px;
  height: 8px;
  background: #5172ff;
  position: absolute;
  top: 5.5px;
  left: 6px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.products-details-tabs .react-tabs__tab-panel .review-form-wrapper form .comment-form-cookies-consent [type="checkbox"]:not(:checked)+label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.products-details-tabs .react-tabs__tab-panel .review-form-wrapper form .comment-form-cookies-consent [type="checkbox"]:checked+label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.products-details-tabs .react-tabs__tab-panel .review-form-wrapper form .comment-form-cookies-consent [type="checkbox"]:hover+label:before {
  border-color: #5172ff;
}

.products-details-tabs .react-tabs__tab-panel .review-form-wrapper form .comment-form-cookies-consent [type="checkbox"]:checked+label:before {
  border-color: #5172ff;
}

.products-details-tabs .react-tabs__tab-panel .review-form-wrapper form button {
  margin-top: 22px;
  border: none;
  display: inline-block;
  text-align: center;
  overflow: hidden;
  color: #ffffff;
  background-color: #5172ff;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  border-radius: 5px;
  padding: 13px 60px;
  font-weight: 700;
  font-size: 16px;
}

.products-details-tabs .react-tabs__tab-panel .review-form-wrapper form button:hover {
  background-color: #221638;
  color: #ffffff;
}

/*================================================
Cart Area CSS
=================================================*/
.cart-table table {
  margin-bottom: 0;
}

.cart-table table thead tr th {
  border-bottom-width: 0px;
  vertical-align: middle;
  padding: 15px 0;
  text-transform: uppercase;
  border: none;
  white-space: nowrap;
  font-size: 16px;
  font-weight: 800;
}

.cart-table table tbody tr td {
  vertical-align: middle;
  color: #606060;
  white-space: nowrap;
  font-weight: 700;
  font-size: 15px;
  padding-left: 0;
  padding-right: 0;
  border-color: #eaedff;
  border-left: none;
  border-right: none;
}

.cart-table table tbody tr td.product-thumbnail a {
  display: block;
}

.cart-table table tbody tr td.product-thumbnail a img {
  width: 80px;
}

.cart-table table tbody tr td.product-name a {
  display: inline-block;
}

.cart-table table tbody tr td.product-subtotal .remove {
  color: red;
  float: right;
  position: relative;
  top: -1px;
  font-size: 18px;
}

.cart-table table tbody tr td.product-quantity .input-counter {
  max-width: 130px;
  min-width: 130px;
  text-align: center;
  display: inline-block;
  position: relative;
}

.cart-table table tbody tr td.product-quantity .input-counter span {
  position: absolute;
  top: 0;
  background-color: transparent;
  cursor: pointer;
  color: #d0d0d0;
  width: 40px;
  height: 100%;
  line-height: 50px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  font-size: 22px;
}

.cart-table table tbody tr td.product-quantity .input-counter span.minus-btn {
  left: 0;
}

.cart-table table tbody tr td.product-quantity .input-counter span.plus-btn {
  right: 0;
}

.cart-table table tbody tr td.product-quantity .input-counter span:hover {
  color: #5172ff;
}

.cart-table table tbody tr td.product-quantity .input-counter input {
  height: 45px;
  color: #221638;
  outline: 0;
  display: block;
  border: none;
  background-color: #f8f8f8;
  text-align: center;
  width: 100%;
  font-size: 17px;
  font-weight: 600;
}

.cart-table table tbody tr td.product-quantity .input-counter input::-webkit-input-placeholder {
  color: #221638;
}

.cart-table table tbody tr td.product-quantity .input-counter input:-ms-input-placeholder {
  color: #221638;
}

.cart-table table tbody tr td.product-quantity .input-counter input::-ms-input-placeholder {
  color: #221638;
}

.cart-table table tbody tr td.product-quantity .input-counter input::placeholder {
  color: #221638;
}

.cart-table table tbody tr td.product-subtotal {
  overflow: hidden;
}

.cart-buttons {
  margin-top: 30px;
}

.cart-buttons .shopping-coupon-code {
  position: relative;
  max-width: 530px;
}

.cart-buttons .shopping-coupon-code button {
  position: absolute;
  right: 0;
  top: 0;
  height: 50px;
  background: #221638;
  color: #ffffff;
  border: none;
  padding: 0 25px;
  line-height: 48px;
  outline: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  font-size: 16px;
  font-weight: 700;
}

.cart-buttons .shopping-coupon-code button:hover {
  background-color: #5172ff;
}

.cart-totals {
  background: #ffffff;
  padding: 40px;
  max-width: 500px;
  -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  margin-top: 60px;
  margin-left: auto;
  margin-right: auto;
}

.cart-totals h3 {
  margin-bottom: 25px;
  font-size: 24px;
  font-weight: 800;
}

.cart-totals ul {
  padding-left: 0;
  margin: 0 0 25px;
  list-style-type: none;
}

.cart-totals ul li {
  border: 1px solid #eaedff;
  padding: 10px 15px;
  color: #221638;
  overflow: hidden;
  font-weight: 700;
}

.cart-totals ul li:first-child {
  border-bottom: none;
}

.cart-totals ul li:last-child {
  font-size: 20px;
  border-top: none;
}

.cart-totals ul li:last-child span {
  color: #221638;
  font-weight: 600;
}

.cart-totals ul li span {
  float: right;
  color: #606060;
  font-weight: normal;
}

/*================================================
Checkout Area CSS
=================================================*/
.user-actions {
  -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  background: #ffffff;
  padding: 15px 20px;
  border-top: 3px solid #5172ff;
  position: relative;
  margin-bottom: 40px;
}

.user-actions i {
  color: #5172ff;
  margin-right: 2px;
  font-size: 20px;
  position: relative;
  top: 3px;
}

.user-actions span {
  display: inline-block;
  font-weight: 700;
  color: #5172ff;
}

.user-actions span a {
  display: inline-block;
}

.billing-details .title {
  margin-bottom: 30px;
  position: relative;
  padding-bottom: 10px;
  border-bottom: 1px solid #eeeeee;
  font-size: 24px;
  font-weight: 800;
}

.billing-details .title::before {
  content: "";
  position: absolute;
  background: #5172ff;
  bottom: -1px;
  left: 0;
  width: 50px;
  height: 1px;
}

.billing-details .form-group {
  margin-bottom: 25px;
}

.billing-details .form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: 700;
  font-size: 15px;
}

.billing-details .form-group label .required {
  color: red;
}

.billing-details .form-group .nice-select {
  background: #f5f5f5;
  border-color: #f5f5f5;
  color: #221638;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  padding: 0 0 0 12px;
  height: 50px;
  line-height: 51px;
  font-size: 15px;
  font-weight: 700;
}

.billing-details .form-group .nice-select .list {
  background-color: #ffffff;
  border-radius: 5px;
  -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  list-style-type: none;
  border: none;
  width: 100%;
  margin-bottom: 0;
  margin-top: 0;
  padding-left: 0;
  padding-top: 10px;
  padding-bottom: 10px;
}

.billing-details .form-group .nice-select .list .option {
  line-height: 38px;
  min-height: 38px;
  color: #221638;
  position: relative;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  padding-left: 32px;
  padding-right: 25px;
  font-size: 16px;
  font-weight: 700;
}

.billing-details .form-group .nice-select .list .option:hover {
  background-color: #5172ff !important;
  color: #ffffff;
}

.billing-details .form-group .nice-select .list .option:hover::before {
  color: #ffffff;
}

.billing-details .form-group .nice-select .list .option.focus,
.billing-details .form-group .nice-select .list .option.selected.focus {
  background-color: transparent !important;
  color: #221638;
}

.billing-details .form-group .nice-select .list .option::before {
  content: "\f11f";
  position: absolute;
  left: 10px;
  top: 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  color: #5172ff;
  font-family: Flaticon;
  font-size: 11px;
}

.billing-details .form-group .nice-select .list .option.selected::before {
  opacity: 1;
  visibility: visible;
}

.billing-details .form-group .nice-select .list .option.selected:hover {
  background-color: #5172ff !important;
  color: #ffffff;
}

.billing-details .form-group .nice-select .list .option.selected:hover::before {
  color: #ffffff;
}

.billing-details .form-group .nice-select:after {
  border-color: #221638;
  height: 8px;
  width: 8px;
  margin-top: -5px;
}

.billing-details .form-group .nice-select:hover {
  border-color: #5172ff;
  background-color: transparent;
}

.billing-details .form-check {
  margin-bottom: 20px;
  padding-left: 25px;
}

.billing-details .form-check .form-check-input {
  margin-left: -25px;
}

.billing-details .form-check .form-check-label {
  color: #221638;
  font-weight: 600;
}

.billing-details .form-check label {
  position: relative;
  left: -3px;
  top: 0;
  font-weight: 500;
}

.billing-details .col-lg-12:last-child .form-group {
  margin-bottom: 0;
}

.order-details .title {
  margin-bottom: 30px;
  position: relative;
  padding-bottom: 10px;
  border-bottom: 1px solid #eeeeee;
  font-size: 24px;
  font-weight: 800;
}

.order-details .title::before {
  content: "";
  position: absolute;
  background: #5172ff;
  bottom: -1px;
  left: 0;
  width: 50px;
  height: 1px;
}

.order-details .order-table table {
  margin-bottom: 0;
  background-color: #fff;
}

.order-details .order-table table thead tr th {
  border-bottom-width: 0;
  vertical-align: middle;
  border-color: #eaedff;
  padding: 12px 20px 10px;
  white-space: nowrap;
  font-weight: 800;
  font-size: 16px;
}

.order-details .order-table table tbody tr td {
  vertical-align: middle;
  color: #606060;
  white-space: nowrap;
  border-color: #eaedff;
  font-size: 15px;
  font-weight: 700;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 15px;
  padding-bottom: 13px;
}

.order-details .order-table table tbody tr td.product-name a {
  display: inline-block;
}

.order-details .order-table table tbody tr td.order-subtotal span,
.order-details .order-table table tbody tr td.order-shipping span,
.order-details .order-table table tbody tr td.total-price span {
  color: #221638;
  font-weight: 800;
  font-size: 16px;
}

.order-details .order-table table tbody tr td.shipping-price,
.order-details .order-table table tbody tr td.order-subtotal-price,
.order-details .order-table table tbody tr td.product-subtotal {
  color: #221638;
  font-weight: 800;
  font-size: 16px;
}

.order-details .payment-box {
  background-color: #ffffff;
  -webkit-box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
  box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
  margin-top: 30px;
  padding: 30px;
}

.order-details .payment-box .payment-method p [type="radio"]:checked,
.order-details .payment-box .payment-method p [type="radio"]:not(:checked) {
  display: none;
}

.order-details .payment-box .payment-method p [type="radio"]:checked+label,
.order-details .payment-box .payment-method p [type="radio"]:not(:checked)+label {
  padding-left: 27px;
  cursor: pointer;
  display: block;
  color: #221638;
  position: relative;
  margin-bottom: 8px;
  font-weight: 800;
}

.order-details .payment-box .payment-method p [type="radio"]:checked+label::before,
.order-details .payment-box .payment-method p [type="radio"]:not(:checked)+label::before {
  content: "";
  position: absolute;
  left: 0;
  top: 3px;
  width: 18px;
  height: 18px;
  border: 1px solid #dddddd;
  border-radius: 50%;
  background: #ffffff;
}

.order-details .payment-box .payment-method p [type="radio"]:checked+label::after,
.order-details .payment-box .payment-method p [type="radio"]:not(:checked)+label::after {
  content: "";
  width: 12px;
  height: 12px;
  background: #5172ff;
  position: absolute;
  top: 6px;
  left: 3px;
  border-radius: 50%;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.order-details .payment-box .payment-method p [type="radio"]:not(:checked)+label::after {
  opacity: 0;
  visibility: hidden;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.order-details .payment-box .payment-method p [type="radio"]:checked+label::after {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.order-details .payment-box .default-btn {
  margin-top: 15px;
  -webkit-box-shadow: 0px 5px 28.5px 1.5px rgba(149, 152, 200, 0.2);
  box-shadow: 0px 5px 28.5px 1.5px rgba(149, 152, 200, 0.2);
  border-radius: 3px;
}

/*================================================
Purchase Guide Area CSS
=================================================*/
.purchase-guide-content img {
  border-radius: 5px;
  display: block;
  width: 100%;
  margin-bottom: 30px;
}

.purchase-guide-content h3 {
  margin-bottom: 15px;
  margin-top: 25px;
  font-size: 22px;
  font-weight: 800;
}

.purchase-guide-content .blockquote,
.purchase-guide-content blockquote {
  margin-bottom: 30px;
  background-color: #e6f2f5;
  text-align: left !important;
  padding: 25px !important;
}

.purchase-guide-content .blockquote p,
.purchase-guide-content blockquote p {
  margin-bottom: 0;
  line-height: 1.8;
  font-size: 16px !important;
  font-weight: normal;
}

.purchase-guide-content .blockquote::after,
.purchase-guide-content blockquote::after {
  display: none;
}

.purchase-guide-content .blockquote::before,
.purchase-guide-content blockquote::before {
  display: none;
}

.purchase-guide-content ol,
.purchase-guide-content ul {
  margin-top: 20px;
}

.purchase-guide-content ol li,
.purchase-guide-content ul li {
  margin-bottom: 10px;
  color: #606060;
  font-size: 16px;
  line-height: 1.8;
}

/*================================================
Membership Levels Area CSS
=================================================*/
.membership-levels-table .table {
  margin-bottom: 0;
}

.membership-levels-table .table thead th {
  vertical-align: middle;
  background-color: #5172ff;
  white-space: nowrap;
  padding: 20px;
  text-align: center;
  color: rgba(255, 255, 255, 0.9);
  border-left: 1px solid #e98f8f;
  border-bottom: none;
  border-right: 1px solid #e98f8f;
}

.membership-levels-table .table thead th h2 {
  color: #ffffff;
  margin-bottom: 0;
  font-size: 35px;
  font-weight: 800;
}

.membership-levels-table .table thead th h3 {
  color: #ffffff;
  text-transform: uppercase;
  margin-top: 8px;
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 700;
}

.membership-levels-table .table thead th:first-child {
  border-left: none;
}

.membership-levels-table .table thead th:last-child {
  border-right: none;
}

.membership-levels-table .table tbody tr td {
  padding: 15px 20px;
  white-space: nowrap;
  vertical-align: middle;
  border: none;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
}

.membership-levels-table .table tbody tr td:first-child {
  text-align: left;
}

.membership-levels-table .table tbody tr td.item-check i {
  width: 20px;
  height: 20px;
  line-height: 20px;
  border-radius: 100%;
  background-color: #0eb582;
  color: #ffffff;
  display: block;
  margin: 0 auto;
}

.membership-levels-table .table tbody tr td.item-none i {
  width: 20px;
  height: 20px;
  line-height: 20px;
  border-radius: 100%;
  background-color: #d85554;
  color: #ffffff;
  display: block;
  margin: 0 auto;
}

.membership-levels-table .table tbody tr td .select-btn {
  display: inline-block;
  color: #221638;
  background-color: #efefef;
  padding: 13px 30px;
  border-radius: 5px;
  margin-top: 10px;
  font-size: 16px;
  font-weight: 700;
}

.membership-levels-table .table tbody tr td .select-btn:hover {
  color: #ffffff;
  background-color: #5172ff;
}

/*================================================
Pagination CSS
=================================================*/
.pagination-area {
  margin-top: 35px;
}

.pagination-area .page-numbers {
  width: 33px;
  height: 33px;
  background-color: #f3f0f0;
  color: #606060;
  text-align: center;
  display: inline-block;
  border-radius: 3px;
  line-height: 34px;
  position: relative;
  margin-left: 3px;
  margin-right: 3px;
  font-size: 16px;
  font-weight: 800;
}

.pagination-area .page-numbers:hover,
.pagination-area .page-numbers.current {
  color: #ffffff;
  background-color: #5172ff;
}

.pagination-area .page-numbers i {
  position: relative;
  top: 1.5px;
}

/*================================================
404 Error Area CSS
=================================================*/
.error-area {
  background-color: #111111;
  height: 100vh;
}

.error-content {
  text-align: center;
}

.error-content h3 {
  color: #ffffff;
  margin-bottom: 20px;
  margin-top: 45px;
  font-size: 40px;
  font-weight: 800;
}

.error-content p {
  color: rgba(255, 255, 255, 0.7);
  max-width: 550px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
}

.error-content .btn-box {
  margin-top: 30px;
}

.error-content .btn-box .default-btn {
  margin-left: 7px;
  margin-right: 7px;
}

.error-content .btn-box .default-btn span {
  background-color: #ffffff;
}

.error-content .btn-box .default-btn:hover {
  color: #221638;
}

/*================================================
FAQ Area CSS
=================================================*/
.faq-accordion-tab .react-tabs__tab-list {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 60px;
  text-align: center;
  border: none;
}

.faq-accordion-tab .react-tabs__tab-list .react-tabs__tab {
  margin-left: 10px;
  margin-right: 10px;
  display: inline-block;
  color: #221638;
  text-decoration: none;
  border-radius: 5px;
  padding: 20px;
  -webkit-box-shadow: 2px 8px 20px 0 rgba(25, 42, 70, 0.13) !important;
  box-shadow: 2px 8px 20px 0 rgba(25, 42, 70, 0.13) !important;
  font-size: 16px;
  font-weight: 800;
}

.faq-accordion-tab .react-tabs__tab-list .react-tabs__tab i {
  font-size: 30px;
  font-weight: 400;
}

.faq-accordion-tab .react-tabs__tab-list .react-tabs__tab span {
  display: block;
  margin-top: 8px;
}

.faq-accordion-tab .react-tabs__tab-list .react-tabs__tab:hover {
  background-color: #5172ff;
  color: #ffffff;
}

.faq-accordion-tab .react-tabs__tab-list .react-tabs__tab.react-tabs__tab--selected {
  background-color: #5172ff;
  color: #ffffff;
}

.faq-accordion .accordion {
  list-style-type: none;
  padding-left: 0;
  border: none;
  margin-bottom: 0;
}

.faq-accordion .accordion .accordion__item {
  border-radius: 5px;
  display: block;
  -webkit-box-shadow: 2px 8px 20px 0 rgba(25, 42, 70, 0.13) !important;
  box-shadow: 2px 8px 20px 0 rgba(25, 42, 70, 0.13) !important;
  background: #ffffff;
  margin-bottom: 15px;
  border: none;
  border-radius: 5px;
}

.faq-accordion .accordion .accordion__item:last-child {
  margin-bottom: 0;
}

.faq-accordion .accordion .accordion__button {
  padding: 20px 25px;
  background-color: #fff;
  color: #221638;
  text-decoration: none;
  position: relative;
  display: block;
  border-radius: 5px;
  font-size: 17px;
  font-weight: 800;
}

.faq-accordion .accordion .accordion__button i {
  position: absolute;
  left: 25px;
  top: 18px;
  font-size: 25px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.faq-accordion .accordion .accordion__button.active i {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.faq-accordion .accordion .accordion__panel {
  position: relative;
  margin-top: -5px;
  padding-bottom: 20px;
  padding-right: 30px;
  padding-left: 48px;
  padding-top: 0;
}

.faq-accordion .accordion .accordion__panel p {
  line-height: 1.8;
}

.tab .tabs-item {
  display: none;
}

.tab .tabs-item:first-child {
  display: block;
}

/*================================================
Coming Soon Area CSS
=================================================*/
.coming-soon-area {
  height: 100vh;
  position: relative;
  z-index: 1;
  background: #f6f7fb;
  background-image: url(/images/coming-soon-bg.jpg);
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}

.coming-soon-content {
  max-width: 700px;
  background: #ffffff;
  border-radius: 5px;
  overflow: hidden;
  text-align: center;
  padding: 40px 60px;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  margin-left: auto;
  margin-right: auto;
}

.coming-soon-content .logo {
  display: inline-block;
}

.coming-soon-content h2 {
  font-size: 40px;
  font-weight: 800;
  margin-top: 30px;
  margin-bottom: 0;
}

.coming-soon-content #timer {
  margin-top: 40px;
}

.coming-soon-content #timer div {
  background-color: #221638;
  color: #ffffff;
  width: 100px;
  height: 105px;
  border-radius: 5px;
  font-size: 40px;
  font-weight: 800;
  margin-left: 10px;
  margin-right: 10px;
}

.coming-soon-content #timer div span {
  display: block;
  margin-top: -2px;
  font-size: 17px;
  font-weight: 600;
}

.coming-soon-content form {
  position: relative;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
}

.coming-soon-content form .form-group {
  margin-bottom: 25px;
  width: 100%;
  position: relative;
}

.coming-soon-content form .form-group .label-title {
  margin-bottom: 0;
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  pointer-events: none;
  width: 100%;
  height: 100%;
  color: #221638;
}

.coming-soon-content form .form-group .label-title i {
  position: absolute;
  left: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  top: 9px;
  font-size: 22px;
}

.coming-soon-content form .form-group .label-title::before {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  background: #5172ff;
}

.coming-soon-content form .form-group .input-newsletter {
  border-radius: 0;
  border: none;
  border-bottom: 2px solid #eeeeee;
  padding: 0 0 0 32px;
  color: #221638;
  height: 45px;
  display: block;
  width: 100%;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  font-size: 16px;
  font-weight: 400;
}

.coming-soon-content form .form-group .input-newsletter::-webkit-input-placeholder {
  color: #a1a1a1;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.coming-soon-content form .form-group .input-newsletter:-ms-input-placeholder {
  color: #a1a1a1;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.coming-soon-content form .form-group .input-newsletter::-ms-input-placeholder {
  color: #a1a1a1;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.coming-soon-content form .form-group .input-newsletter::placeholder {
  color: #a1a1a1;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.coming-soon-content form .form-group .input-newsletter:focus::-webkit-input-placeholder {
  color: transparent;
}

.coming-soon-content form .form-group .input-newsletter:focus:-ms-input-placeholder {
  color: transparent;
}

.coming-soon-content form .form-group .input-newsletter:focus::-ms-input-placeholder {
  color: transparent;
}

.coming-soon-content form .form-group .input-newsletter:focus::placeholder {
  color: transparent;
}

.coming-soon-content form .default-btn {
  border-radius: 0;
}

.coming-soon-content form .validation-danger {
  margin-top: 15px;
  color: red;
}

.coming-soon-content form .validation-success {
  margin-top: 15px;
}

.coming-soon-content form p {
  margin-bottom: 0;
  margin-top: 20px;
}

/*================================================
Profile Authentication Area CSS
=================================================*/
.login-form {
  padding: 40px 50px;
  border-radius: 5px;
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 10px rgba(51, 51, 51, 0.1);
  box-shadow: 0 0 10px rgba(51, 51, 51, 0.1);
  margin-right: 30px;
}

.login-form h2 {
  margin-bottom: 25px;
  font-size: 36px;
  font-weight: 800;
}

.login-form form .form-group {
  margin-bottom: 25px;
}

.login-form form .form-group label {
  display: block;
  margin-bottom: 8px;
  color: #221638;
  font-weight: 700;
}

.login-form form .remember-me-wrap {
  margin-bottom: 0;
}

.login-form form .remember-me-wrap [type="checkbox"]:checked,
.login-form form .remember-me-wrap [type="checkbox"]:not(:checked) {
  display: none;
}

.login-form form .remember-me-wrap [type="checkbox"]:checked+label,
.login-form form .remember-me-wrap [type="checkbox"]:not(:checked)+label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  margin-bottom: 0;
  color: #606060;
  font-weight: 600;
}

.login-form form .remember-me-wrap [type="checkbox"]:checked+label:before,
.login-form form .remember-me-wrap [type="checkbox"]:not(:checked)+label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 19px;
  height: 19px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  border: 1px solid #f5f5f5;
  border-radius: 3px;
  background: #f5f5f5;
}

.login-form form .remember-me-wrap [type="checkbox"]:checked+label:after,
.login-form form .remember-me-wrap [type="checkbox"]:not(:checked)+label:after {
  content: "";
  width: 8px;
  height: 8px;
  background: #5172ff;
  position: absolute;
  top: 5.5px;
  left: 6px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.login-form form .remember-me-wrap [type="checkbox"]:not(:checked)+label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.login-form form .remember-me-wrap [type="checkbox"]:checked+label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.login-form form .remember-me-wrap [type="checkbox"]:hover+label:before {
  border-color: #5172ff;
}

.login-form form .remember-me-wrap [type="checkbox"]:checked+label:before {
  border-color: #5172ff;
}

.login-form form .lost-your-password-wrap {
  text-align: right;
}

.login-form form .lost-your-password-wrap a {
  display: inline-block;
  position: relative;
  font-weight: 600;
  line-height: 1.3;
}

.login-form form .lost-your-password-wrap a::before {
  width: 100%;
  height: 1px;
  position: absolute;
  left: 0;
  bottom: 0;
  content: "";
  -webkit-transition: 0.5s;
  transition: 0.5s;
  background-color: #eeeeee;
}

.login-form form .lost-your-password-wrap a::after {
  width: 0;
  height: 1px;
  position: absolute;
  left: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  bottom: 0;
  content: "";
  background-color: #5172ff;
}

.login-form form .lost-your-password-wrap a:hover::before {
  width: 0;
}

.login-form form .lost-your-password-wrap a:hover::after {
  width: 100%;
}

.login-form form button {
  margin-top: 22px;
  border: none;
  display: block;
  text-align: center;
  overflow: hidden;
  color: #ffffff;
  background-color: #5172ff;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  width: 100%;
  border-radius: 5px;
  padding: 14.5px 30px;
  font-weight: 700;
  font-size: 16px;
}

.login-form form button:hover {
  background-color: #221638;
  color: #ffffff;
}

.register-form {
  padding-top: 30px;
  padding-left: 30px;
}

.register-form h2 {
  margin-bottom: 25px;
  font-size: 36px;
  font-weight: 800;
}

.register-form form .form-group {
  margin-bottom: 25px;
}

.register-form form .form-group label {
  display: block;
  margin-bottom: 8px;
  color: #221638;
  font-weight: 700;
}

.register-form form .description {
  font-style: italic;
  font-size: 13.5px;
  margin-top: -10px;
  margin-bottom: 0;
}

.register-form form button {
  margin-top: 22px;
  border: none;
  display: block;
  text-align: center;
  overflow: hidden;
  color: #ffffff;
  background-color: #5172ff;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  width: 100%;
  border-radius: 5px;
  padding: 14.5px 30px;
  font-weight: 700;
  font-size: 16px;
}

.register-form form button:hover {
  background-color: #221638;
  color: #ffffff;
}

.contact-form {
  overflow: hidden;
  padding: 35px;
  margin-left: 15px;
  border-radius: 5px;
  /* background-color: #5172ff; */
  /* background-image: url(/images/register-shape.jpg); */
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: ççç;
}

.contact-form h2 {
  margin-bottom: 0;
  color: #ffffff;
  font-size: 33px;
  font-weight: 800;
}

.contact-form p {
  color: #ffffff;
  margin-top: 10px;
  margin-bottom: 0;
}

.contact-form form {
  margin-top: 25px;
}

.contact-form form input,
.contact-form form textarea {
  display: block;
  width: 100%;
  border: none;
  color: #9e8e8e;
  background-color: #fff;
  height: 50px;
  padding-left: 15px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 600;
}

.contact-form form input::-webkit-input-placeholder,
.contact-form form textarea::-webkit-input-placeholder {
  color: #cdcdcd;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.contact-form form input:-ms-input-placeholder,
.contact-form form textarea:-ms-input-placeholder {
  color: #cdcdcd;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.contact-form form input::-ms-input-placeholder,
.contact-form form textarea::-ms-input-placeholder {
  color: #cdcdcd;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.contact-form form input::placeholder,
.contact-form form textarea::placeholder {
  color: #cdcdcd;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.contact-form form input:focus::-webkit-input-placeholder,
.contact-form form textarea:focus::-webkit-input-placeholder {
  color: transparent;
}

.contact-form form input:focus:-ms-input-placeholder,
.contact-form form textarea:focus:-ms-input-placeholder {
  color: transparent;
}

.contact-form form input:focus::-ms-input-placeholder,
.contact-form form textarea:focus::-ms-input-placeholder {
  color: transparent;
}

.contact-form form input:focus::placeholder,
.contact-form form textarea:focus::placeholder {
  color: transparent;
}

.contact-form form textarea {
  height: auto !important;
  padding-top: 15px;
}

.contact-form form .default-btn {
  background-color: #221638;
  margin-top: 5px;
  padding: 12px 30px;
}

.contact-form form .default-btn span {
  background-color: #ffffff;
}

.contact-form form .default-btn:hover {
  color: #5172ff;
}

.contact-form form .invalid-feedback {
  color: #ffffff;
}

#map iframe {
  height: 500px;
  margin-bottom: -7px;
  width: 100%;
  border: none;
}

/*================================================
Footer Area CSS
=================================================*/

/*================================================
Go Top CSS
=================================================*/
.go-top {
  position: fixed;
  cursor: pointer;
  bottom: -100px;
  right: 15px;
  color: #221638;
  background-color: #ffffff;
  z-index: 4;
  width: 45px;
  text-align: center;
  height: 45px;
  opacity: 0;
  visibility: hidden;
  border-radius: 50%;
  font-size: 40px;
  -webkit-transition: 0.9s;
  transition: 0.9s;
  overflow: hidden;
  -webkit-box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
}

.go-top.active {
  opacity: 1;
  visibility: visible;
  bottom: 15px;
}

.go-top i {
  position: absolute;
  right: 0;
  left: 0;
  top: 70%;
  -webkit-transform: translateY(-70%);
  transform: translateY(-70%);
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.go-top:hover {
  background-color: #5172ff;
  color: #ffffff;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
}

/*================================================
Go Top CSS
=================================================*/
.heading-primary-blue {
  color: #6562f9;
}

.bg-dark-blue {
  background-color: #6562f9;
}

.border-dark-blue {
  border-color: #6562f9 !important;
}

.heading-secondary-blue {
  color: #3275e0;
}

.testimonial-wrapper p {
  font-size: 24px;
  color: black;
}

.paddingX-50 {
  padding-inline: 50px;
}

.paddingY-40 {
  padding-block: 40px;
}

.custom-box-shadow {
  box-shadow: 0px 3px 6px #00000029;
}

.related-products-Image {
  padding: 20px 20px 0;
}

.look-inside-label {
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: #6562f9;
}

.hover-change-bg:hover {
  background-color: #6562f9 !important;
}

.affairs-box {
  background: #fffaf3 !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  cursor: pointer;
}

.link-btn span {
  color: #8d8d8d !important;
}

.link-btn h6 {
  color: #2b2b2b;
}

.resources-color {
  color: #8d8d8d;
}

.download-pdf-btn {
  background-color: #dfdeff;
  height: 65px;
  display: inline-block;
  border-radius: 7px;
  width: 200px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
  color: #6562f9;
  margin-top: 20px;
  margin-left: 10px;
}

.download-pdf-btn>img {
  width: 30px;
  height: 30px;
  margin-right: 5px;
}

.download-pdf-btn:hover,
.download-pdf-btn:active,
.download-pdf-btn:focus {
  color: #6562f9;
}

.all-resources-btn {
  background-color: #ffe2c7;
  color: #ff7800;
}

.all-resources-btn:hover,
.all-resources-btn:active,
.all-resources-btn:focus {
  color: #ff7800;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.7) !important;
}

.ReactModal__Content.ReactModal__Content--after-open.view-all-resources-inner {
  max-width: 970px;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  inset: 20px;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  height: max-content;
  max-height: 635px;
  overflow-y: auto;
}

.view-resoures-modal-wrapper {
  padding: 40px 65px;
}

.modal-card-wrapper {
  box-shadow: 0px 3px 6px #00000029;
  width: 100%;
  background-color: #fffaf3;
  border-radius: 15px;
  margin-bottom: 50px;
}

.modal-card-wrapper:last-child {
  margin-bottom: 0;
}

.modal-card-wrapper .modal-card-inner {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  height: 190px;
  position: relative;
}

.modal-card-wrapper .card-image-wrapper {
  flex: 0 0 150px;
  max-width: 150px;
  width: 150px;
  height: 100%;
  padding: 20px 0 20px 20px;
}

.modal-card-wrapper .card-image-wrapper>img {
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

.modal-card-wrapper .file-name {
  flex: 0 0 calc(100% - 160px);
  max-width: calc(100% - 160px);
  width: calc(100% - 160px);
  padding: 20px 180px 20px 20px;
  height: 100%;
}

.modal-card-wrapper .file-name h4 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.modal-card-wrapper .file-name .btn-style {
  border-radius: 9px;
  padding: 5px 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 120px;
}

.modal-card-wrapper .file-name .btn-style.bg-blue {
  background-color: #6562f9;
}

.modal-card-wrapper .file-name .btn-style.bg-orange {
  background-color: #ff7800;
}

.modal-card-wrapper .download-btn-wrapper {
  flex: 0 0 180px;
  max-width: 180px;
  width: 180px;
  background-color: #e1e0ff;
  border-radius: 0 15px 15px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  row-gap: 10px;
}

.modal-card-wrapper .download-btn-wrapper>img {
  width: 45px;
}

.modal-card-wrapper .download-btn-wrapper>p {
  color: #6562f9;
  font-weight: bold;
  font-size: 1rem;
  margin-bottom: 0;
}

.close-popup-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  border: 1px solid #ff7800;
  border-radius: 50%;
  color: #fff;
  background-color: #ff7800;
}

.edemy-nav .navbar .navbar-nav .nav-item .dropdown-menu li:hover .dropdown-menu.mobileDropDownStyle {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  z-index: 1055 !important;
}

.desktopPositionClass {
  position: initial !important;
}

.desktop-dropdown {
  display: block;
}

.mobile-dropdown {
  display: none !important;
}

.slick-prev:before,
.slick-next:before {
  color: #09529b !important;
}

@media only screen and (max-width: 768px) {
  .ReactModal__Content.ReactModal__Content--after-open.view-all-resources-inner {
    inset: 16px;
  }

  .view-resoures-modal-wrapper {
    padding: 40px 1rem;
  }

  .modal-card-wrapper .card-image-wrapper {
    flex: 0 0 80px;
    max-width: 80px;
    width: 80px;
    height: 80px;
    padding: 10px 0 10px 10px;
  }

  .modal-card-wrapper .modal-card-inner {
    height: 200px;
  }

  .modal-card-wrapper .file-name {
    flex: 0 0 calc(100% - 85px);
    max-width: calc(100% - 85px);
    width: calc(100% - 85px);
    padding: 10px 10px 10px 10px;
    height: 100%;
  }

  .modal-card-wrapper .download-btn-wrapper {
    flex: 0 0 100%;
    max-width: 100%;
    width: 100%;
    border-radius: 0 0 15px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    height: 60px;
    position: absolute;
    right: 0;
    top: initial;
    bottom: 0;
    row-gap: 10px;
  }

  .modal-card-wrapper .download-btn-wrapper>img {
    width: 30px;
  }

  .modal-card-wrapper .download-btn-wrapper>p {
    padding-left: 20px;
    font-size: 1rem;
  }
}

/* @media only screen and (min-width: 280px) and (max-width: 374px) {
  /* .edemy-nav .navbar-brand {
    max-width: 170px;
  }
  .edemy-nav .navbar-brand > img {
    object-fit: contain;
  } */
/* .extra-small h3 {
    font-size: 16px;
  } */

/* @media only screen and (min-width: 767) {
  .hindiEnglish {
    justify-content: center;
  }
} */

@media only screen and (max-width: 767px) {
  .desktop-dropdown {
    display: none;
  }

  .mobile-dropdown {
    display: block;
  }

  /*.edemy-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu {*/
  /*  display: none;*/
  /*}*/
  .edemy-nav .navbar .navbar-nav .mobileDropDownStyle li.close-icon+li {
    border-top: 0 !important;
  }

  .edemy-nav .navbar .navbar-nav .nav-item .dropdown-menu li a {
    z-index: 1;
  }

  .edemy-nav .navbar .navbar-nav .nav-item .dropdown-menu li:hover .dropdown-menu.mobileDropDownStyle {
    padding-top: 30px;
  }

  .close-icon {
    position: absolute;
    top: 5px;
    right: 20px;
    z-index: 2;
  }

  .chevronDownMobile {
    position: absolute !important;
    right: 15px !important;
    top: 15px !important;
    font-size: 20px !important;
    z-index: 10 !important;
    display: block !important;
  }

  .dropDownStyle {
    top: 8px !important;
  }

  .showData {
    display: block !important;
  }

  .none {
    display: none !important;
  }

  .desktopPositionClass {
    position: relative !important;
  }
}

@media only screen and (max-width: 992px) {
  .samples-listing {
      flex: 0 0 calc(100% - 0px);
      max-width: calc(100% - 0px);
      width: 100%;
    }
  /* .edemy-nav .navbar ul {
    width: 300px;
  } */
  .edemy-nav .navbar ul.nestedChild {
    border: none;
    top: 0;
    border-top: 1px solid #5172ff;
    position: relative;
    opacity: 1;
    visibility: visible;
    margin-top: 0;
    width: 100%;
    border-left: 1px solid #eee;
    margin-bottom: 10px;
    box-shadow: 0px 0px 15px 0px rgb(0 0 0 / 10%);
  }

  .edemy-nav .navbar ul.nestedChild.anotherclass {
    position: fixed;
    top: 0;
    left: 0;
    background-color: #fff;
    bottom: 0;
    right: 0;
    z-index: 2;
  }

  .edemy-nav .navbar ul.nestedChild li {
    padding-inline: 15px;
  }

  .edemy-nav .navbar ul li.topListings {
    padding: 15px;
    border-bottom: 1px solid gray;
  }

  /* .goToTop {} */

  .goToTop1 {
    position: fixed;
    top: 0;
    left: 0;
    background-color: #fff;
    bottom: 0;
    right: 0;
    z-index: 2;
  }

  .goToNone {
    display: none;
  }

  .edemy-nav .navbar ul li.topListings span,
  .edemy-nav .navbar ul li.topListings i {
    color: #221638;
    font-size: 15px;
    font-weight: 700;
  }

  .edemy-nav #navbarSupportedContent ul li a.nav-link {
    color: #221638;
    font-size: 15px;
    padding-left: 0;
    padding-right: 0;
    /* padding-top: 10px;
    padding-bottom: 10px; */
    font-weight: 700;
  }

  .child-nav {
    display: none !important;
  }

  .showw {
    display: block !important;
  }

  .desktop-dropdown {
    display: none;
  }

  .mobile-dropdown {
    display: block;
  }

  /*.edemy-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu {*/
  /*  display: none;*/
  /*}*/
  .edemy-nav .navbar .navbar-nav .mobileDropDownStyle li.close-icon+li {
    border-top: 0 !important;
  }

  .edemy-nav .navbar .navbar-nav .nav-item .dropdown-menu li a {
    z-index: 1;
  }

  .edemy-nav .navbar .navbar-nav .nav-item .dropdown-menu li:hover .dropdown-menu.mobileDropDownStyle {
    padding-top: 30px;
  }

  .close-icon {
    position: absolute;
    top: 5px;
    right: 20px;
    z-index: 2;
  }

  .chevronDownMobile {
    position: absolute !important;
    right: 15px !important;
    top: 15px !important;
    font-size: 20px !important;
    z-index: 10 !important;
    display: block !important;
  }

  .dropDownStyle {
    top: 8px !important;
  }

  .showData {
    display: block !important;
  }

  .none {
    display: none !important;
  }

  .desktopPositionClass {
    position: relative !important;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1366px) {
  .navbar .navbar-brand img {
    max-width: 150px;
  }

  .edemy-nav .navbar .navbar-nav .nav-item a {
    font-size: 14px;
  }
}

/*================================================
-Video Area CSS
-=================================================*/

.video-box {
  position: relative;
  border-radius: 5px;
  max-width: 800px;
  z-index: 1;
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
}

.video-box .image {
  overflow: hidden;
  border-radius: 5px;
}

.video-box .image img {
  border-radius: 5px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.video-box .video-btn {
  display: inline-block;
  width: 80px;
  height: 80px;
  font-size: 35px;
  text-align: center;
  line-height: 80px;
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  -webkit-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%);
  background-color: #5172ff;
  border-radius: 50%;
  color: #ffffff;
}

.video-box .video-btn::after,
.video-box .video-btn::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  bottom: 0;
  left: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  border-radius: 50%;
  border: 1px solid #5172ff;
}

.video-box .video-btn::before {
  -webkit-animation: ripple 2s linear infinite;
  animation: ripple 2s linear infinite;
}

.video-box .video-btn::after {
  -webkit-animation: ripple 2s linear 1s infinite;
  animation: ripple 2s linear 1s infinite;
}

.video-box .video-btn:hover {
  color: #ffffff;
  background-color: #221638;
}

.video-box .video-btn:hover::before,
.video-box .video-btn:hover::after {
  border-color: #221638;
}

.video-box:hover .image img {
  -webkit-transform: scale3d(1.1, 1.1, 1.1);
  transform: scale3d(1.1, 1.1, 1.1);
}

@-webkit-keyframes ripple {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  75% {
    -webkit-transform: scale(1.75);
    transform: scale(1.75);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(2);
    transform: scale(2);
    opacity: 0;
  }
}

@keyframes ripple {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  75% {
    -webkit-transform: scale(1.75);
    transform: scale(1.75);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(2);
    transform: scale(2);
    opacity: 0;
  }
}

.shape10 {
  position: absolute;
  left: -11%;
  bottom: -11%;
  z-index: -1;
}

.shape10 img {
  border-radius: 0;
  -webkit-animation: moveleftbounce 4s linear infinite;
  animation: moveleftbounce 4s linear infinite;
}

/* about video box */

.about-video-box {
  margin-left: 4px;
  position: relative;
  z-index: 1;
  border-radius: 5px;
}

.about-video-box .image {
  overflow: hidden;
  border-radius: 5px;
  position: relative;
  z-index: 1;
}

.about-video-box .image::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 5px;
  background-color: #221638;
  opacity: 0.1;
  z-index: 1;
}

.about-video-box .image img {
  border-radius: 5px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.about-video-box .video-btn {
  display: inline-block;
  width: 80px;
  height: 80px;
  font-size: 35px;
  text-align: center;
  line-height: 80px;
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  -webkit-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%);
  background-color: #5172ff;
  border-radius: 50%;
  color: #ffffff;
}

.about-video-box .video-btn::after,
.about-video-box .video-btn::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  bottom: 0;
  left: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  border-radius: 50%;
  border: 1px solid #5172ff;
}

.about-video-box .video-btn::before {
  -webkit-animation: ripple 2s linear infinite;
  animation: ripple 2s linear infinite;
}

.about-video-box .video-btn::after {
  -webkit-animation: ripple 2s linear 1s infinite;
  animation: ripple 2s linear 1s infinite;
}

.about-video-box .video-btn:hover {
  color: #ffffff;
  background-color: #221638;
}

.about-video-box .video-btn:hover::before,
.about-video-box .video-btn:hover::after {
  border-color: #221638;
}

.about-video-box:hover .image img {
  -webkit-transform: scale3d(1.1, 1.1, 1.1);
  transform: scale3d(1.1, 1.1, 1.1);
}

.about-video-box .shape10 {
  left: -6.5%;
  bottom: -13.5%;
  opacity: 0.5;
}

div.raw-html-embed video{
width:100%;
}

@media only screen and (min-width: 700px){
  div.raw-html-embed video{
    width:50%;
    margin:0 auto;
    }
}
 
/* div.custom-carousel button{
  background:#5172ff
}
 div.carousel-control button{
  background:transparent !important
} */
.react-pdf__Page__canvas { 
margin:0 auto; 
}

div#testseries-content table tr,td{
  border:1px solid gray;
  padding:10px 20px;  
  margin-top:5px;
  ;
}

@media only screen and (max-width: 767px){
  div#testseries-content table tr,td{
    border:1px solid gray;
    padding: 5px;
    margin-top:5px;
    ;
  }
  div#testseries-content ul li{
    list-style-type: square;
    
  }
}

div#testseries-content ul li{
  list-style-type: square;
  margin:5px 0px;
}

div#testseries-content a{
  font-weight: 800;
  color:#7c5efa;
}

div#testseries-content tr.table-head-bg-color{
  background-color:rgba(124, 94, 250, 0.2);
}
div#testseries-content tr.table-head-bg-color td p{
  /* color:white; */

}